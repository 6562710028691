import { createAsyncActionType } from './index';

export const SET_TEAMS_PROGRESS_FILTERS =
  '@@teams/SET_COLLABORATORS_LIST_FILTERS';

export const TEAMS_PROGRESS_GET = createAsyncActionType(
  'teams',
  'TEAMS_PROGRESS_GET'
);

export const VIEWS_GET = createAsyncActionType('teams', 'VIEWS_GET');

export const POSITIVE_GET = createAsyncActionType('teams', 'POSITIVE_GET');

export const GET_NOTIFICATIONS = createAsyncActionType(
  'teams',
  'GET_NOTIFICATIONS'
);
