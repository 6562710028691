import { useCallback, useState } from 'react';
import { apiHost, baseUrl, extendUrl, socialPrefix } from 'api/config';
import { getToken } from 'utils/token';
import moment from 'moment';

// models uploaded file for current endpoints (profile, notes)
// const emptyFile = {
//   file: null, //File object
//   id: '', //moment().format('DDHHmmss')
//   name: undefined, //from api response, currently always undefined
//   size: 0,
//   type: '', //MIME type
//   url: '', //from api response
// };

/** NOTE ON useCallback
 * uses callback memoization so that the action can be called by 
 * an external component and be used in its dependencies arrays
 */
function useFileUpload({ domainId }) {
  // const [uploadProgress, setUploadProgress] = useState({});
  const [acquiredFile, setAcquiredFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileIsHandled, setFileIsHandled] = useState(false);
  const [hasError, setHasError] = useState(false);

  const uploadFile = useCallback(({ endpoint, withUserId = true }) => {
    if (!acquiredFile) return;

    const { userid, token } = getToken();
    if (!userid || !token) return;

    function createFormData(file) {
      if (!file) return null;
      const formData = new FormData();

      const formFile = (typeof File === 'function')
        ? new File([file.file || file], file.name || file)
        : new Blob([file.file || file]); // IE11

      formData.append('file', formFile);

      return formData;
    }

    const formData = createFormData(acquiredFile);
    if (!formData) return;

    const fileUpload = new XMLHttpRequest();

    // fileUpload.upload.addEventListener(
    //   'progress',
    //   progressHandler => {
    //     const { loaded = 0, total: target = 0 } = progressHandler;
    //     setUploadProgress(s => ({
    //       ...s,
    //       [acquiredFile.id]: Math.ceil((loaded / target) * 100)
    //     }));
    //   },
    //   false
    // );

    fileUpload.addEventListener(
      'load',
      load => {
        const { target: { response: rawResponse = {} } = {} } = load;
        const data = JSON.parse(rawResponse);
        setUploadedFile({
          ...acquiredFile,
          name: data.fileName?.toLowerCase(),
          url: data.fileDownloadUri || data.downloadUrl,
          status: data.status
        });
      },
      false
    );
    fileUpload.addEventListener(
      'error',
      error => {
        setHasError(true);
        // eslint-disable-next-line no-console
        console.error('fileUploadCall error', error);
      },
      false
    );
    fileUpload.addEventListener(
      'abort',
      abort => {
        // eslint-disable-next-line no-console
        console.warn('abort', abort);
      },
      false
    );
    fileUpload.addEventListener(
      'loadend',
      loadend => {
        setFileIsHandled(true);
      },
      false
    );

    fileUpload.open(
      'POST',
      `${apiHost}${socialPrefix}${baseUrl}${extendUrl}${endpoint}${withUserId ? `&userId=${userid}` : ''}`
    );
    fileUpload.setRequestHeader('Authorization', token);
    fileUpload.setRequestHeader('Domain', domainId);
    fileUpload.send(formData);

  }, [acquiredFile, domainId]);

  const resetFile = useCallback(() => {
    // setUploadProgress({});
    setAcquiredFile(null);
    setUploadedFile(null);
    setFileIsHandled(false);
    setHasError(false);
  }, []);

  const readFile = useCallback(({ file, format }) => {
    resetFile();

    const getFile = fileToGet => {
      switch (format) {
        case 'BASE64':
          return reader.result;

        case 'BINARY':
        default: {
          const getBlob = f => (
            f.target.readyState === FileReader.DONE
              ? new Blob([f.target.result])
              : null
          );

          return getBlob(fileToGet);
        }
      }
    };

    const reader = new FileReader();

    reader.onload = e => {
      // TODO: null checks?
      setAcquiredFile({
        name: file.name,
        size: file.size,
        type: file.type,
        id: moment().format('DDHHmmss'),
        file: getFile(e)
      });
    };
    reader.onabort = evt => {
      // eslint-disable-next-line no-console
      console.log('ProfilePicture > reader.onabort', evt);
    };
    reader.onerror = err => {
      setHasError(true);
      // eslint-disable-next-line no-console
      console.log('ProfilePicture > reader.onerror', err);
    };

    switch (format) {
      case 'BASE64':
        reader.readAsDataURL(file);
        break;

      case 'BINARY':
      default:
        reader.readAsArrayBuffer(file);
        break;
    }
  }, [resetFile]);

  return {
    acquiredFile,
    fileIsHandled,
    hasError,
    readFile,
    resetFile,
    uploadFile,
    uploadedFile,
    // uploadProgress,
  };
}

export default useFileUpload;
