import styled from 'styled-components/macro';

export const ResetPasswordContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  flex-direction: column;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 25px;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 50px - 50px);

  @media ${props => props.theme.device.tablet} {
    height: calc(100vh - 50px - 59px);
  }
  @media ${props => props.theme.device.mobile} {
    height: calc(100vh - 50px - 100px);
  }
`;

export const Title = styled.div`
  padding-bottom: 16px;
`;

export const Text = styled.div`
  padding-bottom: 16px;

  color: ${props => props.theme.colors.greenDark};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 27px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-right: 0;
    padding-bottom: 23px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 177px;
  min-width: 177px;
  display: flex;
  align-items: center;
  margin: 30px auto;

  @media ${props => props.theme.device.tablet} {
    max-width: 233px;
  }
  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
    margin-bottom: 15px;
  }
`;
