import styled from 'styled-components/macro';

export const CatalogContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  display: flex;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tablet} {
    padding: 35px 22px 0 22px;
    justify-content: space-between;
  }

  @media ${props => props.theme.device.mobile} {
    justify-content: center;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    padding: 0 22px;
  }

  @media ${props => props.theme.device.mobile} {
    flex-wrap: wrap;
  }
`;

export const SelectWrapper = styled.div`
  width: 302px;
  /* max-width: calc(100% - 70px); */
  margin: 20px 20px 20px 0;
  z-index: 0;
`;

export const IconSearch = styled.div`
  width: 30px;
  height: 55px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SearchContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
`;
