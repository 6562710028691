import styled from 'styled-components/macro';
import { customScrollbar, hideScrollbar } from 'utils/common';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Head = styled.div`
  width: 420px;
  max-width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Title = styled.div``;

export const Icon = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const Results = styled.div`
  width: 420px;
  max-width: 100%;
  max-height: 320px;
  margin: 20px;
  overflow: auto;

  & .infinite-scroll-component {
    height: 320px !important;
    ${customScrollbar()}
  }
  @media ${props => props.theme.device.mobile} {
    max-height: 480px;
    ${hideScrollbar()}

    & .infinite-scroll-component {
      height: 480px !important;
    }
  }
`;

export const UserWrapper = styled.div`
  height: 64px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.antiFlashWhite};
  cursor: pointer;

  &.selected {
    background-color: ${props => props.theme.colors.lightSilver};
  }
`;

export const Name = styled.div`
  margin-left: 17px;
`;

export const ButtonWrapper = styled.div`
  min-width: 95px;
  margin-left: auto;
`;

export const Text = styled.div``;
