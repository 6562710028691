import styled from 'styled-components/macro';

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  .brightcove-react-player-loader {
    width: 100%;
    height: 100%;
    .video-js {
      width: 100%;
      height: 100%;
      .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transition: all 0s;
        
        &:hover {
          background-color: ${props => props.theme.colors.primary.base};
        }
      }
      .vjs-current-time {
        display: block;
      }
      .vjs-duration {
        display: block;
      }
      .vjs-dock-text {
        display: none;
      }
    }
    
    .bc-player-default_default:hover .vjs-big-play-button {
      background-color: ${props => props.theme.colors.primary.base};
    }
    .video-js.vjs-layout-small {
      .vjs-mute-control {
        display: block !important;
      }
      .vjs-volume-control {
        display: block !important;
      }
      .vjs-current-time {
        display: block !important;
      }
      .vjs-time-divider {
        display: block !important;
      }
      .vjs-duration {
        display: block !important;
      }
      .vjs-progress-control {
        min-width: 100%;
        height: 50%;
        position: absolute;
        left: 0;
        bottom: 30px;
      }
      .vjs-picture-in-picture-control {
        margin-left: auto;
      }
    }
  }
`;

export const VideoPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  background-color: ${props => props.theme.colors.slateGray};
`;

export const Player = styled.video``;
