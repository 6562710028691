import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;

export const Title = styled.div`
  width: 240px;
  text-align: center;
`;

export const Subtitle = styled.div`
  width: 90%;
  margin-bottom: 35px;
  display: flex;
`;

export const Icon = styled.span`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  position: absolute;
  right: 4%;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 140px;
  margin-bottom: 16px;
`;

export const IconCheck = styled.span`
  font-size: 15px;
  margin-right: 3px;
  color: ${props => props.theme.colors.lightGreen};
`;

export const Head = styled.span`
  padding: 5px 0;
`;

export const Bottom = styled.div`
  padding-top: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

export const BtnContainer = styled.div`
  width: 177px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const FileContainer = styled.div`
  width: 90%;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 2px solid ${props => props.theme.colors.lightSilver};
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: fit-content;
  vertical-align: middle;
  display: ${props => (props.isFlex ? 'flex' : 'block')};
  align-items: center;
`;

export const Name = styled.div`
  font-weight: bold;
`;
export const Size = styled.div`
  font-size: 0.8em;
  color: ${props => props.theme.colors.placeholder};
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  &.no-pointer {
    cursor: default;
  }

  @media ${props => props.theme.device.tablet} {
    display: flex;
    align-items: center;
}
  }
`;

export const IconDelete = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
`;

export const IconInfo = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
`;

export const InputFile = styled.input`
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const Download = styled.a`
  color: ${props => props.theme.colors.primary.base};
  font-size: 12px;
`;
