import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
`;

export const Title = styled.div`
  text-align: center;

  @media ${props => props.theme.device.mobile} {
    width: 279px;
  }
`;
export const Subtitle = styled.div`
  margin-top: 14px;
  text-align: center;
`;

export const InputContainer = styled.div`
  width: 300px;
  margin-bottom: 20px;
  & > div {
    margin-top: 20px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 270px;
  }
`;
