import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-width: calc(100vw - 9px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
  ${customScrollbar()}
`;

export const Head = styled.div`
  width: 420px;
  max-width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionContainer = styled.div`
  width: 420px;
  max-width: 100%;
  margin-bottom: 20px;
`;

export const Texts = styled.div``;

export const Title = styled.div``;

export const Subtitle = styled.div`
  color: ${props => props.theme.colors.americanSilver};
  padding-top: 10px;
}
`;

export const Icon = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const BarContainer = styled.div`
  width: 420px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 030px;
`;

export const Form = styled.div`
  width: 420px;
  max-width: 100%;
  min-height: 280px;
`;

export const KnobContainer = styled.div`
  width: 100%;
  height: 48px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin-top: 13px;
`;

export const Knob = styled.div`
  min-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.primary.base};
  cursor: pointer;
  margin: 0 20px;

  &.selected {
    background-color: ${props => props.theme.colors.primary.base};
  }

  @media ${props => props.theme.device.mobile} {
    margin: 0 18px;
  }
`;

export const Labels = styled.div`
  width: 330px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const TextArea = styled.input`
  width: 100%;
  height: 113px;
  margin: 10px 48px 0 0;
  padding: 16px 10px 73px 20px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
`;

export const Bottom = styled.div`
  width: 420px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BtnContainer = styled.div`
  width: 120px;
`;

export const LikeWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconLike = styled.div`
  font-size: 60px;
  padding-right: 37px;
  color: ${props => props.theme.colors.lightSilver};
  cursor: pointer;

  &.active {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const IconDislike = styled(IconLike)`
  border-right: 1px solid ${props => props.theme.colors.lightSilver};
  transform: rotate(180deg);
`;
export const CheckboxWrapper = styled.div`
  margin-top: 15px;
`;

export const CommentWrapper = styled.div`
  margin-top: 15px;
`;
