import produce from 'immer';

import { CONFIG_TEXT_GET } from 'redux/actions/configuration';

const initialState = {
  subscriptions_config_success: {},
  delete_subscription: {},
  welcome: {},
  confirm_password: {},
  payment_update: {},
  load_list: {},
  premium_purchase_confirm: {},
  premium_purchase: {},
  welcome_from_bank: {},
  welcome_from_bank_link: {}
};

const configurationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONFIG_TEXT_GET._SUCCESS: {
        const {
          response: {
            subscriptions_config_success,
            delete_subscription,
            welcome,
            confirm_password,
            payment_update,
            premium_purchase,
            disable_subscription,
            premium_purchase_confirm,
            load_list,
            welcome_from_bank,
            welcome_from_bank_link
          } = {}
        } = action;
        draft.subscriptions_config_success = subscriptions_config_success;
        draft.delete_subscription = delete_subscription;
        draft.disable_subscription = disable_subscription;
        draft.welcome = welcome;
        draft.confirm_password = confirm_password;
        draft.payment_update = payment_update;
        draft.load_list = load_list;
        draft.premium_purchase_confirm = premium_purchase_confirm;
        draft.premium_purchase = premium_purchase;
        draft.welcomeFromBank = welcome_from_bank;
        draft.welcomeFromBankLink = welcome_from_bank_link;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default configurationReducer;
