import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Row, Col } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import { SubTitle } from 'pages/Signup/steps/UserAccount/style';
import { priceFormat } from 'utils/price-format';
import {
  ResumeContainer,
  Headings,
  QuantityHeading,
  ResumeDataContainer,
  ResumeFooter,
  ResumePrice,
  ResumeOffer,
  ResumeTipology,
  ResumeQuantity,
  ResumeCost
} from '../SummaryTable/style';

const SummaryTableSimpler = ({
  cart: { products, total_price },
  mediaIsPhone
}) => {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setList(Object.values(products));
    setTotal(total_price);
  }, [products, total_price]);

  const getTipology = type => {
    const types = {
      'Learning Subscription': 'Abbonamento',
      Learning: 'Finger Learning'
    };
    return types[type] ? types[type] : 'Finger Learning';
  };

  return (
    <>

      <SubTitle>
        <ProText type="description" configuration={{ fontWeight: 600 }}>
          Riepilogo
        </ProText>
      </SubTitle>

      <ResumeContainer>
        <Headings>
          <Row>
            <Col md={4} sm={4}>
              <ProText type="resumeTime">Offerta formativa</ProText>
            </Col>
            {!mediaIsPhone && (
              <Col md={3} sm={3}>
                <ProText type="resumeTime">Tipologia</ProText>
              </Col>
            )}

            <Col md={3} sm={4}>
              <QuantityHeading
                style={!mediaIsPhone ? { textAlign: 'center' } : null}
              >
                <ProText type="resumeTime"> Quantità</ProText>
              </QuantityHeading>
            </Col>
            <Col md={2} sm={4}>
              <QuantityHeading
                style={!mediaIsPhone ? { textAlign: 'center' } : null}
              >
                <ProText
                  type="resumeTime"
                  style={!mediaIsPhone ? { textAlign: 'center' } : null}
                >
                  {' '}
                  Costo
                </ProText>
              </QuantityHeading>
            </Col>
          </Row>
        </Headings>

        <ResumeDataContainer>
          {list.map(
            (data, index) =>
              data.num > 0 && (
                <Row key={`${data.title}-${index}`}>
                  <Col md={4} sm={12}>
                    <ResumeOffer>
                      <ProText type="resumeLabel">{data.title}</ProText>
                    </ResumeOffer>
                  </Col>
                  {mediaIsPhone && <Col sm={4}></Col>}
                  {!mediaIsPhone && (
                    <Col md={4} sm={3}>
                      <ResumeTipology>
                        <ProText type="resumeLabel">
                          {getTipology(data?.product_type || data?.type)}
                        </ProText>
                      </ResumeTipology>
                    </Col>
                  )}

                  <Col md={2} sm={4}>
                    <ResumeQuantity
                      style={mediaIsPhone ? { textAlign: 'center' } : null}
                    >
                      <ProText type="resumeLabel">{data.num}</ProText>
                    </ResumeQuantity>
                  </Col>
                  <Col md={2} sm={4}>
                    <ResumeCost>
                      <ProText type="resumeLabel">
                        {priceFormat(data.price_number)} €
                      </ProText>
                    </ResumeCost>
                  </Col>
                </Row>
              )
          )}
        </ResumeDataContainer>

        <ResumeFooter>
          <Row>
            <Col md={10} sm={8}>
              <ProText
                type="section"
                configuration={{ textTransform: 'uppercase' }}
              >
                Totale
              </ProText>
            </Col>
            <Col md={2} sm={4}>
              <ResumePrice>
                <ProText type="section">
                  {total ? priceFormat(parseFloat(total).toFixed(2)) : 0}€
                </ProText>
              </ResumePrice>
            </Col>
          </Row>
        </ResumeFooter>
      </ResumeContainer>
    </>
  );
};

SummaryTableSimpler.propTypes = {
  //HOC (connect, state)
  cart: PropTypes.object,

  //HOC (connect, dispatch)
  mediaIsPhone: PropTypes.bool.isRequired
};

export default withMediaQueries(
  connect(
    state => {
      const { cart } = state.signup;
      return { cart };
    }
  )(SummaryTableSimpler)
);
