import PropTypes from 'prop-types';

export const UserProfileInterface = PropTypes.shape({
  username: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  pictureId: PropTypes.string,
  organizationId: PropTypes.string,
  prospectId: PropTypes.string,
  industry: PropTypes.string
});

export const BillingDataInterface = PropTypes.arrayOf(PropTypes.shape({
  number_subscriptions: PropTypes.number,
  deadline: PropTypes.string,
  card_holder: PropTypes.string,
  card_number: PropTypes.string,
  valid_date: PropTypes.string,
  cvc: PropTypes.string
}));

export const CompanyDataInterface = PropTypes.shape({
  organizationId: PropTypes.string,
  title: PropTypes.string,
  vatNumber: PropTypes.string,
  sdiNumber: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  district: PropTypes.string,
  zipCode: PropTypes.string,
  industry: PropTypes.string
});

export const AccountDataInterface = PropTypes.shape({
  profile: UserProfileInterface,
  billing: BillingDataInterface,
  company: CompanyDataInterface
});
