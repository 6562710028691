import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  min-width: 160px;
  max-width: 230px;

  @media ${props => props.theme.device.mobile} {
    &.with-margin {
      margin-bottom: 15px;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 400px;
  flex-wrap: wrap;
  @media ${props => props.theme.device.mobile} {
    min-width: initial;
    flex-direction: column;
  }
`;

export const Text = styled.div`
  padding-bottom: 32px;
  color: ${props => props.theme.colors.mineShaft};
  width: 420px;
  text-align: center;
  max-width: 100%;

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 27px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-right: 0;
    padding-bottom: 23px;
  }
`;
