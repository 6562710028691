import styled from 'styled-components/macro';

export const Header = styled.header`
  height: 120px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    flex-wrap: wrap;
    margin: 20px 0;
  }
`;

export const Title = styled.h1`
  height: 34px;
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  padding-left: 11px;
  margin-left: 7px;
`;

export const IconBack = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  transform: rotate(180deg);
  cursor: pointer;
`;

export const SelectLabel = styled.div`
  margin-left: auto;

  @media ${props => props.theme.device.mobile} {
    margin: 20px 0 4px;
  }
`;

export const SelectPeriodWrapper = styled.div`
  margin-left: 10px;
  width: 227px;

  @media ${props => props.theme.device.mobile} {
    margin: 0;
  }
`;

export const Labels = styled.div`
  width: 100%;
  padding: 0 30px;
  margin-bottom: 10px;
`;

export const TableContainer = styled.div`
  width: 100%;
  padding: 10px 30px 10px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 10px 40px 0 rgba(24, 42, 50, 0.03);
`;

export const SmallRow = styled.div`
  min-height: 1.14em;
`;

export const LoadMore = styled.div`
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary.base};
  span {
    cursor: pointer;
  }
`;
