import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import {
  BILLINGS_GET_PAYMENT_GATEGAYS,
  BILLING_GET_PAYMENT_METHODS,
  BILLING_GET_CLIENT_SECRET,
  BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL,
  BILLINGS_ZUORA_SIGNATURE_GET,
  BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET,
  BILLINGS_ZUORA_PAYMENT_METHOD_ADD,
} from 'redux/actions/billings';

import billings from 'api/billings';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  SIGNUP_SET_PAYMENT_METHOD_ID,
} from 'redux/actions/signup';

function* billingsPaymentGatewaysGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    if (userId) {
      const res = yield call(billings.getPaymentGateways, { userId });
      const { data: gateways = [], status = '' } = res;
      if (status?.toString() === '200') {
        yield put({
          type: BILLINGS_GET_PAYMENT_GATEGAYS._SUCCESS,
          gateways: gateways[0],
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > billingsPaymentGatewaysGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* billingsPaymentGatewaysGetWatch() {
  yield takeLatest(
    BILLINGS_GET_PAYMENT_GATEGAYS._REQUEST,
    billingsPaymentGatewaysGet
  );
}

function* getPaymentMethods() {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
    const { paymentToken } = yield select(state => state.signup);
    if (zuoraaccountnumber) {
      const { data: { paymentmethodlist = [] } = {} } = yield call(billings.getPaymentMethods, { zuoraaccountnumber });
      yield put({ type: BILLING_GET_PAYMENT_METHODS._SUCCESS, payment_methods: paymentmethodlist });
      const paymentMethodDefault = paymentmethodlist.filter(item => item.defaultPaymentMethod);
      yield put({ type: SIGNUP_SET_PAYMENT_METHOD_ID, payload: paymentToken || (paymentMethodDefault[0] ? paymentMethodDefault[0].id : paymentmethodlist[0]?.id) });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > getPaymentMethods', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPaymentMethodsWatch() {
  yield takeLatest(BILLING_GET_PAYMENT_METHODS._REQUEST, getPaymentMethods);
}

function* getClientSecret({ fromRoute = 'registration' }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId, zuoraAccountNumber, zuoraAccountId } = {} } = yield select(
      state => state.authentication
    );
    if (userId) {
      const res = yield call(billings.getClientSecret, { userId, zuoraAccountNumber, zuoraAccountId, fromRoute });
      const {
        data: { client_secret: clientSecret },
        status = {},
      } = res;
      if (status?.toString() === '201' || status?.toString() === '200') {
        yield put({
          type: BILLING_GET_CLIENT_SECRET._SUCCESS,
          clientSecret,
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > getClientSecret', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getClientSecretWatch() {
  yield takeLatest(BILLING_GET_CLIENT_SECRET._REQUEST, getClientSecret);
}

function* getZuoraSignature({ payment_method }) {
  try {
    yield put({ type: LOADER_OPEN });
    const res = yield call(billings.getZuoraSignature, { payment_method });
    const { data, status = {} } = res;
    if (status?.toString() === '201' || status?.toString() === '200') {
      yield put({ type: BILLINGS_ZUORA_SIGNATURE_GET._SUCCESS, data, payment_method });
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ModalErrors.id, payload: { errorText: err?.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > getClientSecret', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getZuoraSignatureWatch() {
  yield takeEvery(BILLINGS_ZUORA_SIGNATURE_GET._REQUEST, getZuoraSignature);
}

function* addZuoraPaymentMethod({ payment_method_id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountid: zuoraAccountId } = {} } = yield select(state => state.profile);
    if (zuoraAccountId) {
      yield call(billings.addPaymentMethodZuora, { accountId: zuoraAccountId, defaultPaymentMethodId: payment_method_id });
      yield put({ type: BILLINGS_ZUORA_PAYMENT_METHOD_ADD._SUCCESS });
      yield put({ type: BILLING_GET_PAYMENT_METHODS._REQUEST });
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ModalErrors.id, payload: { errorText: err?.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > addZuoraPaymentMethod', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addZuoraPaymentMethodWatch() {
  yield takeEvery(BILLINGS_ZUORA_PAYMENT_METHOD_ADD._REQUEST, addZuoraPaymentMethod);
}

function* setZuoraPaymentMethodDefault({ payment_method_id, shouldCreate }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountid: zuoraAccountId } = {} } = yield select(state => state.profile);
    if (zuoraAccountId) {
      yield call(billings.setDefaultPaymentMethodZuora, { accountId: zuoraAccountId, defaultPaymentMethodId: payment_method_id });
      yield put({ type: BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET._SUCCESS });
      if (shouldCreate) yield put({ type: BILLINGS_ZUORA_PAYMENT_METHOD_ADD._REQUEST, payment_method_id });
      else yield put({ type: BILLING_GET_PAYMENT_METHODS._REQUEST });
    }
  } catch (err) {
    yield put({ type: MODAL_OPEN, id: ModalErrors.id, payload: { errorText: err?.message } });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > setZuoraPaymentMethodDefault', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}
function* setZuoraPaymentMethodDefaultWatch() {
  yield takeEvery(BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET._REQUEST, setZuoraPaymentMethodDefault);
}

function* getResourceCommerceOrder({ orderId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    if (userId) {
      const res = yield call(billings.getResourceCommerceOrder, {
        userId,
        orderId,
      });
      const {
        data: { data: commerceOrder },
        status,
      } = res;
      if (status?.toString() === '201' || status?.toString() === '200') {
        const { tax_amount, tax_percentage } = commerceOrder[0];
        yield put({
          type: BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL._SUCCESS,
          payload: { tax_amount, tax_percentage },
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > billings > getClientSecret', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getResourceCommerceOrderWatch() {
  yield takeLatest(
    BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL._REQUEST,
    getResourceCommerceOrder
  );
}

export default [
  billingsPaymentGatewaysGetWatch(),
  getPaymentMethodsWatch(),
  getClientSecretWatch(),
  getZuoraSignatureWatch(),
  setZuoraPaymentMethodDefaultWatch(),
  getResourceCommerceOrderWatch(),
  addZuoraPaymentMethodWatch(),
];
