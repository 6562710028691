export const breakpoint = {
  mobile: 596,
  tablet: 788,
  laptop: 1012,
  desktop: 1220,
  largeDesktop: 1480,
};

export const size = {
  mobile: `${breakpoint.mobile}px`,
  tablet: `${breakpoint.tablet}px`,
  laptop: `${breakpoint.laptop}px`,
  desktop: `${breakpoint.desktop}px`,
  largeDesktop: `${breakpoint.largeDesktop}px`,
};

export const device = {
  mobile: `(max-width: ${breakpoint.tablet - 1}px)`,
  tablet: `(max-width: ${breakpoint.laptop - 1}px)`,
  laptop: `(max-width: ${breakpoint.desktop - 1}px)`,
  desktop: `(max-width: ${breakpoint.largeDesktop - 1}px)`,
  largeDesktop: `(min-width: ${size.largeDesktop})`,
};

