export const isSimpleUser = arr => {
  if (!arr || !Array.isArray(arr)) {
    return false;
  }
  const app = 's4bapp';
  const webuser = 's4bwebuser';

  return arr.includes(app) || arr.includes(webuser);
};

export const isManager = arr => {
  if (!arr || !Array.isArray(arr)) {
    return false;
  }
  const s4bweb = 's4bweb';

  return arr.includes(s4bweb);
};
