import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

const LoaderContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  position: fixed;
  z-index: ${props => props.theme.zindex.loader};

  animation: ${spinnerAnimation} 0.5s;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const SpinnerContainer = styled.div`
  width: 60px;
  height: 60px;

  position: relative;
  margin: auto;

  animation: ${spinnerAnimation} 0.5s;
`;

const Pulse = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.colors.primary.base};

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;

  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
`;

export const Spinner = () => (
  <SpinnerContainer>
    <Pulse />
  </SpinnerContainer>
);

const Loader = ({ isSpinning=false }) => (
  <>
    {isSpinning ? (
      <LoaderContainer>
        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      </LoaderContainer>
    ) : null}
  </>
);

Loader.propTypes = {
  isSpinning: PropTypes.bool.isRequired
};

export default connect(state => ({
  isSpinning: state.app.loader.isSpinning
}))(Loader);
