import PropTypes from 'prop-types';

export const ClausesInterface = PropTypes.shape({
  id: PropTypes.number,
  text: PropTypes.string,
  required: PropTypes.boolean
});

export const UserClausesInterface = PropTypes.shape({
  id: PropTypes.number,
  value: PropTypes.boolean,
  valid: PropTypes.boolean
});

export const AgreementsInterface = PropTypes.shape({
  title: PropTypes.string,
  text: PropTypes.string,
  clauses: PropTypes.arrayOf(ClausesInterface),
  user_clauses: PropTypes.arrayOf(UserClausesInterface)
});

export const AgreementsContentsInterface = PropTypes.shape({
  agreements: AgreementsInterface
});

export const LandingPageInterface = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variation_ids: PropTypes.array
});

export const ProductInterface = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  extra: PropTypes.shape({
    payment_information_id: PropTypes.string,
    period_information_id: PropTypes.string
  })
});

export const PaymentInformationInterface = PropTypes.shape({
  title: PropTypes.string,
  duration: PropTypes.string,
  periodicity: PropTypes.string,
  postpone_payment: PropTypes.string
});

export const PeriodInformationInterface = PropTypes.shape({
  title: PropTypes.string,
  duration: PropTypes.string,
  activation_mode: PropTypes.string
});

export const ProductVariation = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  list_price: PropTypes.string,
  price: PropTypes.string,
  sku: PropTypes.string,
  product_id: PropTypes.number,
  type: PropTypes.string,
  extra: PropTypes.shape({
    selection_ids: PropTypes.array,
    custom_pack_type: PropTypes.string
  }),
  details: ProductInterface,
  payment_information: PaymentInformationInterface,
  period_information: PeriodInformationInterface,
  child_variation: PropTypes.object
});

export const ProductVariationInterface = PropTypes.arrayOf(ProductVariation);

export const ProductCartInterface = PropTypes.shape({
  nid: PropTypes.number,
  sku: PropTypes.string,
  qty: PropTypes.number,
  unit_price: PropTypes.number,
  price: PropTypes.number
});

export const CreateCartInterface = PropTypes.shape({
  products: PropTypes.object,
  total_price: PropTypes.string
});

export const LatestProduct = PropTypes.shape({
  nid: PropTypes.string,
  sku: PropTypes.string,
  qty: PropTypes.string,
  unit_price: PropTypes.string,
  price: PropTypes.string
});

export const LatestCartInterface = PropTypes.shape({
  cart_nid: PropTypes.string,
  cart_uuid: PropTypes.string,
  products: PropTypes.arrayOf(LatestProduct),
  total_price: PropTypes.string
});

export const productType = {
  LEARNING_SUBSCRIPTION: 'Learning Subscription',
  LEARNING: 'Learning'
};
