import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import validator from 'utils/regex-validator';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import CustomInput from 'ui/atoms/CustomInput';
import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { USER_RESET_PASSWORD } from 'redux/actions/landingPage';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon,
  InputWrapper,
  Text
} from './style';
import { AUTHENTICATION_SIGNOUT } from 'redux/actions/authentication';

const modalId = 'ModalResetPassword';

const ModalResetPassword = ({ onClose, userResetPassword, logout }) => {
  const [email, setEmail] = useState('');

  const handleResetPassword = () => {
    logout();
    userResetPassword(email);
    onClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Title>
                <ProDisplay type="modalTitle">Reset Password</ProDisplay>
              </Title>
              <InputWrapper>
                <Text>
                  <ProText type="description">
                    Inserisci la tua e-mail. Ti invieremo una e-mail di conferma
                    con il link per ripristinare la password.
                  </ProText>
                </Text>
                <CustomInput
                  type="email"
                  placeholder="Indirizzo email"
                  onChange={value => setEmail(value)}
                  error={email && !validator.email(email)}
                />
              </InputWrapper>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    on
                    type={Button.TYPE.PRIMARY}
                    onClick={handleResetPassword}
                    text="Invia"
                    disabled={!validator.email(email)}
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ModalResetPassword.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  userResetPassword: PropTypes.func.isRequired,
  logout: PropTypes.func
};

ModalResetPassword.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    userResetPassword: email =>
      dispatch({ type: USER_RESET_PASSWORD._REQUEST, email }),
    logout: goTo => dispatch({ type: AUTHENTICATION_SIGNOUT._REQUEST, goTo })
  })
)(ModalResetPassword);
