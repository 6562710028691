import styled from 'styled-components/macro';

export const ChangePasswordContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  flex-direction: column;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 25px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3rem;
  height: calc(100vh - 50px - 50px);
  margin-bottom: 40px;

  @media ${props => props.theme.device.tablet} {
    height: calc(100vh - 50px - 59px);
  }
  @media ${props => props.theme.device.mobile} {
    height: calc(100vh - 50px - 100px);
  }
`;

export const Title = styled.div`
  padding-bottom: 22px;

  color: ${props => props.theme.colors.azureDark};
`;

export const InputWrapper = styled.div`
  padding-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  max-width: 177px;
  min-width: 177px;
  padding-top: 32px;
  padding-bottom: 100px;
  margin: 0 auto;

  @media ${props => props.theme.device.tablet} {
    max-width: 233px;
  }
  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
    margin-bottom: 15px;
  }
`;

// REMOVE?
export const SuggestionsWrapper = styled.div`
  padding-top: 20px;
`;
export const Suggestion = styled.div`
  color: ${({ compiled, theme }) =>
    compiled ? theme.colors.black : theme.colors.slateGray};
  padding-bottom: 12px;
  width: 100%;
  display: flex;
`;

export const SuggestionItem = styled.div``;

export const TitleSuggestion = styled.div`
  color: ${props => props.theme.colors.black};
  padding: 0.7rem 0;
`;

export const IconCheck = styled.div`
  margin-right: 5px;
  padding-top: 2px;
  color: ${({ valid, compiled, theme }) =>
    !compiled
      ? theme.colors.slateGray
      : valid === true
      ? theme.colors.primary.base
      : theme.colors.redError};
`;
