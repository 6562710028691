import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from 'routes';
import { push } from 'redux-first-history';

import { Container } from 'ui/gridSystem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';
import { ProfilePicture } from 'ui/components';
import { GET_USERS } from 'redux/actions/notes';
import { GET_FOLLOWERS, ADD_FOLLOW, REMOVE_FOLLOW } from 'redux/actions/social';
import ProText from 'ui/typography/proText';
import ScrollLoader from 'ui/atoms/ScrollLoader';

import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ContentWrapper,
  Icon,
  Head,
  Results,
  UserWrapper,
  Name,
  ButtonWrapper,
  Text
} from './style';
import { ModalGlobalContainer } from '../Modal.style';
import Modal, { stopEvent } from '../Modal';

const modalId = 'FollowerModal';

const FollowerModal = ({
  onClose,
  getAllUsers,
  users,
  getFollowers,
  followers,
  followed,
  removeFollow,
  addFollow,
  usersType,
  pushUrl,
  nextPagePresent,
  nextPage
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (usersType === 'all') getAllUsers();
    if (usersType === 'follower') getFollowers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersType]);

  useEffect(() => {
    if (usersType === 'all') setSelectedUsers(users);
    if (usersType === 'follower') setSelectedUsers(followers);
  }, [usersType, users, followers]);

  const onSave = () => {
    onClose();
  };

  const isFollowed = user => {
    return followed.findIndex(f => f === user.userId) >= 0;
  };

  const fetchData = () => {
    getAllUsers(nextPage);
  };

  return (
    <Modal id={modalId} onClose={onSave}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="478px"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onSave} />
              <Head>
                <Title>
                  <ProDisplay type="modalTitle">
                    {usersType === 'all' ? 'Utenti' : 'Followers'}
                  </ProDisplay>
                </Title>
              </Head>
              <Results id="scrollableDiv">
                <InfiniteScroll
                  dataLength={selectedUsers?.length}
                  height={300}
                  next={fetchData}
                  hasMore={nextPagePresent}
                  loader={<ScrollLoader />}
                  scrollableTarget="scrollableDiv"
                >
                  {selectedUsers?.length > 0 ? (
                    selectedUsers.map(user => (
                      <UserWrapper
                        key={user.userId}
                        onClick={() => {
                          onClose();
                          pushUrl(
                            `${routes.FollowProfileUser.path}/${user.userId}`
                          );
                        }}
                      >
                        <ProfilePicture
                          profile={{
                            pictureId: user.imageUrl,
                            firstname: user.givenName,
                            lastname: user.familyName
                          }}
                          widthContainer="35px"
                          heightContainer="35px"
                          fontSizeInitials={14}
                        />
                        <Name>
                          <ProDisplay type="nameUsers">
                            {user.givenName} {user.familyName}
                          </ProDisplay>
                        </Name>
                        <ButtonWrapper>
                          {isFollowed(user) ? (
                            <Button
                              text="FOLLOWING"
                              type="SECONDARY"
                              hover
                              fontSize={12}
                              height={35}
                              width={95}
                              onClick={e => {
                                e.stopPropagation();
                                removeFollow(user.userId);
                              }}
                            />
                          ) : (
                            <Button
                              text="FOLLOW"
                              type="PRIMARY"
                              hover
                              fontSize={12}
                              height={35}
                              width={95}
                              onClick={e => {
                                e.stopPropagation();
                                addFollow(user.userId);
                              }}
                            />
                          )}
                        </ButtonWrapper>
                      </UserWrapper>
                    ))
                  ) : (
                    <Text>
                      <ProText type="1">
                        Non ci sono
                        {usersType === 'all' ? ' utenti ' : ' followers '}
                        da visualizzare.
                      </ProText>
                    </Text>
                  )}
                </InfiniteScroll>
              </Results>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

FollowerModal.propTypes = {
  // HOC (connect, state)
  users: PropTypes.array,
  followers: PropTypes.array,
  followed: PropTypes.array,
  usersType: PropTypes.string,
  nextPagePresent: PropTypes.bool,
  nextPage: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func,
  getFollowers: PropTypes.func,
  removeFollow: PropTypes.func,
  addFollow: PropTypes.func,
  pushUrl: PropTypes.func
};

FollowerModal.id = modalId;

export default connect(
  state => {
    const {
      users,
      followers,
      followed,
      nextPagePresent,
      nextPage
    } = state.social;
    const { payload: { getUsers: usersType } = {} } = state.modal;
    return { users, followers, followed, usersType, nextPagePresent, nextPage };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    getAllUsers: (nextPage = null) =>
      dispatch({ type: GET_USERS._REQUEST, nextPage }),
    getFollowers: () => dispatch({ type: GET_FOLLOWERS._REQUEST }),
    addFollow: followId => dispatch({ type: ADD_FOLLOW._REQUEST, followId }),
    removeFollow: followId =>
      dispatch({ type: REMOVE_FOLLOW._REQUEST, followId })
  })
)(FollowerModal);
