import styled, { css } from 'styled-components/macro';

export const UserCardContainer = styled.div`
  width: 380px;
  height: 400px;
  border-radius: 6px;
  background-color: white;
  margin: 0 auto;
  margin-bottom: 15px;
  min-height: 338px;
  position: relative;
  padding: 16px;

  @media ${props => props.theme.device.laptop} {
    width: 300px;
    min-height: 343px;
  }

  @media ${props => props.theme.device.tablet} {
    width: 352px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 278px;
  }
`;

export const Content = styled.div`
  width: 100%;

  ${props =>
    props.stylerow &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      border-bottom: 1px solid ${props.theme.colors.ghostWhite};
    `}

  ${props =>
    props.stylecol &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    `}
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  font-size: 20px;
`;

export const Icon = styled.span`
  font-size: 22px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.mineShaft};
  margin-right: 10px;

  & div {
    margin-left: 5px;
  }

  &.smaller {
    font-size: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  border-bottom: solid 1px ${props => props.theme.colors.ghostWhite};
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const Contents = styled.div`
  display: flex;

  ${props =>
    props.desingCol &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: calc(50% - 17px);
      padding: 20px 0px;
    `}

  ${props =>
    props.designRow &&
    css`
      width: 100%;
      border-bottom: 1px solid ${props.theme.colors.ghostWhite};
      flex-direction: row;
      align-items: center;
      padding: 15px 0px;
    `}
`;

export const TitleContent = styled.div`
  color: ${props => props.theme.colors.mineShaft};

  @media ${props => props.theme.device.laptop} {
    width: 100%;
    padding-right: 8px;
  }

  @media ${props => props.theme.device.tablet} {
    padding-right: 0px;
  }

  @media ${props => props.theme.device.mobile} {
    padding-right: 8px;
  }
`;

export const ValueContent = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.azureDark};
  margin: 5px 0px;
  display: flex;
  span{
    display: inline-block;
    margin-right: 4px;
  }
  small {
    font-size: 65%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;

  @media ${props => props.theme.device.tablet} {
    margin-top: 10px;
  }

  button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const PlaylistEmpty = styled.div`
  margin-top: 15px;
`;

export const ValueWrapper = styled.div`
  width: 100%;
`;
