import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ChooseType = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    & div:last-child {
      margin-top: 10px;
    }
  }

  @media ${props => props.theme.device.mobile} {
    margin-top: 50px;
  }
`;

export const InputWrapper = styled.div`
  width: 200px;
  margin-left: 20px;
`;

export const Title = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const Text = styled.div`
  height: 100%;
  overflow-y: auto;
  margin-top: 10px;
  width: 100%;
  ${customScrollbar()}
`;

export const Link = styled.span`
  cursor: pointer;
  color: blue;
  span {
    text-decoration: underline;
    font-size: 12px;
  }
`;

export const Smaller = styled.span`
  font-size: 12px;
`;

export const CheckboxWrapper = styled.div`
  padding: 10px 0 15px 0;

  > label {
    color: ${props => props.theme.colors.greenDark};
    span {
      font-weight: 600;
      letter-spacing: 0.8px;
      font-size: 16px;
    }
  }
`;

export const Radio = styled.div`
  padding: 10px 0 15px 0;
  &.inline {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex: 1;
    padding: 0 0 0 20px;
    margin-right: 40px;

    & > label {
      width: fit-content;
      margin-bottom: 10px;
      margin-right: 20px;
    }

    @media ${props => props.theme.device.tablet} {
      padding: 10px 0 15px 0;
    }
  }
  &.start {
    justify-content: flex-start;
    padding: 10px 0 15px 0;
  }
`;

export const RadioDescription = styled.div.attrs(props => ({
  className: `${props.top ? 'top' : ''}`
}))`
  line-height: 1.22;
  font-size: 16px;
  margin-bottom: 20px;
  padding-top: 8px;
  padding-bottom: 8px;

  &.top {
    padding-bottom: 8px;
    padding-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 180px;
  margin: auto;
  margin-top: 10px;
`;

export const CheckboxDescription = styled.div`
  padding-bottom: 8px;
`;
