import styled, { css } from 'styled-components/macro';

export const ResumeContainer = styled.div`
  min-width: 780px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: var(--white);
  border-bottom: none;

  @media ${props => props.theme.device.tablet} {
    min-width: unset;
  }

  > div:not(:last-child) {
    padding: 10px 32px 0;
  }
`;
export const Headings = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.philippineGray};
  padding: 20px 20px 8px 20px;

  > div {
    border-bottom: solid 1px ${props => props.theme.colors.brightGray};
    padding-bottom: 10px;
  }
`;

export const QuantityHeading = styled.div`
  @media ${props => props.theme.device.mobile} {
    text-align: end;
  }
`;

export const ResumeDataContainer = styled.div`
  width: 100%;

  > div {
    border-bottom: dashed 1px ${props => props.theme.colors.brightGray};
    padding: 10px 0;
  }

  > div:first-child {
    padding-top: 0;
  }

  > div:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.brightGray};
  }

  &:first-child {
    border-bottom: 1px dashed ${props => props.theme.colors.brightGray};
  }
`;

export const ResumeOffer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const ResumeTipology = styled(ResumeOffer)`
  @media ${props => props.theme.device.mobile} {
    justify-content: flex-end;
  }
`;
export const ResumeQuantity = styled(ResumeOffer)`
  @media ${props => props.theme.device.mobile} {
    justify-content: flex-end;
  }
`;
export const ResumeCost = styled(ResumeOffer)`
  justify-content: center;
  
  ${({isDiscounted}) => isDiscounted && css`
    > span:first-child {
      text-decoration: line-through;
    }
    > span:last-child {
      color:  ${props => props.theme.colors.primary.base}
      margin-left: 5px;
    }
  `}
`;
export const ResumePrice = styled(ResumeOffer)`
  justify-content: center;
  @media ${props => props.theme.device.mobile} {
    justify-content: flex-end;
  }
`;

export const TotalContainer = styled.div`
  background-color: ${props => props.theme.colors.lotion};

  > div {
    border-bottom: solid 1px ${props => props.theme.colors.brightGray};
    padding-bottom: 10px;
  }
`;

export const IvaContainer = styled.div``;

export const ResumeFooter = styled.div`
  margin: 14px 0 0;
  padding: 14px 30px 16px 30px;
  background-color: ${props => props.theme.colors.ming};
  width: 100%;

  color: ${props => props.theme.colors.white};
`;

export const DiscountWrapper = styled.div`
  margin: 40px 0;
`;

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;

  & span:first-child {
    margin-right: 10px;
  }
`;

export const DiscountContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${props => props.theme.colors.brightGray};
  padding-bottom: 10px !important;
  flex-wrap: wrap;
`;

export const ColDiscount = styled.div`
  &.label {
    margin-right: 10px;
    @media ${props => props.theme.device.mobile} {
      margin-right: 0;
      width: 100%;
    }
  }
  &.resume {
    margin-left: auto;
    width: 12.5%;
    @media ${props => props.theme.device.mobile} {
      width: 25%;
    }
  }

  &.error-msg {
    width: 100%;
  }
`;

export const Icon = styled.div`
  font-size: 20px;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
  margin-left: 10px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    min-width: 100%;
  }
  & div.tooltip {
    min-width: 400px;
  }
`;

export const TooltipWrapper = styled.div`
  width: 24px;
`;

export const IconInfo = styled.span`
  color: ${props => props.theme.colors.primary.base};
  width: 24px;
  height: 24px;
  font-size: 24px;
  position: relative;
  top: 0;
`;
