import React from 'react';
import PropTypes from 'prop-types';

import { SelectInput, Option, Icon } from './styles';

const Select = ({
  error,
  focus,
  options,
  onChange,
  onBlur,
  onFocus,
  value,
  disabled,
  id,
  multiple
}) => (
  <>
    <SelectInput
      error={error}
      focus={focus}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      defaultValue=""
      id={id}
    >
      <Option disabled hidden value="" />
      {(options || []).map(opt => (
        <Option key={opt.label} value={opt.value}>
          {opt.label}
        </Option>
      ))}
    </SelectInput>
    {!disabled && (
      <Icon
        className="icon-btn-arrow"
        focus={focus}
        onClick={() => document.getElementById(id).focus}
      />
    )}
  </>
);

Select.propTypes = {
  error: PropTypes.bool,
  focus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

export default Select;
