import React from 'react';
import PropTypes from 'prop-types';

import { Container, LineBar, PercentageLine } from './style';

const ProgressBar = ({ percentage, deg, color1, color2 }) => (
  <>
    <Container>
      <LineBar>
        <PercentageLine
          percentage={percentage}
          deg={deg}
          color1={color1}
          color2={color2}
        />
      </LineBar>
    </Container>
  </>
);

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  deg: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string
};

ProgressBar.defaultProps = {
  percentage: 0,
  deg: '258deg',
  color1: '#e9dc9f',
  color2: '#90e4c7'
};

export default ProgressBar;
