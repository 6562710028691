import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import routes from 'routes';
import { TEAMS_PROGRESS_GET } from 'redux/actions/teams';
import { CollaboratorsFiltersInterface } from 'interface/teams';
import { withMediaQueries } from 'hoc/withMediaQueries';
import UserCard from 'ui/components/UserCard';
import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import UserList from 'ui/components/UserList';
import { SearchBar } from 'ui/atoms';
import SelectedCollectionModal from 'ui/components/Modals/SelectedCollectionModal';
import { MODAL_OPEN } from 'redux/actions';
import MainContainer from '../../../style';
import { SearchBarWrapper } from '../../style';
import {
  NavWrapper,
  Icon,
  Title,
  SearchContainer,
  SearchWrapper,
  IconSwitch,
  IconSearch,
  CardWrapper,
  UserCardContainer,
  UserListContainer
} from './style';

const AllActivity = ({
  list,
  filters,
  getCollaboratorsList,
  mediaIsDesktop,
  pushUrl,
  openSelectedCollectionModal,
  myUserId
}) => {
  useEffect(() => {
    getCollaboratorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedList, setSelectedList] = useState([]);
  const [gridView, changeView] = useState(true);
  const [visibleSearch, openSearch] = useState(true);
  const [search, setSearch] = useState(filters.search);

  const changeLocation = url => {
    pushUrl(url);
  };

  const handleOnchageSearch = value => {
    setSearch(value.toLowerCase());
  };

  const openModal = collection => {
    setSelectedList(collection);
    openSelectedCollectionModal();
  };

  return (
    <MainContainer>
      <Container>
        <NavWrapper>
          <Icon
            className="icon-btn-arrow"
            onClick={() => changeLocation(routes.teamsActivity.path)}
          />
          <Title>
            <ProDisplay type="menutitle">
              L'attività dei miei collaboratori
            </ProDisplay>
          </Title>
        </NavWrapper>
        <SearchWrapper>
          <SearchContainer>
            <ProDisplay type="label">
              {list ? list.length : 0} Utenti
            </ProDisplay>
            <IconSearch
              className="icon-ricerca"
              onClick={() => openSearch(!visibleSearch)}
            />
            {!visibleSearch ? (
              <SearchBarWrapper>
                <SearchBar
                  placeholder="Cerca"
                  onChange={value => handleOnchageSearch(value)}
                  value={search}
                  hidden
                />
              </SearchBarWrapper>
            ) : (
              ''
            )}
          </SearchContainer>
          {mediaIsDesktop && (
            <IconSwitch
              className={`${
                !gridView && mediaIsDesktop ? 'icon-griglia' : 'icon-lista'
              }`}
              onClick={() => changeView(!gridView)}
            />
          )}
        </SearchWrapper>
        {!gridView && mediaIsDesktop ? (
          <>
            {list &&
              ([...list] || [])
                .filter(
                  collaborator =>
                    collaborator.firstname.toLowerCase().includes(search) ||
                    collaborator.lastname.toLowerCase().includes(search)
                )
                .map((collaborator, index) => (
                  <UserListContainer key={`collaborator-${index}`}>
                    <UserList
                      user={collaborator}
                      openSelectedCollectionModal={openModal}
                      onClick={() => {
                        if (collaborator.userName !== myUserId)
                          pushUrl(
                            `${routes.followProfileManager.path}/${collaborator.userName}`
                          );
                        else pushUrl(`${routes.userProfile.path}`);
                      }}
                    />
                  </UserListContainer>
                ))}
          </>
        ) : (
          <CardWrapper>
            {list &&
              ([...list] || [])
                .filter(
                  collaborator =>
                    collaborator.firstname.toLowerCase().includes(search) ||
                    collaborator.lastname.toLowerCase().includes(search)
                )
                .map((collaborator, index) => (
                  <UserCardContainer key={`collaborator-list-${index}`}>
                    <UserCard
                      user={collaborator}
                      openSelectedCollectionModal={openModal}
                      onClick={() => {
                        if (collaborator.userName !== myUserId)
                          pushUrl(
                            `${routes.followProfileManager.path}/${collaborator.userName}`
                          );
                        else pushUrl(`${routes.userProfile.path}`);
                      }}
                    />
                  </UserCardContainer>
                ))}
          </CardWrapper>
        )}
        <SelectedCollectionModal list={selectedList} />
      </Container>
    </MainContainer>
  );
};

AllActivity.propTypes = {
  mediaIsDesktop: PropTypes.bool,

  //HOC (connect, dispatch)
  list: PropTypes.array,
  filters: CollaboratorsFiltersInterface,
  getCollaboratorsList: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  openSelectedCollectionModal: PropTypes.func.isRequired,
  myUserId: PropTypes.string
};

export default connect(
  state => {
    const { teams_progress_list: list, filters } = state.teams;
    const {
      user: { userId: myUserId }
    } = state.authentication;
    return {
      list,
      filters,
      myUserId
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getCollaboratorsList: () =>
      dispatch({
        type: TEAMS_PROGRESS_GET._REQUEST
      }),
    openSelectedCollectionModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'SelectedCollectionModal' })
  })
)(withMediaQueries(AllActivity));
