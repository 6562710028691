import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import routes from 'routes';
import { Footer } from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { CustomLeftArrow, CustomRightArrow } from 'ui/atoms/CarouselArrows';
// import { useAnalytics } from 'providers/analyticsProvider';
import { responsiveLandingCards } from 'utils/carousel';
import { GET_FULL_CATALOG } from 'redux/actions/landingPage';
import { MODAL_OPEN } from 'redux/actions';
import PlaylistDetailModal from 'ui/components/Modals/PlaylistDetailModal';

import {
  CardBody,
  CardTitle,
  CarouselWrapper,
  HtmlWrapper,
  IconArrow,
  LandingCard,
  Overlay,
} from 'ui/components/RenderLandingParagraph/style';
import { Head, HeadWrapper, Logo } from '../../style';
import {
  Body,
  Close,
  CloseWrapper,
  BackWrapper,
  IconBack,
  Title,
  Section,
  SectionTitle,
} from './style';
import { Link } from '../PricedCatalog/style';

const Catalog = ({
  mediaIsPhone,
  mediaIsTablet,
  mediaIsDesktop,
  mediaIsLaptop,
  pushUrl,
  domain,
  getFullCatalog,
  fullCatalog: catalogList = [],
  openModal,
}) => {
  const [size, setSize] = useState();
  const [fullCatalog, setFullCatalog] = useState(catalogList);

  // const { ga4IsInitialized, sendGA4Event } = useAnalytics();

  // const sendAnalytics = (label) => {
  //   ga4IsInitialized && sendGA4Event({
  //     eventCategory: 'catalog',
  //     eventAction: 'click',
  //     eventLabel: label,
  //   });
  // };

  useEffect(() => {
    const { needs = [] } = domain;
    if (needs && needs.length) {
      const catalogListWithOrder = [...catalogList].reduce((acc, val) => {
        const item = {
          ...val,
          order: [...needs].filter((need) => Number(val.need_id) === Number(need.id))[0]?.order || 999,
        };
        return [...acc, item];
      }, []);
      const catalogListSortedByOrder = [...catalogListWithOrder].sort((a, b) => a.order - b.order);
      setFullCatalog(catalogListSortedByOrder);
    } else {
      setFullCatalog(catalogList);
    }
  }, [domain, catalogList]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(document.getElementsByClassName('container')[0]?.offsetWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    getFullCatalog();
  }, [getFullCatalog]);

  const redirectHomepage = () => {
    pushUrl(routes.landingPage.path);
  };

  const handleClickOnPrices = () => {
    // sendAnalytics('go_to_prices');
    pushUrl(routes.pricedCatalog.path);
  };

  const handleClickLandingCard = (card) => {
    // sendAnalytics(`view_card__${card.id}`);
    openModal('PlaylistDetailModal', {
      ...card,
      listoflo: card.contents,
    });
  };

  const getCarousel = carousel => (
    <CarouselWrapper>
      {carousel?.length > 0 && (
        <Carousel
          swipeable
          responsive={responsiveLandingCards(size)}
          draggable={mediaIsPhone || mediaIsTablet}
          customRightArrow={
            mediaIsPhone || mediaIsTablet ? (
              <></>
            ) : (
              <CustomRightArrow size={window.innerWidth - size} />
            )
          }
          customLeftArrow={
            mediaIsPhone || mediaIsTablet ? (
              <></>
            ) : (
              <CustomLeftArrow size={window.innerWidth - size} />
            )
          }
          containerClass="react-multi-carousel-list container pl-0 pb-4 "
        >
          {carousel?.map(card => (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <LandingCard
              image={card.cover}
              key={card.id}
              onClick={() => handleClickLandingCard(card)}
              clickable
            >
              <Overlay>
                <>
                  <CardTitle>
                    <ProDisplay type="4" configuration={{ color: 'white' }}>
                      {card.title}
                    </ProDisplay>
                  </CardTitle>
                  <CardBody>
                    <ProDisplay type="label" configuration={{ color: 'white' }}>
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{
                          __html: card.body_trimmed,
                        }}
                        withEllipsis={
                          card.body_trimmed === card.body ? '' : '...'
                        }
                      />
                    </ProDisplay>
                  </CardBody>
                </>
              </Overlay>
            </LandingCard>
          ))}
        </Carousel>
      )}
    </CarouselWrapper>
  );

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={domain?.brand?.logo_white} />
            <CloseWrapper onClick={() => pushUrl(routes.landingPage.path)}>
              <Close className="icon-close-x" />
            </CloseWrapper>
          </Head>
        </Container>
      </HeadWrapper>
      <Body>
        <Container>
          <BackWrapper onClick={() => pushUrl(routes.landingPage.path)}>
            <IconBack className="icon-arrow-right" />
            <ProDisplay type="link">Torna alla homepage</ProDisplay>
          </BackWrapper>
          <Title>
            <ProDisplay
              type="title"
              configuration={{ color: 'primary', colorType: 'base' }}
            >
              Catalogo
            </ProDisplay>
          </Title>
          <Link onClick={handleClickOnPrices}>
            <ProDisplay type="link">Sfoglia il listino prezzi</ProDisplay>
            <IconArrow className="icon-btn-arrow" />
          </Link>
          {fullCatalog?.length > 0 &&
            fullCatalog.map(section => (
              <Section key={section.need_id}>
                <SectionTitle>
                  <ProDisplay type="pageTitle">{section.need_name}</ProDisplay>
                </SectionTitle>
                {getCarousel(section.playlists)}
              </Section>
            ))}
        </Container>
      </Body>
      <PlaylistDetailModal />
      {mediaIsDesktop || mediaIsLaptop ? <Footer /> : null}
    </>
  );
};

Catalog.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsDesktop: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,

  // HOC (connect, state)
  domain: PropTypes.object,
  fullCatalog: PropTypes.array,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getFullCatalog: PropTypes.func,
  openModal: PropTypes.func,
};

export default connect(
  state => {
    const { fullCatalog } = state.landingPage;
    return {
      domain: state.domain,
      fullCatalog,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getFullCatalog: () => dispatch({ type: GET_FULL_CATALOG._REQUEST }),
    openModal: (id, payload) => dispatch({ type: MODAL_OPEN, id, payload }),
  })
)(withMediaQueries(Catalog));
