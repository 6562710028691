import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';
import { ADD_COMMENT } from 'redux/actions/notes';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ContentWrapper,
  Icon,
  Head,
  AddComment,
  TextArea,
  BtnWrapper
} from './style';
import { ModalGlobalContainer } from '../Modal.style';
import Modal, { stopEvent } from '../Modal';

const modalId = 'AddCommentModal';

const AddCommentModal = ({ noteId, onClose, addComment }) => {
  const [comment, setComment] = useState('');

  const clearAndClose = () => {
    setComment('');
    onClose();
  };

  return (
    <Modal id={modalId} onClose={clearAndClose}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="478px"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={clearAndClose} />
              <Head>
                <Title>
                  <ProDisplay type="modalTitle">
                    Aggiungi un commento
                  </ProDisplay>
                </Title>
              </Head>
              <AddComment>
                <TextArea
                  placeholder="Scrivi qui..."
                  type="text"
                  onChange={e => setComment(e.target.value)}
                  value={comment || ''}
                />
                <BtnWrapper>
                  <Button
                    type={Button.TYPE.PRIMARY}
                    text="Aggiungi"
                    onClick={() => {
                      addComment(noteId, comment);
                      clearAndClose();
                    }}
                    disabled={comment.length === 0}
                  />
                </BtnWrapper>
              </AddComment>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

AddCommentModal.propTypes = {
  // HOC (connect, state)
  noteId: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired
};

AddCommentModal.id = modalId;

export default connect(
  state => {
    const { noteId } = state.modal.payload;
    return { noteId };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    addComment: (idNote, body) =>
      dispatch({ type: ADD_COMMENT._REQUEST, idNote, body })
  })
)(AddCommentModal);
