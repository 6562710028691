import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Img from 'ui/assets/img/group.png';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';

import {
  ContentWrapper,
  Image,
  Title,
  Subtitle,
  BtnContainer
} from 'pages/Signup/steps/Confirm/style';

import { Container } from 'ui/gridSystem';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { ConfirmPasswordInterface } from 'interface/configuration';
import routes from 'routes';

const ConfirmPassword = ({ confirm_password, pushUrl, reset_email }) => (
  <Container>
    <ContentWrapper>
      <Image src={Img} />
      <Title>
        <ProDisplay type="modalTitle">{confirm_password.title}</ProDisplay>
      </Title>
      <Subtitle>
        <ProDisplay configuration={{ lineHeight: 1.63 }}>
          {confirm_password.description}
        </ProDisplay>
      </Subtitle>
      <BtnContainer>
        <Button
          type={Button.TYPE.PRIMARY}
          onClick={() =>
            pushUrl(`${routes.changePassword.path}?email=${reset_email}`)
          }
        >
          {confirm_password.call_to_action}
        </Button>
      </BtnContainer>
    </ContentWrapper>
  </Container>
);

ConfirmPassword.propTypes = {
  // HOC withMediaQueires
  mediaIsPhone: PropTypes.bool.isRequired,
  confirm_password: ConfirmPasswordInterface,
  pushUrl: PropTypes.func.isRequired,
  reset_email: PropTypes.string
};

export default withMediaQueries(
  connect(
    state => {
      const { confirm_password } = state.configuration;
      const { reset_email } = state.landingPage;
      return {
        confirm_password,
        reset_email
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url))
    })
  )(ConfirmPassword)
);
