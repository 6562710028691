import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import { Footer } from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import {
  AUTHENTICATION_SIGNOUT,
  GET_DOMAINS,
} from 'redux/actions/authentication';
import {
  Head,
  Icon,
  Title,
  SubTitle,
  CardsContainer,
  Card,
  ConainerPortals,
  Logo,
} from './style';

const ChoosePortal = ({
  mediaIsDesktop,
  mediaIsLaptop,
  domains,
  choosePortal,
  logout,
}) => (
  <>
    <Container>
      <Head>
        <Icon className="icon-close-x" onClick={() => logout()} />
      </Head>
      <ConainerPortals>
        <Title>
          <ProDisplay type="title" configuration={{ color: 'azureDark' }}>
            A quale portale vuoi accedere?
          </ProDisplay>
        </Title>
        <SubTitle>
          <ProText type="description" configuration={{ color: 'mineShaft' }}>
            Il gruppo Digit’Ed offre varie piattaforme di learning.
            Seleziona la piattaforma a cui vuoi accedere.
          </ProText>
        </SubTitle>
        <CardsContainer>
          {domains?.map(d => (
            <Card
              key={d.id}
              onClick={() => {
                choosePortal(d.id);
              }}
            >
              <Logo src={d.brand.logo} alt={d.brand.name} />
            </Card>
          ))}
        </CardsContainer>
      </ConainerPortals>
    </Container>
    {mediaIsDesktop || mediaIsLaptop ? <Footer /> : null}
  </>
);

ChoosePortal.propTypes = {
  // HOC withMediaQueries
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  domains: PropTypes.array,

  // HOC (connect, dispatch)
  choosePortal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(
  state => ({ domains: state.authentication.user.domainList }),
  dispatch => ({
    choosePortal: domain => dispatch({ type: GET_DOMAINS._REQUEST, domain }),
    logout: () => dispatch({ type: AUTHENTICATION_SIGNOUT._REQUEST }),
  })
)(withMediaQueries(ChoosePortal));
