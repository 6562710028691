import produce from 'immer';
import {
  GET_LANDING_PAGE,
  REMEMBER_ME,
  RESET_PASSWORD_EMAIL,
  CREATE_USER,
  FIRST_ACCESS_SET,
  GET_CONTENTS_CAROUSEL,
  GET_LANDINGPAGE_PRODUCTS,
  GET_FULL_CATALOG
} from 'redux/actions/landingPage';

const initialState = {
  data: {},
  reset_email: '',
  rememberMe: false,
  user: {},
  headerFooterVisible: true,
  isFirstAccess: false,
  contentsCarousel: []
};

const landingPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LANDING_PAGE._SUCCESS: {
        const { response } = action;
        draft.data = { ...response[0] };
        break;
      }
      case REMEMBER_ME: {
        const { remember } = action;
        draft.rememberMe = remember;
        break;
      }
      case RESET_PASSWORD_EMAIL: {
        const { email } = action;
        draft.reset_email = email;
        break;
      }
      case CREATE_USER._SUCCESS: {
        const { user } = action;
        draft.user = user;
        break;
      }
      case FIRST_ACCESS_SET: {
        draft.isFirstAccess = true;
        break;
      }
      case GET_CONTENTS_CAROUSEL._SUCCESS: {
        const { response } = action;
        const newCarousels = state.contentsCarousel;
        newCarousels.push({
          id: `carousel-${action.index}`,
          data: response
        });
        draft.contentsCarousel = [...newCarousels];
        break;
      }
      case GET_LANDINGPAGE_PRODUCTS._SUCCESS: {
        const { response } = action;
        draft.landingProducts = response;
        break;
      }
      case GET_FULL_CATALOG._SUCCESS: {
        const { data } = action;
        const sortedCatalog = {};
        data.forEach(pl => {
          if (sortedCatalog[pl.need_id]) {
            sortedCatalog[pl.need_id].playlists.push({
              id: pl.id,
              title: pl.title,
              body: pl.body,
              body_trimmed: pl.body_trimmed,
              cover: pl.cover,
              contents: pl.contents
            });
          } else {
            sortedCatalog[pl.need_id] = {
              need_id: pl.need_id,
              need_name: pl.need_name,
              playlists: [
                {
                  id: pl.id,
                  title: pl.title,
                  body: pl.body,
                  body_trimmed: pl.body_trimmed,
                  cover: pl.cover,
                  contents: pl.contents
                }
              ]
            };
          }
        });
        draft.fullCatalog = Object.values(sortedCatalog);
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default landingPageReducer;
