import React from 'react';
import PropTypes from 'prop-types';

import Typeface, { TypefaceInterface } from './index';
import types from './proText.types';

const ProText = ({ children, configuration, type, currentViewport, ellipsis, tooltip, className }) => {
  const selectedType = (currentViewport && types[type] && types[type][currentViewport]) ?? types[type];
  return (
    <Typeface
      configuration={{
        ...selectedType,
        ...configuration
      }}
      tooltip={tooltip}
      ellipsis={ellipsis}
      className={className}
    >
      {children}
    </Typeface>
  )
};

ProText.propTypes = {
  children: PropTypes.node,
  configuration: TypefaceInterface,
  ellipsis: PropTypes.number,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(types)),
};

ProText.defaultProps = {
  type: '1'
};

export default ProText;
