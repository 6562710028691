import {
  takeLatest, put, call,
  // select
} from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import { ADD_WORKOUT } from 'redux/actions/workouts';
import notes from 'api/workouts';

import ModalErrors from 'ui/components/Modals/ModalErrors';

function* addWorkout({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const { user: { userId } = {} } = yield select(
    //   state => state.authentication
    // );
    // payload.owner = userId;
    const response = yield call(notes.addNote, payload);
    if (response.status === 200 || response.resultcode?.toString() === '200') {
      yield put({
        type: ADD_WORKOUT._SUCCESS,
        data: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > workouts > addWorkout', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addWorkoutWatch() {
  yield takeLatest(ADD_WORKOUT._REQUEST, addWorkout);
}

export default [
  addWorkoutWatch()
];
