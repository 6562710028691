import React from 'react';
import PropTypes from 'prop-types';

import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import ProDisplay from 'ui/typography/proDisplay';
import {
  DropZoneWrapper,
  IconWrapper,
  Text,
  Link,
  ErrorText,
  Icon,
  Description
} from './style';

const DropZone = ({ onDrop, onClick, validFormat, error }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(monitor);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;

  return (
    <DropZoneWrapper ref={drop}>
      {isActive ? (
        <Text>Trascina il file qui.</Text>
      ) : (
        <>
          <Text>
            <Description>
              <Link href="#" onClick={onClick}>
                <ProDisplay
                  type="categories"
                  configuration={{ color: 'primary', colorType: 'base' }}
                >
                  Carica
                </ProDisplay>
              </Link>
              <ProDisplay type="categories">
                o trascina un elenco da un file .csv
              </ProDisplay>
            </Description>
            <IconWrapper onClick={onClick}>
              <Icon className="icon-exit" />
            </IconWrapper>
          </Text>
          {!validFormat ? (
            <ErrorText>
              <ProDisplay
                type="categories"
                configuration={{ color: 'redError' }}
              >
                Formato non supportato.
              </ProDisplay>
            </ErrorText>
          ) : (
            error && (
              <ErrorText>
                <ProDisplay
                  type="categories"
                  configuration={{ color: 'redError' }}
                >
                  {error}
                </ProDisplay>
              </ErrorText>
            )
          )}
        </>
      )}
    </DropZoneWrapper>
  );
};

DropZone.propTypes = {
  onClick: PropTypes.func
};

DropZone.defaultProps = {
  validFormat: true
};

export default DropZone;
