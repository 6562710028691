import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import rootSaga from './sagas';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const historyApi = createBrowserHistory();

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({
  history: historyApi,
  oldLocationChangePayload: true,
  savePreviousLocations: 2
});

const composeEnhancers = process.env.REACT_APP_DEPLOY_ENV !== 'PROD'
  ? composeWithDevTools({ trace: true, traceLimit: 25, }, )
  : compose

export const store = createStore(
  combineReducers({
    router: routerReducer,
    ...rootReducer
  }),
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware)
  )
);

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
