import produce from 'immer';
import {
  ADD_COMMENT,
  ADD_NOTE,
  DELETE_NOTE,
  EDIT_NOTE,
  GET_NOTES,
  GET_USERS
} from 'redux/actions/notes';
import { USER_SIDE_INITIAL_STATE } from 'redux/actions/social';

const initialState = {
  notes: {
    notes: []
  },
  users: [],
  file: {
    imageUrl: ''
  }
};

const notesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_NOTES._SUCCESS: {
        draft.notes = action.data;
        break;
      }
      case ADD_NOTE._SUCCESS: {
        draft.notes.notes = [...state.notes.notes, action.data];
        break;
      }
      case EDIT_NOTE._SUCCESS: {
        draft.notes.notes = [
          action.data,
          ...state.notes.notes.filter(nt => nt.id !== action.data.id)
        ];
        break;
      }
      case GET_USERS._SUCCESS: {
        const newUsers = [...state.users, ...action.data.allusers];
        const newUniqueUsers = newUsers.filter(
          (u, idx) => newUsers.findIndex(nu => u.userId === nu.userId) === idx
        );
        draft.users = newUniqueUsers;
        break;
      }
      case DELETE_NOTE._SUCCESS: {
        draft.notes.notes = state.notes.notes.filter(n => n.id !== action.id);
        break;
      }
      case ADD_COMMENT._SUCCESS: {
        const editedNote = state.notes.notes.find(n => n.id === action.data.id);
        editedNote.comments = action.data.comments;
        draft.notes.notes = [
          editedNote,
          ...state.notes.notes.filter(nt => nt.id !== action.data.id)
        ];
        break;
      }
      case USER_SIDE_INITIAL_STATE: {
        draft.users = initialState.users;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default notesReducer;
