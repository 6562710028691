export const priceFormat = (value = 0) => {
  let price = value.toString();
  price = price.replace(',', '.');
  return price.indexOf('.') > -1
    ? price.slice(0, price.indexOf('.') + 3)
    : price;
};

export const priceTotalFormat = (value = 0) => {
  let price = value.toFixed(2).toString();
  price = price.replace('.', ',');
  return price.indexOf('.') > -1
    ? price.slice(0, price.indexOf('.') + 3)
    : price;
};


export const vatRemove = (value = 0) => {
  if (!value) return value;
  return (value / 1.22).toFixed(0);
};

export const calcPeriod = cards => {
  const cardPayment = cards.find(c => c.card_type === 'card_payment_period');
  if (cardPayment) {
    if (
      cardPayment.every_how_many_months === '1' &&
      cardPayment.number_of_payments === '12'
    ) {
      return 'mensile';
    }
    if (
      cardPayment.every_how_many_months === '6' &&
      cardPayment.number_of_payments === '2'
    ) {
      return 'semestrale';
    }
    if (
      cardPayment.every_how_many_months === '12' &&
      cardPayment.number_of_payments === '1'
    ) {
      return 'anno';
    }
    return `ogni ${cardPayment.every_how_many_months}  ${
      cardPayment.every_how_many_months === '1' ? 'mese' : 'mesi'
    }`;
  }
  return null;
};
