import React from 'react';
import { Icon } from './style';

const Visa = () => (
  <Icon className="icon-credit-cards-visa">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
    <span className="path4" />
    <span className="path5" />
    <span className="path6" />
  </Icon>
);

export default Visa;
