import React from 'react';
import { Icon } from './style';

const Excel = () => (
  <Icon className="icon-excel">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
  </Icon>
);

export default Excel;
