import React from 'react';
import PropTypes from 'prop-types';

import ProDisplay from 'ui/typography/proDisplay';

import Visa from 'ui/atoms/IconCreditCard/Visa';
import Amex from 'ui/atoms/IconCreditCard/Amex';
import MasterCard from 'ui/atoms/IconCreditCard/MasterCard';
import { Label, Description, Field, Text, Editable } from './style';

const getCardIcon = type => {
  let card_icon = <></>;
  switch (type) {
    case 'AmericanExpress':
      card_icon = <Amex />;
      break;
    case 'mastercard':
      card_icon = <MasterCard />;
      break;
    case 'visa':
      card_icon = <Visa />;
      break;
    default:
      break;
  }
  return card_icon;
};

const TextField = ({
  label,
  description,
  type,
  hasIcon,
  isEditable,
  onClickEdit,
  iconOnly,
}) => (
  <Field>
    <Label>
      <ProDisplay type="tableLabel">{label}</ProDisplay>
    </Label>
    <Text>
      {hasIcon ? getCardIcon(hasIcon) : ''}
      <Description className={type}>
        <ProDisplay type="greenTableLabel">{description}</ProDisplay>
      </Description>
    </Text>
    {isEditable ? (
      <Editable
        onClick={onClickEdit}
        className={iconOnly ? 'icon-modifica only' : 'icon-modifica'}
      />
    ) : (
      ''
    )}
  </Field>
);

TextField.TYPE = {
  TEXT: 'text',
  HIDDEN: 'hidden',
};

TextField.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  hasIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isEditable: PropTypes.bool,
  iconOnly: PropTypes.bool,
  type: PropTypes.string,
  onClickEdit: PropTypes.func,
};

export default TextField;
