import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const LineBar = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: ${props => props.theme.colors.ghostWhite};
  border: solid 1px ${props => props.theme.colors.filterColor};
  border-radius: 4px;
`;

const PercentageLine = styled.span`
  position: absolute;
  top: -1px;
  left: 0;
  width: ${props => (props.percentage > 0 ? `${props.percentage}%` : 0)};
  height: 6px;
  background: linear-gradient(
    ${props => props.deg},
    ${props => props.color1},
    ${props => props.color2}
  );
  border-radius: 4px;
`;

export { Container, LineBar, PercentageLine };
