import React from 'react';
import ProDisplay from 'ui/typography/proDisplay';

import { Loader } from './style';

const ScrollLoader = () => {
  return (
    <Loader>
      <ProDisplay
        type="3"
        configuration={{ color: 'primary', colorType: 'base' }}
      >
        Caricamento...
      </ProDisplay>
    </Loader>
  );
};

export default ScrollLoader;
