import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Image = styled.img`
  margin-bottom: 13px;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 372px;
  text-align: center;

  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const Subtitle = styled.div`
  width: 346px;
  margin-bottom: 35px;
  text-align: center;

  div {
    display: inline;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const EmailContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const InputContainer = styled.div`
  width: 342px;
  margin-right: 12px;

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 17px;
    margin-right: 0;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: 110px;
  @media ${props => props.theme.device.mobile} {
    width: 90%;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const ModalWrapper = styled.div`
  width: 100%;
`;
