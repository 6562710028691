import { createAsyncActionType } from './index';

export const GET_FIVE_MINUTES = createAsyncActionType(
  'fiveMinutes',
  'GET_FIVE_MINUTES'
);

export const GET_NEWS = createAsyncActionType('fiveMinutes', 'GET_NEWS');

export const SET_COURSE_FIVE_MINUTE = '@@fiveMinutes/SET_COURSE_FIVE_MINUTE';
