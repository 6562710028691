import styled from 'styled-components/macro';

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const Title = styled.div`
  padding-left: 25px;
  @media ${props => props.theme.device.tablet} {
    padding-left: 10px;
  }
`;

const NotifTitle = styled.div`
  font-weight: bold;
  display: flex;
  align-content: center;

  &.old {
    padding-left: 14px;
  }
`;

const Circle = styled.div`
  background-color: ${props => props.theme.colors.primary.base};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: auto 0;
  margin-right: 4px;
`;

const Bar = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.ghostWhite};
  color: ${props => props.theme.colors.greenDark};
  padding: 4px 16px;
`;

const Notification = styled.div`
  padding: 16px 20px;
  border-bottom: solid 1px ${props => props.theme.colors.filterColor};

  &:last-child {
    border-bottom: none;
  }

  &.new-notification {
    background-color: ${props => props.theme.colors.ghostWhite};
  }

  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;

const Description = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  width: 80%;
  padding-left: 14px;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
  }
`;

const Time = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  padding-left: 12px;

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    text-align: left;
  }
`;

const Shadow = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: ${props => props.theme.colors.ghostWhite};
  opacity: 0.6;
`;

const ErrorMsg = styled.div`
  padding: 16px;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  margin-right: 20px;
  cursor: pointer;
`;

export {
  Notification,
  Time,
  Description,
  Title,
  Bar,
  Head,
  ErrorMsg,
  Shadow,
  NotifTitle,
  Circle,
  Icon
};
