import styled, { css } from 'styled-components/macro';

export const PrivacyWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  cursor: pointer;
`;

export const Icon = styled.span`
  font-size: 22px;
  color: ${props => props.theme.colors.primary.base};
  margin-right: 10px;
`;

export const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.colors.primary.darker};
  vertical-align: super;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  cursor: pointer;
`;

export const CheckboxWrapperText = styled.div`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`;
