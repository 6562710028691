import * as React from 'react';

function IconPhoto(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={11}
      viewBox="0 0 12 11"
      {...props}
    >
      <g
        stroke="#FFF"
        strokeWidth={0.95}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 9.8h9.68V2.32H8.92L8.04 1H5.4l-.88 1.32H1z" />
        <path d="M8.92 5.84a2.2 2.2 0 10-4.4 0 2.2 2.2 0 004.4 0h0zM3.2 4.08h-.44" />
      </g>
    </svg>
  );
}

export default IconPhoto;
