import PropTypes from 'prop-types';

export const ActiveSubscriptionInterface = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  playlist_premium: PropTypes.number,
  subscription_type: PropTypes.string,
  is_deleted: PropTypes.bool,
  content_premium: PropTypes.array
});

export const SubscriptionsInterface = PropTypes.arrayOf(
  ActiveSubscriptionInterface
);

export const ActiveSubscriptionsInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  subscriptions: SubscriptionsInterface
});

export const SubscriptionPlanInterface = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  cover_image: PropTypes.string,
  subscription_plan_type: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  num_content: PropTypes.number
});

export const RecommendedInterface = PropTypes.arrayOf(
  SubscriptionPlanInterface
);

export const RecommendedSubscriptionsInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  subscription_plans: RecommendedInterface
});

export const TeamsInterestInterface = PropTypes.arrayOf(
  SubscriptionPlanInterface
);

export const TeamsInterestsInterface = PropTypes.shape([
  {
    total_items: PropTypes.number,
    count: PropTypes.number,
    from: PropTypes.number,
    subscription_plans: TeamsInterestInterface
  }
]);

export const Content = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  total_count: PropTypes.number
});

export const ContentOverview = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  contents: PropTypes.arrayOf(Content)
});

export const SubscriptionsList = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  frequency: PropTypes.string,
  duration: PropTypes.number
});

export const SubscriptionsListInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  packages: PropTypes.arrayOf(SubscriptionsList)
});

export const SubscriptionsFiltersInterface = PropTypes.shape({
  type: PropTypes.string,
  search: PropTypes.string
});
