import React from 'react';
import PropTypes from 'prop-types';

import { CollaboratorInterface } from 'interface/teams';

import { withMediaQueries } from 'hoc/withMediaQueries';

import ProDisplay from 'ui/typography/proDisplay';
import ProgressBar from 'ui/atoms/ProgressBar';
import { Button } from 'ui/atoms';
import ProText from 'ui/typography/proText';
import { ProfilePicture } from 'ui/components';
import {
  UserCardContainer,
  Header,
  Content,
  Title,
  Icon,
  UserInfo,
  IconWrapper,
  Contents,
  TitleContent,
  ValueContent,
  ButtonWrapper,
  ValueWrapper,
  PlaylistEmpty
} from './style';

const UserCard = ({
  user,
  mediaIsDesktop,
  mediaIsLaptop,
  mediaIsTablet,
  mediaIsPhone,
  openSelectedCollectionModal,
  onClick
}) => {
  const {
    firstname,
    lastname,
    counterNote,
    counterReview,
    profilepic,
    selectedCollection,
    collectionProgress,
    selectionProgress
  } = user;

  return (
    <UserCardContainer>
      <Header onClick={onClick}>
        <ProfilePicture
          profile={{
            imageurl: profilepic,
            firstname,
            lastname
          }}
          heightContainer="48px"
          widthContainer="48px"
          fontSizeInitials={24}
        />
        <UserInfo>
          <Title>
            <ProDisplay type="titleContentCard">
              {firstname} {lastname}
            </ProDisplay>
          </Title>
          <IconWrapper>
            <Icon className="icon-note smaller">
              <ProText type="label">{counterNote}</ProText>
            </Icon>
            <Icon className="icon-commenti">
              <ProText type="label">{counterReview}</ProText>
            </Icon>
          </IconWrapper>
        </UserInfo>
      </Header>
      <Content
        stylerow={mediaIsDesktop || mediaIsTablet}
        stylecol={mediaIsLaptop || mediaIsPhone}
      >
        <Contents
          desingCol={mediaIsDesktop || mediaIsTablet}
          designRow={mediaIsLaptop || mediaIsPhone}
        >
          <TitleContent>
            <ProDisplay
              type="link"
              configuration={{ fontWeight: 300, lineHeight: 'normal' }}
            >
              Contenuti per lo sviluppo
            </ProDisplay>
          </TitleContent>
          <ValueWrapper>
            <ValueContent>
              <ProDisplay type="modalTitle">
                {collectionProgress.complete}
              </ProDisplay>
              <ProDisplay type="link"> di </ProDisplay>
              <ProDisplay type="modalTitle">
                {collectionProgress.total}{' '}
              </ProDisplay>
              <ProDisplay type="link">fruiti</ProDisplay>
            </ValueContent>
            <ProgressBar
              percentage={
                (collectionProgress.complete / collectionProgress.total) * 100
              }
            />
          </ValueWrapper>
        </Contents>
        <Contents
          desingCol={mediaIsDesktop || mediaIsTablet}
          designRow={mediaIsLaptop || mediaIsPhone}
        >
          <TitleContent>
            <ProDisplay
              type="link"
              configuration={{ fontWeight: 300, lineHeight: 'normal' }}
            >
              Contenuti obbligatori
            </ProDisplay>
          </TitleContent>
          <ValueWrapper>
            <ValueContent>
              <ProDisplay type="modalTitle">
                {selectionProgress.complete}
              </ProDisplay>
              <ProDisplay type="link"> di </ProDisplay>
              <ProDisplay type="modalTitle">
                {' '}
                {selectionProgress.total}
              </ProDisplay>{' '}
              <ProDisplay type="link">fruiti</ProDisplay>
            </ValueContent>
            <ProgressBar
              percentage={
                (selectionProgress.complete / selectionProgress.total) * 100
              }
              className="progressbar"
            />
          </ValueWrapper>
        </Contents>
      </Content>
      <TitleContent>
        <ProDisplay type="link" configuration={{ fontWeight: 300 }}>
          Playlist selezionate
        </ProDisplay>
      </TitleContent>
      {[...selectedCollection].length > 0 && (
        <ButtonWrapper>
          {([...selectedCollection].slice(0, mediaIsDesktop ? 2 : 1) || []).map(
            (need, index) => (
              <Button
                key={`button-${index}`}
                text={need}
                type={Button.TYPE.TEAM}
              />
            )
          )}
          {[...selectedCollection].length > (mediaIsDesktop ? 2 : 1) && (
            <Button
              text={`+${[...selectedCollection].length -
                (mediaIsDesktop ? 2 : 1)}`}
              type={Button.TYPE.TEAM}
              onClick={() => openSelectedCollectionModal(selectedCollection)}
            />
          )}
        </ButtonWrapper>
      )}
      {[...selectedCollection].length <= 0 && (
        <PlaylistEmpty>
          <ProDisplay
            type="link"
            configuration={{ fontWeight: 500, lineHeight: 'normal' }}
          >
            Non ci sono contenuti per lo sviluppo selezionati
          </ProDisplay>
        </PlaylistEmpty>
      )}
    </UserCardContainer>
  );
};

UserCard.propTypes = {
  user: CollaboratorInterface,
  onClick: PropTypes.func,
  openSelectedCollectionModal: PropTypes.func,

  // HOC (withMediaQueries)
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,
  mediaIsTablet: PropTypes.bool,
  mediaIsPhone: PropTypes.bool
};

export default withMediaQueries(UserCard);
