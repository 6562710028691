import React from 'react';
import { Background, PlaceHolder } from './style';

const PlaceholderImage = ({ logo }) => {
  return (
    <Background>
      <PlaceHolder logo={logo} />
    </Background>
  );
};

export default PlaceholderImage;
