import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import { connect } from 'react-redux';
import {
  PageContainer,
  NavBarContainer,
  HeaderContainer,
  LogoContainer,
  StepContainer,
  Step,
  StepBar
} from './styles';

const OnBoardingLayout = ({ children, step, brand = '' }) => {
  const maxSteps = 2;

  return (
    <>
      <NavBarContainer>
        <Container>
          <HeaderContainer>
            <LogoContainer step={step} img={brand.logo_white} />
            <ProText>
              <StepContainer>
                <Step>{step}</Step> di {maxSteps}
              </StepContainer>
            </ProText>
          </HeaderContainer>
        </Container>
        <StepBar width={(step * 100) / maxSteps} />
      </NavBarContainer>
      <PageContainer>{children}</PageContainer>
    </>
  );
};

OnBoardingLayout.propTypes = {
  children: PropTypes.node,
  step: PropTypes.number,
  brand: PropTypes.object
};

export default connect(
  ({ domain: { brand } }) => ({ brand }),
  () => ({})
)(OnBoardingLayout);
