import styled from 'styled-components/macro';

export const EditBillingsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 97px);
  
  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - 86px);
  }
  @media ${props => props.theme.device.mobile} {
    min-height: calc(100vh - 140px);
  }
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.greenDark};
  text-transform: uppercase;
  padding-bottom: 12px;
  padding-top: 23px;
`;

export const Data = styled.div`
  display: flex;
  color:  ${props => props.theme.colors.greenDark};
  justify-content: space-between;
  text-align: end;
  width: 30%;
  
  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.div`
  color:  ${props => props.theme.colors.greenDark};
  width: 70%;
  display: flex;
`;

export const IconCard = styled.span`
  margin-right: 20px;
`;
