import { takeLatest, put, call, select } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import {
  VIEWS_GET,
  TEAMS_PROGRESS_GET,
  POSITIVE_GET,
  GET_NOTIFICATIONS
} from 'redux/actions/teams';

import teams from 'api/teams';
import ModalErrors from 'ui/components/Modals/ModalErrors';

function* teamsProgressGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { teams_progress_list = [] } = yield select(state => state.teams);
    if (teams_progress_list?.length) {
      yield put({
        type: TEAMS_PROGRESS_GET._SUCCESS,
        list: teams_progress_list
      });
    } else {
      const res = yield call(teams.getTeamProgess, { userId });
      const {
        data: { items: list = [], resultcode: code = '' } = {},
        status
      } = res;
      if (code?.toString() === '200' || status?.toString() === '200')
        yield put({ type: TEAMS_PROGRESS_GET._SUCCESS, list });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > teams > teamsProgressGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* teamsProgressGetWatch() {
  yield takeLatest(TEAMS_PROGRESS_GET._REQUEST, teamsProgressGet);
}

function* viewsGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { views = [] } = yield select(state => state.teams);
    if (views.length) {
      yield put({
        type: VIEWS_GET._SUCCESS,
        response: views
      });
    } else {
      const res = yield call(teams.getViews);
      const { data: { data = [], resultcode: code = '' } = {}, status } = res;
      if (code?.toString() === '200' || status?.toString() === '200') {
        yield put({ type: VIEWS_GET._SUCCESS, response: data });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > viewsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* viewsGetWatch() {
  yield takeLatest(VIEWS_GET._REQUEST, viewsGet);
}

function* positiveGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { positive = [] } = yield select(state => state.teams);
    if (positive.length) {
      yield put({
        type: POSITIVE_GET._SUCCESS,
        response: positive
      });
    } else {
      const res = yield call(teams.getPositive);
      const { data: { data = [], resultcode: code = '' } = {}, status } = res;
      if (code?.toString() === '200' || status?.toString() === '200') {
        yield put({ type: POSITIVE_GET._SUCCESS, response: data });
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > profile > positiveGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* positiveGetWatch() {
  yield takeLatest(POSITIVE_GET._REQUEST, positiveGet);
}

function* getNotifications() {
  try {
    yield put({ type: LOADER_OPEN });
    const { notifications: oldNotifications = [] } = yield select(
      state => state.teams
    );
    if (oldNotifications.length) {
      yield put({
        type: GET_NOTIFICATIONS._SUCCESS,
        notifications: oldNotifications
      });
    } else {
      const { user: { userId } = {} } = yield select(
        state => state.authentication
      );
      const res = yield call(teams.getNotifications, userId);
      const {
        data: { notifications, resultcode: code = '' } = {},
        status
      } = res;
      if (code?.toString() === '200' || status?.toString() === '200') {
        yield put({ type: GET_NOTIFICATIONS._SUCCESS, notifications });
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > profile > getNotifications', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNotificationsWatch() {
  yield takeLatest(GET_NOTIFICATIONS._REQUEST, getNotifications);
}

export default [
  viewsGetWatch(),
  teamsProgressGetWatch(),
  positiveGetWatch(),
  getNotificationsWatch()
];
