import React from 'react';
import PropTypes from 'prop-types';

import {
  CommonCardContainer,
  Header,
  Content,
  Title,
  Icon,
  Text
} from './style';

import ProDisplay from 'ui/typography/proDisplay';

const CommonCard = ({
  title,
  icon,
  children,
  hasHeader,
  hasBorder,
  type,
  hasIcon,
  text,
  onClick,
  hasTitleIcon,
  onClickIcon
}) => (
  <CommonCardContainer type={type} className={hasBorder ? 'border' : ''}>
    {hasHeader ? (
      <Header>
        <Title>
          <ProDisplay type="menutitle" configuration={{ fontSize: '20' }}>
            {title}
          </ProDisplay>
        </Title>
        {hasTitleIcon && (
          <Icon className={`${icon} align-left`} onClick={onClickIcon} />
        )}
        {hasIcon ? (
          <Icon className={icon} onClick={onClick} />
        ) : (
          <Text onClick={onClick}>
            <ProDisplay type="link">{text}</ProDisplay>
          </Text>
        )}
      </Header>
    ) : (
      ''
    )}
    <Content>{children}</Content>
  </CommonCardContainer>
);

CommonCard.TYPE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};
CommonCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  hasHeader: PropTypes.bool,
  hasBorder: PropTypes.bool,
  hasIcon: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    CommonCard.TYPE.SMALL,
    CommonCard.TYPE.MEDIUM,
    CommonCard.TYPE.LARGE
  ]),
  hasTitleIcon: PropTypes.bool,
  onClickIcon: PropTypes.func
};

export default CommonCard;
