import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import authentication from 'api/authentication';

export const StorageName = {
  Auth: 'Authorization',
  Maintenance: 'isMaintenance',
};
const EncodingSupported = !!window.btoa(StorageName.Auth);

export const cleanToken = (key='') => {
  if (key.length) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  } else {
    localStorage.clear();
    sessionStorage.clear();
  }
};

const errorStatement = (err = 'Generic error') => {
  // eslint-disable-next-line no-console
  console.error(
    'Something goes wrong refreshing the token, user must signin again',
    err
  );
  // eslint-disable-next-line no-use-before-define
  cleanToken(StorageName.Auth);
};

export const setTimeoutTokenRefresh = (value, local) => {
  if (document.timeoutRefresh) clearTimeout(document.timeoutRefresh);
  const timeoutRefresh = setTimeout(() => {
    clearTimeout(document.timeoutRefresh);
    authentication
      .refreshToken(value)
      .then(response => {
        const {
          data: { token, accesstoken, refreshtoken, expiresin } = {}
        } = response;
        if (token && accesstoken) {
          // eslint-disable-next-line no-use-before-define
          setToken(
            {
              token,
              accesstoken,
              refreshtoken,
              expiresin,
              userid: value.userid
            },
            local
          );
        } else {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log('Error Refreshing token', error);
      });
  }, (value.expiresin - 1200) * 1000);
  document.timeoutRefresh = timeoutRefresh;
};

export const setToken = (value, local = false, skipRefresh = false) => {
  try {
    if (!skipRefresh) {
      setTimeoutTokenRefresh(value, local);
    }
    let newValue = JSON.stringify({ ...value, timestamp: moment().valueOf() });

    if (EncodingSupported) newValue = window.btoa(newValue);
    if (local) {
      localStorage.setItem(StorageName.Auth, newValue);
    } else {
      sessionStorage.setItem(StorageName.Auth, newValue);
    }
  } catch (err) {
    errorStatement(err);
  }
};

const isValidToken = (
  { timestamp = moment().valueOf(), expiresin = 3600 } = {},
  deltaMinutes = 10
) =>
  moment().diff(moment(Number(timestamp)), 'minutes') <
  moment.duration(expiresin * 1000).asMinutes() - deltaMinutes;

// eslint-disable-next-line consistent-return
const decodeToken = token => {
  if (EncodingSupported) token = window.atob(`${token}`);
  return JSON.parse(token);
};

// eslint-disable-next-line consistent-return
const validateToken = token => {
  if (typeof token === 'object' && token !== null) {
    return isValidToken(token) ? token : null;
  }
  return null;
};

// eslint-disable-next-line consistent-return
const getTokenFlow = token => {
  try {
    let validToken = decodeToken(token);
    validToken = validateToken(validToken);
    return validToken;
  } catch (err) {
    errorStatement(err);
  }
};

export const getToken = () => {
  let token = localStorage.getItem(StorageName.Auth);
  if (token) return getTokenFlow(token);
  token = sessionStorage.getItem(StorageName.Auth);
  if (token) return getTokenFlow(token);
  return null;
};
