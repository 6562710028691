import React from 'react';
import PropTypes from 'prop-types';
import {
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  IbanElement
} from 'react-stripe-elements';

const StripeForm = ({ children }) => <Elements>{children}</Elements>;

StripeForm.propTypes = {
  children: PropTypes.node.isRequired
};

export default StripeForm;

export const StripeCardElement = ({ onBlur, onFocus, onReady, disabled }) => (
  <CardElement
    onBlur={onBlur}
    onFocus={onFocus}
    onReady={onReady}
    disabled={disabled}
  />
);

export const StripeCardNumberElement = ({
  onBlur,
  onFocus,
  onReady,
  disabled
}) => (
  <CardNumberElement
    onBlur={onBlur}
    onFocus={onFocus}
    onReady={onReady}
    disabled={disabled}
  />
);
// export const StripeCardNumberElement = injectStripe(({ onBlur, onFocus }) => <CardNumberElement onBlur={onBlur} onFocus={onFocus} />);

export const StripeCardExpiryElement = ({
  onBlur,
  onFocus,
  onReady,
  disabled
}) => (
  <CardExpiryElement
    onBlur={onBlur}
    onFocus={onFocus}
    onReady={onReady}
    disabled={disabled}
  />
);
// export const StripeCardExpiryElement = injectStripe(({ onBlur, onFocus }) => <CardExpiryElement onBlur={onBlur} onFocus={onFocus} />);

export const StripeCardCVCElement = ({
  onBlur,
  onFocus,
  onReady,
  disabled
}) => (
  <CardCVCElement
    onBlur={onBlur}
    onFocus={onFocus}
    onReady={onReady}
    placeholder="****"
    disabled={disabled}
  />
);
// export const StripeCardCVCElement = injectStripe(({ onBlur, onFocus }) => <CardCVCElement onBlur={onBlur} onFocus={onFocus} placeholder="****" />);

export const StripeIbanElement = ({ onBlur, onFocus, onReady, disabled }) => (
  <IbanElement
    onBlur={onBlur}
    onFocus={onFocus}
    onReady={onReady}
    supportedCountries={['SEPA']}
    placeholderCountry="IT"
    hideIcon
    disabled={disabled}
  />
);

const STRIPE_KEY_ENV = {
  DEV: null,
  SIT: 'pk_test_8N6EiwjbLEGVVLv2muRMCywW',
  PRE_PROD: null,
  PROD: 'pk_live_tssRbOlYSI8KRdDXThMEjGuq', //'pk_test_8N6EiwjbLEGVVLv2muRMCywW',

  DEFAULT: 'pk_test_8N6EiwjbLEGVVLv2muRMCywW'
};

export const StripeKey =
  STRIPE_KEY_ENV[process.env.REACT_APP_DEPLOY_ENV] || STRIPE_KEY_ENV.DEFAULT;
