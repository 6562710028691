import { FontBase } from 'const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'protext',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'unset',
  fontSize: FontBase, // 16
  cursor: 'inherit'
};

const types = {
  eventLabelHomepage: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 13, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 13, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 13, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 11, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 8, fontWeight: 700 },
  },
  eventTitleHomepage: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 20, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 20, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 20, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 16, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 13, fontWeight: 700 },
  },
  eventSessionHomepage: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 14, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 14, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 14, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 12, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 9, fontWeight: 700 },
  },
  loginSubTitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 20 },
    mediaIsDesktop: { ...baseConfig, fontSize: 16 },
    mediaIsLaptop: { ...baseConfig, fontSize: 16 },
    mediaIsTablet: { ...baseConfig, fontSize: 20 },
    mediaIsPhone: { ...baseConfig, fontSize: 16 },
  },
  loginPasswordRecovery: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 15 },
    mediaIsDesktop: { ...baseConfig, fontSize: 12 },
    mediaIsLaptop: { ...baseConfig, fontSize: 12 },
    mediaIsTablet: { ...baseConfig, fontSize: 15 },
    mediaIsPhone: { ...baseConfig, fontSize: 12 },
  },
  loginSignupCta: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 20 },
    mediaIsDesktop: { ...baseConfig, fontSize: 16 },
    mediaIsLaptop: { ...baseConfig, fontSize: 16 },
    mediaIsTablet: { ...baseConfig, fontSize: 20 },
    mediaIsPhone: { ...baseConfig, fontSize: 16 },
  },
  newsCardTitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 14, fontWeight: 500 },
    mediaIsDesktop: { ...baseConfig, fontSize: 14, fontWeight: 500 },
    mediaIsLaptop: { ...baseConfig, fontSize: 14, fontWeight: 500 },
    mediaIsTablet: { ...baseConfig, fontSize: 12, fontWeight: 500 },
    mediaIsPhone: { ...baseConfig, fontSize: 11, fontWeight: 500 },
  },
  newsCardLabel: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 10, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 10, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 10, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 9, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 8, fontWeight: 700 },
  },
  packageCardTitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 21 },
    mediaIsDesktop: { ...baseConfig, fontSize: 18 },
    mediaIsLaptop: { ...baseConfig, fontSize: 15, },
    mediaIsTablet: { ...baseConfig, fontSize: 17 },
    mediaIsPhone: { ...baseConfig, fontSize: 14 },
  },
  packageCardContents: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 14, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 12, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 10, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 9, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 8, fontWeight: 700 },
  },
  packageCardFooter: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 14 },
    mediaIsDesktop: { ...baseConfig, fontSize: 12 },
    mediaIsLaptop: { ...baseConfig, fontSize: 10, },
    mediaIsTablet: { ...baseConfig, fontSize: 10 },
    mediaIsPhone: { ...baseConfig, fontSize: 12 },
  },
  packageDetailSubtitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 23 },
    mediaIsDesktop: { ...baseConfig, fontSize: 20 },
    mediaIsLaptop: { ...baseConfig, fontSize: 20 },
    mediaIsTablet: { ...baseConfig, fontSize: 17 },
    mediaIsPhone: { ...baseConfig, fontSize: 16 },
  },
  packageDetailHero: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 25 },
    mediaIsDesktop: { ...baseConfig, fontSize: 25 },
    mediaIsLaptop: { ...baseConfig, fontSize: 25 },
    mediaIsTablet: { ...baseConfig, fontSize: 20 },
    mediaIsPhone: { ...baseConfig, fontSize: 20 },
  },
}

export default types;