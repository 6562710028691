import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 405px;
  text-align: center;
`;

export const Subtitle = styled.div`
  width: 320px;
  margin-bottom: 35px;
  text-align: center;
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const Image = styled.img`
  width: 140px;
  margin-bottom: 16px;
`;

export const IconCheck = styled.span`
  font-size: 15px;
  margin-right: 3px;
  color: ${props => props.theme.colors.lightGreen};
`;

export const Head = styled.div`
  padding-bottom: 5px;
`;

export const Bottom = styled.div`
  padding-top: 10px;
`;
