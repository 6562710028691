import { FontBase } from 'const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'prodisplay',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  fontSize: FontBase,
  cursor: 'inherit',
};

const types = {
  1: {
    ...baseConfig,
  },
  2: {
    ...baseConfig,
    fontWeight: 400,
  },
  3: {
    ...baseConfig,
    fontWeight: 500,
  },
  4: {
    ...baseConfig,
    fontWeight: 600,
  },
  5: {
    ...baseConfig,
    fontWeight: 700,
  },
  title: {
    ...baseConfig,
    fontSize: '30',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  totalCost: {
    ...baseConfig,
    fontSize: '30',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'primary',
    colorType: 'base',
  },
  landingtitle: {
    ...baseConfig,
    fontSize: '38',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'primary',
    colorType: 'base',
  },
  menutitle: {
    ...baseConfig,
    fontSize: '28',
    fontWeight: 300,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  label: {
    ...baseConfig,
    fontSize: '14',
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: 'normal',
  },
  link: {
    ...baseConfig,
    fontSize: '14',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 1.86,
  },
  tableLabel: {
    ...baseConfig,
    fontSize: '12',
    fontStretch: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
  },
  greenTableLabel: {
    ...baseConfig,
    fontSize: '14',
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: 'normal',
    color: '#5c5c5c',
  },
  green: {
    ...baseConfig,
    fontSize: '12',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: 'primary',
    colorType: 'base',
  },
  totPrice: {
    ...baseConfig,
    fontSize: '12',
    fontStretch: 'normal',
    fontWeight: 500,
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: 'azureDark',
  },
  modalTitle: {
    ...baseConfig,
    fontSize: '23',
    fontWeight: 600,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: 'primary',
    colorType: 'base',
  },
  deleted: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 300,
    fontStretch: 'normal',
    lineHeight: 1.07,
  },
  deleteBtn: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 600,
    fontStretch: 'normal',
    letterSpacing: '0.69px',
  },
  circle: {
    ...baseConfig,
    fontSize: '13',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.69,
  },
  titleContentCard: {
    ...baseConfig,
    fontSize: '21',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.1,
    letterSpacing: '0.33px',
  },
  productQtyLabel: {
    ...baseConfig,
    fontSize: '10',
    fontWeight: 500,
    fontStretch: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
  },
  subscriptionProducts: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
  },
  productPrice: {
    ...baseConfig,
    fontSize: '24',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'azureDark',
  },
  productPriceCurrency: {
    ...baseConfig,
    fontSize: '16',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'azureDark',
  },
  IVA: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'azureDark',
  },
  categories: {
    ...baseConfig,
    fontSize: '16',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: 'philippineGray',
  },
  bodyLanding: {
    ...baseConfig,
    fontSize: '16',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: 'mineShaft',
  },
  titleCollectionItem: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 500,
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: 'normal',
  },
  userName: {
    ...baseConfig,
    fontSize: '18',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.28',
    letterSpacing: '0.29px',
  },
  menuLabel: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.57',
    letterSpacing: 'normal',
    color: 'primary',
    colorType: 'base',
  },
  logout: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.57',
    letterSpacing: 'normal',
    color: 'blueDark',
  },
  pageTitle: {
    ...baseConfig,
    fontSize: '18',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.89',
    letterSpacing: '0.23px',
  },
  viewAll: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: '0.32px',
    color: 'white',
  },
  viewAllDetail: {
    ...baseConfig,
    fontSize: '9',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.18',
    letterSpacing: '0.07px',
    color: 'white',
  },
  playlistCardTitle: {
    ...baseConfig,
    fontSize: '20',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: '0.32px',
    color: 'white',
  },
  progressCardTitle: {
    ...baseConfig,
    fontSize: '22',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.27',
    letterSpacing: '0.35px',
    color: 'white',
  },
  nameUsers: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.64',
    letterSpacing: '0.22px',
  },

  paragraphTitle: {
    ...baseConfig,
    fontSize: 30,
    fontWeight: 600,
    color: 'primary',
    colorType: 'base',
  },
  sdsTitle: {
    fontSize: 25,
    fontWeight: 600,
    color: 'darkCharcoal',
  },
  digitalTraining: {
    ...baseConfig,
    fontSize: 18,
    lineHeight: 1.5,
    color: 'darkCharcoal',
  },
  sliderTitle: {
    ...baseConfig,
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 'normal',
    color: 'white',
  },
  sliderSubtitle: {
    ...baseConfig,
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 'normal',
    color: 'white',
  },
};

export default types;