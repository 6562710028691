import produce from 'immer';
import { GET_USERS } from 'redux/actions/notes';
import {
  ADD_BOOKMARK,
  ADD_FOLLOW,
  GET_BOOKMARKS,
  GET_FOLLOWED_INFO,
  GET_FOLLOWERS,
  GET_FOLLOW_COUNT,
  HANDLE_FEEDBACK,
  REMOVE_BOOKMARK,
  REMOVE_BOOKMARK_BY_ID,
  REMOVE_FOLLOW,
  USER_SIDE_INITIAL_STATE
} from 'redux/actions/social';

const initialState = {
  bookmarks: {
    bookmarklist: []
  },
  feedback: {},
  followCount: {},
  users: [],
  followers: [],
  followed: [],
  followUser: {},
  nextPagePresent: false,
  nextPage: null
};

const bookmarkReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_BOOKMARKS._SUCCESS: {
        draft.bookmarks = action.data;
        break;
      }
      case ADD_BOOKMARK._SUCCESS: {
        let oldBookmarks = state.bookmarks.bookmarklist;
        draft.bookmarks.bookmarklist = [...oldBookmarks, action.data.bookmark];
        break;
      }
      case REMOVE_BOOKMARK._SUCCESS: {
        draft.bookmarks.bookmarklist = state.bookmarks.bookmarklist.filter(
          bm => bm.itemId?.toString() !== action.data?.toString()
        );
        break;
      }
      case REMOVE_BOOKMARK_BY_ID._SUCCESS: {
        draft.bookmarks.bookmarklist = state.bookmarks.bookmarklist.filter(
          bm => bm.bookId !== action.data
        );
        break;
      }
      case HANDLE_FEEDBACK._SUCCESS: {
        draft.feedback = action.data;
        break;
      }
      case GET_FOLLOW_COUNT._SUCCESS: {
        draft.followCount = action.data;
        break;
      }
      case GET_USERS._SUCCESS: {
        const newUsers = [...state.users, ...action.data.allusers];
        const newUniqueUsers = newUsers.filter(
          (u, idx) => newUsers.findIndex(nu => u.userId === nu.userId) === idx
        );
        draft.users = newUniqueUsers;
        draft.followed = action.data.followed;
        draft.nextPagePresent = action.data.nextpagepresent;
        draft.nextPage = action.data.nextpage;
        break;
      }
      case GET_FOLLOWERS._SUCCESS: {
        draft.followers = action.data.followers;
        draft.followed = action.data.followed;
        break;
      }
      case ADD_FOLLOW._SUCCESS: {
        // const newFollow = state.users.find(u => u.userId === action.data);
        draft.followed.push(action.data);
        draft.followCount.followed = state.followCount.followed + 1;
        break;
      }
      case REMOVE_FOLLOW._SUCCESS: {
        draft.followed = state.followed.filter(f => f !== action.data);
        draft.followCount.followed = state.followCount.followed - 1;
        break;
      }
      case GET_FOLLOWED_INFO._SUCCESS: {
        const followProfile = {
          firstname: action.followUser.givenName,
          lastname: action.followUser.familyName,
          imageurl: action.followUser.imageUrl
        };
        draft.followUser = {
          profile: followProfile,
          followCount: action.followCount,
          pathProgress: action.followPath?.yourpathprogress
        };
        break;
      }
      case USER_SIDE_INITIAL_STATE: {
        draft.bookmarks = initialState.bookmarks;
        draft.users = initialState.users;
        draft.followed = initialState.followed;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default bookmarkReducer;
