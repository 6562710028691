import styled from 'styled-components/macro';

export const Image = styled.div`
  width: 100%;
  height: 191px;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;

  @media ${props => props.theme.device.laptop} {
    height: 158px;
  }
`;

export const Header = styled.div`
  display: flex;
  border-bottom: solid 1px ${props => props.theme.colors.lightSilver};
  flex-direction: column;
  min-height: 301px;

  @media ${props => props.theme.device.laptop} {
    min-height: 269px;
  }
`;

export const ContentCardContainer = styled.div`
  width: 382px;
  height: 410px;
  border-radius: 6px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white};
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 15px;
  margin-right: 20px;

  &:nth-child(n*3) {
    margin-right: 0;
  }

  @media ${props => props.theme.device.laptop} {
    padding: 16px;
    width: 302px;
    height: 370px;
  }

  @media ${props => props.theme.device.tablet} {
    width: 351px;
    margin-right: 0;
  }

  @media ${props => props.theme.device.mobile} {
    width: 302px;
  }

  &.social {
    height: 460px;
    @media ${props => props.theme.device.laptop} {
      height: 430px;
    }
    @media ${props => props.theme.device.tablet} {
      height: 410px;
    }
    @media ${props => props.theme.device.mobile} {
      height: 440px;
    }
  }
`;

export const Title = styled.div`
  color: black;
  color: ${props => props.theme.colors.greenDark};
  font-weight: 400;
  margin-top: 10px;
  text-transform: capitalize;
  height: 50px;

  @media ${props => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

export const Icon = styled.span`
  cursor: default;
  font-size: 26px;
`;

export const Contents = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
  color: ${props => props.theme.colors.mineShaft};
  margin-bottom: 16px;

  @media ${props => props.theme.device.mobile} {
    margin-top: 4px;
    margin-bottom: 15px;
  }
`;

export const Span = styled.span`
  margin-left: 7px;
  cursor: default;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;

  @media ${props => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

export const TotalPrice = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  display: flex;
  align-items: baseline;

  @media ${props => props.theme.device.tablet} {
    font-size: 16px;
  }

  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const Price = styled.span`
  display: flex;
  align-items: baseline;
  color: ${props => props.theme.colors.azureDark};
  margin-right: 14px;

  small {
    font-size: 65%;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  margin-bottom: 15px;
`;

export const ProfilePictures = styled.div`
  position: relative;
  width: 30%;
  height: 30px;

  @media ${props => props.theme.device.laptop} {
    width: 45%;
  }
  @media ${props => props.theme.device.tablet} {
    width: 30%;
  }
  @media ${props => props.theme.device.mobile} {
    width: 45%;
  }
`;

export const FirstPic = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  div {
    width: 100%;
    height: 100%;
  }
`;

export const Initials1 = styled.div`
  height: 30px;
  width: 30px;
  color: ${props => props.theme.colors.white};
  border-radius: 100%;
  background-color: ${props => props.theme.colors.primary.base};
  position: relative;
  margin-right: 6px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  position: absolute;
  z-index: 1;
`;

export const Initials2 = styled.div`
  height: 30px;
  width: 30px;
  color: ${props => props.theme.colors.white};
  border-radius: 100%;
  background-color: ${props => props.theme.colors.primary.base};
  margin-right: 6px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  position: absolute;
  top: 0;
  left: 12px;
`;

export const SecondPic = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  left: 12px;
  div {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.div``;

export const BtnWrapper = styled.div`
  width: 110px;

  button{
    padding: 0;
    width: 100%;
  }
  
  @media ${props => props.theme.device.tablet} {
    width: 116px;
  }
`;
