import Axios from 'axios';
import { getToken } from 'utils/token';
import { getProfilePictureMock, shouldUseMock } from './mock';

export const axiosApi = Axios.create({
  withCredentials: true
});

export default {
  getPicture: (pictureId = '') => {
    if (shouldUseMock()) {
      return new Promise(resolve => resolve(getProfilePictureMock()));
    }

    return axiosApi.get(pictureId, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: getToken().token
      }
    });
  },
  getResource: ({ resource = '' }) => {
    if (shouldUseMock()) {
      window.open('https://www.google.com', '_blank');
      return;
    }

    window.open(resource.replaceAll('{VALUE}', getToken().token), '_blank');
  }
};
