import styled from 'styled-components/macro';

const Steps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;
`;

const Step = styled.div`
  width: calc(100% / ${props => props.numOfSteps});
  height: 3px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.filterColor};

  &.active,
  &.visited {
    background-color: ${props => props.theme.colors.primary.base};
  }

  &.active {
    border-radius: 0px 4px 4px 0px;
  }
`;

export { Step, Steps };
