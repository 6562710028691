import styled, { css } from 'styled-components/macro';

const CustomInputNumberContainer = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  ${props =>
    props.disabled
    && css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const InputNumber = styled.input.attrs(() => ({
  type: 'number'
}))`
  width: 55px;
  height: 49px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  border: 1px solid ${props => props.theme.colors.lightSilver};
  border-radius: 4px;
  color: ${props => props.theme.colors.mineShaft};
  text-align: center;
  font-weight: bold;
  margin: 0 20px;

  -moz-appearance: textfield;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
`;

const QuantityNav = styled.div``;

const QuantityButton = styled.div`
  cursor: pointer;

  width: 30px;
  height: 30px;
  border-radius: 20.6px;
  border: solid 0.8px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center
    ${props =>
      props.disabled
      && css`
        opacity: 0.5;
        pointer-events: none;
        color: gray;
        background-color: ${props => props.theme.colors.platinum};
      `};
`;

const Icon = styled.span`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};

  ${props =>
    props.disabled
    && css`
      color: gray;
    `};
`;

export {
  InputNumber,
  Icon,
  QuantityNav,
  QuantityButton,
  CustomInputNumberContainer
};
