import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProText from 'ui/typography/proText';
import { GET_NOTIFICATIONS } from 'redux/actions/teams';
import {
  Head,
  Title,
  Description,
  Time,
  Notification,
  ErrorMsg,
  NotifTitle,
  Circle,
  Icon
} from './style';

const NotificationsBox = ({ onClose, getNotifications, notifications }) => {
  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <>
      <Head>
        <Title>
          <ProText type="sidebarTitle">Notifiche</ProText>
        </Title>
        <Icon className="icon-close-x" onClick={onClose} />
      </Head>
      {notifications && notifications.length > 0 ? (
        notifications.map((n, index) => (
          <Notification
            className="new-notification"
            key={`notification-${n.id}-${index}`}
          >
            <NotifTitle>
              <Circle />
              <ProText type="buttonText" configuration={{ lineHeight: 1.36 }}>
                {n.title}
              </ProText>
            </NotifTitle>
            <Description>
              <ProText type="label" configuration={{ lineHeight: 1.36 }}>
                {n.body}
              </ProText>
            </Description>
            <Time>
              <ProText type="time">
                {new Date(n.creationDate).toLocaleDateString()}
              </ProText>
            </Time>
          </Notification>
        ))
      ) : (
        <ErrorMsg>
          <ProText type="label" configuration={{ lineHeight: 1.36 }}>
            Non ci sono notifiche da visualizzare.
          </ProText>
        </ErrorMsg>
      )}
    </>
  );
};

NotificationsBox.propTypes = {
  notifications: PropTypes.array,

  // HOC (connect, dispatch)
  onClose: PropTypes.func,
  getNotifications: PropTypes.func
};

export default connect(
  state => {
    const { notifications } = state.teams;
    const { notificationToggle: status } = state.app;
    return { notifications, status };
  },
  dispatch => ({
    getNotifications: () => dispatch({ type: GET_NOTIFICATIONS._REQUEST })
  })
)(NotificationsBox);
