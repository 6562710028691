import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import routes from 'routes';
import { connect } from 'react-redux';

import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container, Row } from 'ui/gridSystem';
import { CustomInput, TextField } from 'ui/atoms';
import { Spinner } from 'ui/components';
import { PROFILE_DATA_PAYMENTS_GET } from 'redux/actions/profile';
import { lastPaymentsOptions } from 'utils/selectOptions';
import moment from 'moment';
import { priceTotalFormat } from 'utils/price-format';
import { CardContent, ColMediumTable, TableBody } from '../../style';
import MainContainer from '../../../style';
import {
  Header,
  IconBack,
  Labels, LoadMore,
  SelectLabel,
  SelectPeriodWrapper,
  SmallRow,
  TableContainer,
  Title
} from './style';

const LastPayments = ({
  pushUrl,
  mediaIsLaptop,
  mediaIsDesktop,
  billings,
  nextpagepresent,
  getBillings,
  company
}) => {
  const [filter, setFilter] = useState('all');
  const [filteredBillings, setFilteredBillings] = useState();

  useEffect(() => {
    getBillings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    setFilteredBillings(billings);
  }, [billings]);

  const handleOnChange = value => {
    setFilter(value);
  };

  const getDateByFilter = filterThis => {
    const now = new Date();
    switch (filterThis) {
      case lastPaymentsOptions[1].value:
        return new Date(now.setMonth(now.getMonth() - 3));
      case lastPaymentsOptions[2].value:
        return new Date(now.setMonth(now.getMonth() - 6));
      default:
        return new Date(0);
    }
  };

  useEffect(() => {
    const firstAcceptedDate = getDateByFilter(filter);
    const selectedBilling = billings?.filter(b => {
      if (!b?.orderDate) return true;
      const dateCreatd = new Date(b?.orderDate);
      return dateCreatd.getTime() > firstAcceptedDate.getTime();
    });
    setFilteredBillings(selectedBilling);
  }, [filter, billings]);

  const getDescriptionById = paymentMethod => (
    paymentMethod.type === 'BankTransfer' ? `IBAN termina con ${paymentMethod.paymentAccountNumber?.slice(paymentMethod.paymentAccountNumber.length - 4)}` : paymentMethod.paymentAccountNumber
  );

  return (
    <MainContainer>
      <Container>
        <Header>
          <IconBack
            className="icon-arrow-right"
            onClick={() => pushUrl(routes.managerProfile.path)}
          />
          <Title>
            <ProDisplay type="menutitle" configuration={{ color: 'azureDark' }}>
              Ultimi pagamenti
            </ProDisplay>
          </Title>
          <SelectLabel>
            <ProDisplay
              type="deleted"
              configuration={{ fontWeight: 500, color: 'philippineGray' }}
            >
              {billings?.length}{' '}
              {billings?.length > 1
                ? 'Pagamenti effettuati'
                : 'Pagamento effettuato'}
            </ProDisplay>
          </SelectLabel>
          <SelectPeriodWrapper>
            <CustomInput
              type="select"
              placeholder="Periodo"
              options={lastPaymentsOptions}
              onChange={handleOnChange}
              value={filter}
            />
          </SelectPeriodWrapper>
        </Header>
        {(mediaIsDesktop || mediaIsLaptop) && (
          <Labels>
            <Row>
              <ColMediumTable>
                <ProDisplay type="green">Ordine N.</ProDisplay>
              </ColMediumTable>
              <ColMediumTable>
                <ProDisplay type="green">Data Ordine</ProDisplay>
              </ColMediumTable>
              <ColMediumTable>
                <ProDisplay type="green">Totale N. Abbonamenti</ProDisplay>
              </ColMediumTable>
              <ColMediumTable>
                <ProDisplay type="green">Scadenza Abbonamenti</ProDisplay>
              </ColMediumTable>
              <ColMediumTable>
                <ProDisplay type="green">Modalità Pagamento</ProDisplay>
              </ColMediumTable>
              <ColMediumTable>
                <ProDisplay type="green">Stato</ProDisplay>
              </ColMediumTable>
              <ColMediumTable>
                <ProDisplay type="green">Totale Ordine</ProDisplay>
              </ColMediumTable>
            </Row>
          </Labels>
        )}

        <TableContainer>
          {filteredBillings ? (
            (filteredBillings || [])
              .filter(b => b.paymentStatus.toLowerCase() !== 'rejected' && b.paymentStatus.toLowerCase() !== 'pending')
              .map((item = {}, index) => (
                <TableBody
                  className={index === 0 ? '' : 'bordered'}
                  key={item.orderId}
                >
                  <CardContent>
                    <ColMediumTable>
                      <TextField
                        label={
                          mediaIsDesktop || mediaIsLaptop ? '' : 'N. Ordine'
                        }
                        description={item.paymentNumber}
                        type="text"
                      />
                    </ColMediumTable>
                    <ColMediumTable>
                      <TextField
                        label={
                          mediaIsDesktop || mediaIsLaptop ? '' : 'Data Ordine'
                        }
                        description={moment(item.paymentDate).format(
                          'DD/MM/YYYY'
                        )}
                        type="text"
                      />
                    </ColMediumTable>
                    <ColMediumTable>
                      {!(mediaIsDesktop || mediaIsLaptop) && (
                        <TextField label="N. Abbonamenti" type="text" />
                      )}
                      {item?.invoiceItemsList.map(i => (
                        <SmallRow>
                          <ProDisplay type="greenTableLabel">
                            {i.quantity} {i.productName}
                          </ProDisplay>
                        </SmallRow>
                      ))}
                    </ColMediumTable>
                    <ColMediumTable>
                      {!(mediaIsDesktop || mediaIsLaptop) && (
                        <TextField
                          label="Scadenza Abbonamenti"
                          description=""
                          type="text"
                        />
                      )}
                      {item?.invoiceItemsList.map(i =>
                        i.expirationDate ? (
                          <SmallRow>
                            <ProDisplay type="greenTableLabel">
                              {moment(i.expirationDate).format('DD/MM/YYYY')}
                            </ProDisplay>
                          </SmallRow>
                        ) : (
                          <SmallRow />
                        )
                      )}
                    </ColMediumTable>

                    <ColMediumTable>
                      <TextField
                        label={
                          mediaIsDesktop || mediaIsLaptop
                            ? ''
                            : 'Modalità Pagamento'
                        }
                        description={getDescriptionById(item.paymentMethod)}
                        type="text"
                        hasIcon={item.paymentMethod?.creditCardType?.toLowerCase() || ''}
                      />
                    </ColMediumTable>
                    <ColMediumTable>
                      <TextField
                        label={mediaIsDesktop || mediaIsLaptop ? '' : 'Stato'}
                        description={item?.paymentStatus}
                        type="text"
                      />
                    </ColMediumTable>
                    <ColMediumTable>
                      <TextField
                        label={
                          mediaIsDesktop || mediaIsLaptop ? '' : 'Totale Ordine'
                        }
                        description={`${priceTotalFormat(
                          item.paymentAmount
                        )} €`}
                        type="text"
                      />
                    </ColMediumTable>
                  </CardContent>
                </TableBody>
              ))
          ) : (
            <Spinner />
          )}
        </TableContainer>
        {nextpagepresent && (
        <LoadMore>
          <ProDisplay type="deleted" configuration={{ fontWeight: 600, fontSize: 16 }} onClick={() => getBillings(true)}>Mostra altro</ProDisplay>
        </LoadMore>
        )}
      </Container>
    </MainContainer>
  );
};

LastPayments.propTypes = {
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  company: PropTypes.object,
  billings: PropTypes.array,
  nextpagepresent: PropTypes.bool,
  getBillings: PropTypes.func.isRequired,
  //HOC withMediaQueries
  mediaIsLaptop: PropTypes.bool.isRequired,
  mediaIsDesktop: PropTypes.bool.isRequired
};

export default connect(
  state => {
    const { billings: { orders: billings = [], nextpagepresent } = {}, company } = state.profile;
    return { billings, nextpagepresent, company };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getBillings: ( loadMore = false ) => dispatch({ type: PROFILE_DATA_PAYMENTS_GET._REQUEST, loadMore })
  })
)(withMediaQueries(LastPayments));
