import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Head = styled.div`
  width: 420px;
  max-width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Title = styled.div``;

export const Icon = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const AddComment = styled.div`
  margin: 22px auto;
  width: 100%;
  max-width: 420px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  max-width: 600px;
  height: 100px;
  resize: none;
  border-radius: 4px;
  margin: 6px auto 10px 0;
  padding: 16px 10px 147px 20px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
  font-family: 'ProDisplay', sans-serif;
  font-size: 16px;
`;

export const BtnWrapper = styled.div`
  width: 140px;
  height: 52px;
`;
