import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withMediaQueries } from 'hoc/withMediaQueries';

import { Container } from 'ui/gridSystem';

import { Footer } from 'ui/components';
import MainContainer from 'pages/style';
import { HeaderIcon, Header, HeaderWrapper, Text } from 'pages/Profile/style';
import ProText from 'ui/typography/proText';
import { useParams } from 'react-router';
import routes from 'routes';
import { push } from 'redux-first-history';
import FollowProfile from 'ui/components/FollowProfile';

const FollowProfileManager = ({ pushUrl }) => {
  const { userId } = useParams();

  return (
    <>
      <MainContainer>
        <Header>
          <Container>
            <HeaderWrapper>
              <HeaderIcon
                className="icon-arrow-right first"
                onClick={() => pushUrl(routes.teamsActivity.path)}
              />
              <Text>
                <ProText type="title" configuration={{ color: 'white' }}>
                  Torna a Segui il team
                </ProText>
              </Text>
            </HeaderWrapper>
          </Container>
        </Header>
        <FollowProfile userId={userId} isSelection />
      </MainContainer>
      <Footer />
    </>
  );
};

FollowProfileManager.propTypes = {
  //HOC (connect, state)

  //HOC (connect, dispatch)
  pushUrl: PropTypes.func

  //HOC withMediaQueries
};

export default connect(
  state => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(FollowProfileManager));
