import produce from 'immer';
import { GET_ACTIVITY } from 'redux/actions/tracking';

const initialState = {
  startFrom: '0'
};

const trackingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ACTIVITY._SUCCESS: {
        draft.startFrom = action.data?.val ? action.data?.val : '0';
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default trackingReducer;
