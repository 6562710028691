import styled from 'styled-components/macro';
import {
  Head,
  UploadWrapper as OriginalUploadWrapper,
} from '../NotesModal/style';

export const Text = styled(Head)`
  margin: 20px 0;
`;

export const ErrorMsg = styled.div`
  color: ${props => props.theme.colors.primary.base};
  margin-bottom: 10px;
`;

export const UploadWrapper = styled(OriginalUploadWrapper)`
  height: auto
`;
