import produce from 'immer';
import {
  ADD_WORKOUT,
} from 'redux/actions/workouts';

const initialState = {
  file: undefined
};

const notesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ADD_WORKOUT._SUCCESS: {
        draft.workout.file = [...state.workout.file, action.data];
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default notesReducer;
