import styled from 'styled-components/macro';

export const Text = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  @media ${props => props.theme.device.mobile} {
    opacity: 0.9;
  }
  &.redText{
    color: ${props => props.theme.colors.primary.redWave2};
  }
`;
export const StatusLabel = styled.span`
  display: block;
  text-align: center;
  span{
    border: 2px solid ${props => props.theme.colors.primary.redWave2};;
    color: ${props => props.theme.colors.primary.redWave2};
    display: inline-block !important;
    width: 100%;
    line-height: 32px;
    border-radius: 18px;
    padding: 0 16px;
  }
  &.active{
    span{
      border-color: ${props => props.theme.colors.spanishGreen};
      color: ${props => props.theme.colors.spanishGreen};
    }
  }
`;


export const Icon = styled.div`
  font-size: x-large;
  text-align: right;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: ${props => props.theme.colors.lightSilver};
  }
`;

export const Circle = styled.div`
  color: ${props => props.theme.colors.azureDark};
  background-color: ${props => props.theme.colors.filterColor};
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 100%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media ${props => props.theme.device.mobile} {
    margin: 0;
  }
`;

export const Delete = styled.div`
  color: ${props => props.theme.colors.primary.base};
  text-align: center;
  cursor: pointer;
  width: 100%;
  padding-right: 10px;

  &:last-child {
    padding-left: 10px;
    border-left: 1px solid ${props => props.theme.colors.platinum};
  }
`;

export const Deleted = styled.div`
  text-align: right;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    position: absolute;
    right: 20px;
  }

  @media ${props => props.theme.device.mobile} {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
`;

export const TableCardContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  
  @media (min-width: 972px) {
    border-radius: 0;
  }
  &:nth-child(2){
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  &:last-child{
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    &:after {
      display: none;
    }
  }
  background-color: ${props => props.grayBg
    ? props.theme.colors.ghostWhite
    : props.theme.colors.white
  };
  ${props => props.activeTab ?
    `background-color: ${props.theme.colors.ghostWhite};

      @media (min-width: 972px) {
        border-radius: 20px;
      }
    ` : ''
  }

  @media (min-width: 972px) {
   &:after {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid ${props => props.theme.colors.platinum};
    }
  }

  @media ${props => props.theme.device.tablet} {
    padding-left: 17px;
    padding-right: 17px;
  }

  /* &.active {
    padding-top: 18px;
    padding-bottom: 18px;
    box-shadow: 0 2px 55px 0 rgba(171, 171, 171, 0.5);
  } */

  &.new-user {
    padding-top: 10px;
    padding-bottom: 10px;

    .row {
      align-items: center;
    }
  }

  &.deleted {
    opacity: 0.36;
  }

  .position-relative {
    position: relative;
  }
`;

export const ConfirmContainer = styled.div`
  color: ${props => props.theme.colors.white};
  margin-bottom: 10px;
  background-color: ${props => props.theme.colors.davysGray};
  padding: 10px 0;
  margin-left: -9999px;
  padding-left: 9999px;
  margin-right: -9999px;
  padding-right: 9999px;

  @media ${props => props.theme.device.mobile} {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: center;

  small {
    font-size: 65%;
  }

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

export const Iva = styled.span`
  font-size: 10px;
  margin-left: 4px;
`;

export const Label = styled.div`
  margin-right: 40px;

  @media ${props => props.theme.device.tablet} {
    margin-right: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 177px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-child {
    width: 133px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const ButtonWrapperEdit = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  flex-wrap: nowrap;
  margin-right: 20px;
  > button {
    max-width: 170px;
    @media ${props => props.theme.device.mobile} {
      max-width: 120px;
    }
  }
`;

export const ConfirmEditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 16px;
  }
`;

export const SelectWrapper = styled.div`
  height: 55px;
`;

export const SessionsPopup = styled.div`
  position: absolute;
  min-width: 35%;
  top: 100%;
  right: 0;
  padding: 20px;
  text-transform: capitalize;
  border-radius: 8px;
  z-index: 99;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary.base};

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    right: 50px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid ${props => props.theme.colors.primary.base};
  }
  > span {
    display: flex;
    line-height: 20px;
  }
  .mode {
    min-width: 60px;
    display: inline-block;
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

export const IconAccordion = styled(Icon)`
  transform: ${props => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: all 250ms;
  display: inline;
  color: ${props => props.theme.colors.primary.base};
  background-color: ${props => props.grayBg
    ? props.theme.colors.ghostWhite
    : props.theme.colors.white
  };
`;

export const AccordionWrapper = styled.div`
  margin-bottom: 10px;
  ${props => props.activeTab ?
    `
    border-radius: 20px!important;
    box-shadow: -4px 6px 12px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    background-color: ${props.theme.colors.ghostWhite};
    ` :
    ``}
`;

export const IconEdit = styled(Icon)`
  color: ${props => props.theme.colors.primary.redWave2};
`;

export const IconStatus = styled(Icon)`
  display: block;
  color: ${props => props.status === 'active'
    ? props.theme.colors.spanishGreen
    : props.theme.colors.primary.redWave2
  };
`;

