import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 372px;
  text-align: center;
`;

export const IconList = styled.span`
  font-size: 15px;
  color: ${props => props.theme.colors.lightGreen};
  margin-right: 8px;
  margin-top: 2px;
`;

export const ContentList = styled.ul`
  text-align: left;
  width: 100%;
  max-width: 420px;
  overflow-y: auto;
  ${customScrollbar()}
`;

export const ItemList = styled.li`
  text-align: left;
  margin-bottom: 5px;
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;
