import {
  userPasswordResetMock,
  getLandingPageMock,
  updatePasswordMock,
  shouldUseMock,
  getContentsCarouselMock,
  getBankLandingPageMock,
  setPasswordManagerMock,
  setPasswordManagerFromBankMock,
  getFullCatalogMock
} from './mock';
import {
  apiCall,
  baseUrl,
  extendUrl,
  internalPrefix,
  authPrefix
} from './config';

export default {
  userPasswordReset: body => {
    if (shouldUseMock()) {
      return userPasswordResetMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}resetpassword${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  updatePassword: body => {
    if (shouldUseMock()) {
      return updatePasswordMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}updatepassword${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  setPasswordManager: body => {
    if (shouldUseMock()) {
      return setPasswordManagerMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signup/setpasswordmanager${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  setPasswordManagerFromBank: body => {
    if (shouldUseMock()) {
      return setPasswordManagerFromBankMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signup/setpasswordmanagerfrombank${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  getLandingPage: () => {
    if (shouldUseMock()) {
      return getLandingPageMock();
    }
    return apiCall(`${baseUrl}${extendUrl}landing_page`, 'GET', null, {
      isDrupal: true
    });
  },
  getBankLandingPage: () => {
    if (shouldUseMock()) {
      return getBankLandingPageMock();
    }
    return apiCall(`${baseUrl}${extendUrl}landing_page?type=1`, 'GET', null, {
      isDrupal: true
    });
  },
  getContentsCarousel: ids => {
    if (shouldUseMock()) {
      return getContentsCarouselMock();
    }
    return apiCall(`/api/v1/carousel_contents/${ids}`, 'GET', null, {
      isDrupal: true
    });
  },
  getFullCatalog: () => {
    if (shouldUseMock()) {
      return getFullCatalogMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}content_catalog?items_per_page=100`,
      'GET',
      null,
      {
        isDrupal: true
      }
    );
  }
};
