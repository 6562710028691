import styled from "styled-components/macro";

export const HalfWidth = styled.div`
  width: 60%;

  @media ${(props) => props.theme.device.desktop} {
    width: 50%;
  }

  @media ${(props) => props.theme.device.laptop} {
    width: 50%;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: 100%;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const NoEvents = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;

  @media ${(props) => props.theme.device.desktop} {
    width: 50%;
  }

  @media ${(props) => props.theme.device.laptop} {
    width: 50%;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: 100%;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;
