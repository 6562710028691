import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import routes from 'routes';
import { Numeration } from 'ui/atoms';
import { PurchaseFooter } from 'ui/components';
import { preventBrowserBack } from 'utils/common';

import { FlowHeader, FlowHeaderWrapper, Logo } from 'pages/Signup/style';
import { SubscriptionPlanInterface } from 'interface/subscriptions';
import { PREMIUM_PURCHASE_SUBMIT } from 'redux/actions/subscriptions';
import { BILLING_GET_PAYMENT_METHODS } from 'redux/actions/billings';
import { Container } from 'ui/gridSystem';
import {
  Subscriptions
  // Billings
} from './steps';
import { PremiumPurchaseContainer } from './style';

preventBrowserBack();

const getNewStep = (num, name, path) => ({ num, name, path });
const STEPS = [
  getNewStep(1, 'subscriptions', routes.premiumSubscriptions.path)
];
const initialStep = STEPS[0];

const PremiumPurchase = ({
  selected_subscription_plan,
  pushUrl,
  new_users_subscriptions,
  onSubmit,
  brand,
  getPaymentMethods,
  router
}) => {
  useEffect(() => {
    if (window.location.hash !== '#') pushUrl(`${window.location.pathname}#`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [price, setPrice] = useState(0);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(initialStep);

  const nextStep = () => {
    if (Number(selectedUserIds.length)) {
      onSubmit(selectedUserIds);
    }
  };

  const updateTotal = (ids, totalPrice) => {
    setPrice(totalPrice.toFixed(2));
    setSelectedUserIds(ids);
  };

  const redirectHomepage = () => {
    pushUrl(routes.mySubscription.path);
    window.location.reload();
  };

  return (
    <>
      <FlowHeaderWrapper>
        <Container>
          <FlowHeader>
            <Logo onClick={redirectHomepage} img={brand?.logo_white} />
            <Numeration currentStep={step.num} totalSteps={STEPS.length} />
          </FlowHeader>
        </Container>
      </FlowHeaderWrapper>
      <PremiumPurchaseContainer>
        <Switch>
          {(selected_subscription_plan || new_users_subscriptions) && (
            <>
              <Route
                path={routes.premiumSubscriptions.path}
                exact
                render={() => (
                  <Subscriptions
                    plan={selected_subscription_plan}
                    onChange={(ids, totalValue) => updateTotal(ids, totalValue)}
                  />
                )}
              />
            </>
          )}
          <Redirect to={routes.allSubsriptions.path} />
        </Switch>
      </PremiumPurchaseContainer>
      <PurchaseFooter
        price={price}
        btnText="CONFERMA"
        btnBackText="ANNULLA"
        btnOnClick={nextStep}
        onClickBack={() =>
          pushUrl(
            router.previousLocations.length > 1
              ? router.previousLocations[2].location.pathname
              : routes.mySubscription.path
          )
        }
        btnDisabled={!Number(selectedUserIds.length)}
        isPremium
      />
    </>
  );
};

PremiumPurchase.propTypes = {
  // HOC (connect, state)
  new_users_subscriptions: PropTypes.array,
  selected_subscription_plan: SubscriptionPlanInterface,
  brand: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func,
  router: PropTypes.object
};

export default connect(
  state => {
    const {
      selected_subscription_plan,
      new_users_subscriptions
    } = state.subscriptions;
    const { brand } = state.domain;
    return {
      selected_subscription_plan,
      new_users_subscriptions,
      brand,
      router: state.router
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    onSubmit: (subscriptions = []) =>
      dispatch({ type: PREMIUM_PURCHASE_SUBMIT._REQUEST, subscriptions }),
    getPaymentMethods: () => dispatch({ type: BILLING_GET_PAYMENT_METHODS._REQUEST })
  })
)(PremiumPurchase);
