import { takeLatest, put, call, select, delay } from 'redux-saga/effects';

import { /* LOADER_CLOSE, LOADER_OPEN, */ MODAL_OPEN } from 'redux/actions';

import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  GET_ACTIVITY,
  GET_STATEMENTS,
  POST_STATEMENTS,
  PUT_ACTIVITY
} from 'redux/actions/tracking';
import tracking from 'api/tracking';
import { getBodyStatements } from 'utils/tracking';
import { setCallHeader, deleteCallHeader } from 'api/config';
// import playlist from 'api/playlist';
import {
  GET_COURSE,
  GET_PLAYLIST
  // UPDATE_PLAYLIST
} from 'redux/actions/playlist';

function* getStatements({ payload }) {
  try {
    //yield put({ type: LOADER_OPEN });
    const { user: { email } = {} } = yield select(
      state => state.authentication
    );
    const { type, itemId } = payload;
    const { data } = yield call(tracking.getStatements, email, type, itemId);
    yield put({
      type: GET_STATEMENTS._SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > tracking > getStatements', err);
  } finally {
    //yield put({ type: LOADER_CLOSE });
  }
}

function* getStatementsWatch() {
  yield takeLatest(GET_STATEMENTS._REQUEST, getStatements);
}

function* postStatements({ payload }) {
  try {
    //yield put({ type: LOADER_OPEN });
    const { user: { firstname, lastname, email } = {} } = yield select(
      state => state.authentication
    );
    const body = getBodyStatements({ firstname, lastname, email, ...payload });
    const res = yield call(tracking.postStatements, body);
    yield put({
      type: POST_STATEMENTS._SUCCESS
    });
    const { playlistSelected: { itemid: playlistId } = {} } = yield select(
      state => state.playlist
    );
    if (payload.type === 'completed' && res.status === 204) {
      yield delay(500);
      yield put({
        type: GET_COURSE._REQUEST,
        id: payload.itemId,
        isFiveMinutes: false
      });
      if (playlistId) {
        yield put({ type: GET_PLAYLIST._REQUEST, id: playlistId });
        // yield put({ type: UPDATE_PLAYLIST, id: payload.itemId });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > tracking > postStatements', err);
  } finally {
    //yield put({ type: LOADER_CLOSE });
  }
}

function* postStatementsWatch() {
  yield takeLatest(POST_STATEMENTS._REQUEST, postStatements);
}

function* getActivity({ payload }) {
  try {
    //yield put({ type: LOADER_OPEN });
    const { user: { email } = {} } = yield select(
      state => state.authentication
    );
    const { itemId } = payload;
    const { data } = yield call(tracking.getActivity, email, itemId);
    yield put({
      type: GET_ACTIVITY._SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > tracking > getActivity', err);
  } finally {
    //yield put({ type: LOADER_CLOSE });
  }
}

function* getActivityWatch() {
  yield takeLatest(GET_ACTIVITY._REQUEST, getActivity);
}

function* putActivity({ payload }) {
  try {
    //yield put({ type: LOADER_OPEN });
    const { user: { email } = {} } = yield select(
      state => state.authentication
    );
    const { itemId, body } = payload;
    setCallHeader('If-Match', '*');
    const { data } = yield call(tracking.putActivity, email, itemId, body);
    yield put({
      type: PUT_ACTIVITY._SUCCESS,
      data
    });
    deleteCallHeader('If-Match');
  } catch (err) {
    if (err?.response?.status !== 204) {
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: {
          errorText: 'Errore di caricamento'
        }
      });
    }
    // eslint-disable-next-line no-console
    console.log('sagas > tracking > putActivity', err);
  } finally {
    //yield put({ type: LOADER_CLOSE });
  }
}

function* putActivityWatch() {
  yield takeLatest(PUT_ACTIVITY._REQUEST, putActivity);
}

export default [
  getStatementsWatch(),
  postStatementsWatch(),
  getActivityWatch(),
  putActivityWatch()
];
