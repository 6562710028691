import React from 'react';
import PropTypes from 'prop-types';

import { CollaboratorInterface } from 'interface/teams';

import ProDisplay from 'ui/typography/proDisplay';
import ProgressBar from 'ui/atoms/ProgressBar';
import { Button } from 'ui/atoms';
import ProText from 'ui/typography/proText';
import {
  UserListContainer,
  Header,
  Content,
  Title,
  Icon,
  UserInfo,
  IconWrapper,
  Contents,
  TitleContent,
  ValueContent,
  ButtonWrapper,
  ValueWrapper,
  FootContent,
  PlaylistEmpty
} from './style';
import { ProfilePicture } from '..';

const UserList = ({ user, openSelectedCollectionModal, onClick }) => {
  const {
    firstname,
    lastname,
    counterNote,
    counterReview,
    profilepic,
    selectedCollection,
    collectionProgress,
    selectionProgress
  } = user;

  return (
    <UserListContainer>
      <Header onClick={onClick}>
        <ProfilePicture
          profile={{
            imageurl: profilepic,
            firstname,
            lastname
          }}
          heightContainer="48px"
          widthContainer="48px"
          fontSizeInitials={24}
        />
        <UserInfo>
          <Title>
            <ProDisplay type="titleContentCard">
              {firstname} {lastname}
            </ProDisplay>
          </Title>
          <IconWrapper>
            <Icon className="icon-note smaller">
              <ProText type="label">{counterNote}</ProText>
            </Icon>
            <Icon className="icon-commenti">
              <ProText type="label">{counterReview}</ProText>
            </Icon>
          </IconWrapper>
        </UserInfo>
      </Header>
      <Content>
        <Contents>
          <TitleContent>
            <ProDisplay
              type="link"
              configuration={{ fontWeight: 300, lineHeight: 'normal' }}
            >
              Contenuti per lo sviluppo
            </ProDisplay>
          </TitleContent>
          <ValueWrapper>
            <ValueContent>
              <ProDisplay type="modalTitle">
                {collectionProgress.complete}
              </ProDisplay>
              <ProDisplay type="link"> di </ProDisplay>
              <ProDisplay type="modalTitle">
                {collectionProgress.total}
              </ProDisplay>{' '}
              <ProDisplay type="link">fruiti</ProDisplay>
            </ValueContent>
            <ProgressBar
              percentage={
                (collectionProgress.complete / collectionProgress.total) * 100
              }
            />
          </ValueWrapper>
        </Contents>
        <Contents>
          <TitleContent>
            <ProDisplay
              type="link"
              configuration={{ fontWeight: 300, lineHeight: 'normal' }}
            >
              Contenuti obbligatori
            </ProDisplay>
          </TitleContent>
          <ValueWrapper>
            <ValueContent>
              <ProDisplay type="modalTitle">
                {selectionProgress.complete}
              </ProDisplay>
              <ProDisplay type="link"> di </ProDisplay>
              <ProDisplay type="modalTitle">
                {' '}
                {selectionProgress.total}{' '}
              </ProDisplay>
              <ProDisplay type="link">fruiti</ProDisplay>
            </ValueContent>
            <ProgressBar
              percentage={
                (selectionProgress.complete / selectionProgress.total) * 100
              }
              className="progressbar"
            />
          </ValueWrapper>
        </Contents>
      </Content>
      <FootContent>
        <TitleContent>
          <ProDisplay type="link" configuration={{ fontWeight: 300 }}>
            Playlist selezionate
          </ProDisplay>
        </TitleContent>
        {[...selectedCollection].length > 0 && (
          <ButtonWrapper>
            {([...selectedCollection].slice(0, 2) || []).map((need, index) => (
              <Button
                key={`button-list-${index}`}
                text={need}
                type={Button.TYPE.FILTER}
              />
            ))}
            {[...selectedCollection].length > 2 && (
              <Button
                text={`+${[...selectedCollection].length - 2}`}
                type={Button.TYPE.FILTER}
                onClick={() => openSelectedCollectionModal(selectedCollection)}
              />
            )}
          </ButtonWrapper>
        )}
        {[...selectedCollection].length <= 0 && (
          <PlaylistEmpty>
            <ProDisplay
              type="link"
              configuration={{ fontWeight: 500, lineHeight: 'normal' }}
            >
              Non ci sono contenuti per lo sviluppo selezionati
            </ProDisplay>
          </PlaylistEmpty>
        )}
      </FootContent>
    </UserListContainer>
  );
};

UserList.propTypes = {
  user: CollaboratorInterface,
  onClick: PropTypes.func,
  openSelectedCollectionModal: PropTypes.func.isRequired
};

export default UserList;
