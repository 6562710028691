import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Icon = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const InputWrapper = styled.div`
  width: 442px;
  height: 50px;
  margin-top: 25px;
  & span {
    margin: 0;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  & div:first-child {
    min-width: 442px;
    @media ${props => props.theme.device.mobile} {
      min-width: 100%;
    }
  }
`;

export const IconEdit = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  position: relative;
  top: 12px;
  right: 45px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  width: 140px;
  height: 52px;
  margin-top: 45px;
`;
