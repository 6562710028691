/* import styled from 'styled-components/macro';

const FormWrapper = styled.div.attrs(() => ({
  className: 'react-select'
}))`
  width: 100%;
  .react-select {
    width: 100%;
  }

  .react-select__menu {
    width: 100%;
  }

  .react-select__control {
    border: none;
    background: transparent;
    border: 0;
    box-shadow: none;
  }
  .react-select__control:hover {
    border: none !important;
    box-shadow: none !important;
  }

  .react-select__indicators {
    display: none;
    font-style: italic !important;
    font-weight: normal !important;
  }

  .react-select__single-value {
    color: ${props => props.theme.colors.white}!important;
  }

  .react-select__input {
    color: ${props => props.theme.colors.white}!important;
  }

  .react-select__placeholder {
    color: ${props => props.theme.colors.white}!important;
    font-style: italic !important;
    font-weight: normal !important;
  }

  .react-select:hover {
    outline: none;
  }
`;

export { FormWrapper };

 */
import styled from 'styled-components/macro';

const InputSearch = styled.input`
  padding-left: 30px;
  outline: none;
  font-size: 14px;
  width: 100%;
  height: 36px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;

  &::placeholder {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    color: ${props => props.theme.colors.lightSilver};
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: ${props => props.theme.colors.lightSilver};
    opacity: 1;
    line-height: 33px;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${props => props.theme.colors.lightSilver};
    opacity: 1;
    line-height: 33px;
  }
`;

const IconSearch = styled.span`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 14px;
  color: ${props => props.theme.colors.lightSilver};
  width: 14px;
  height: 14px;
  font-weight: 600;
`;

const Container = styled.div.attrs(props => ({
  className: `${props.mobile ? 'mobile' : ''}`
}))`
  position: relative;
  width: 100%;

  .link-route-search {
    color: white;
  }

  @media ${props => props.theme.device.tablet} {
    &.mobile {
      padding-left: 10px;

      form {
        display: flex;
        justify-content: flex-end;
      }

      ${IconSearch} {
        font-size: 23px;
      }
      ${InputSearch} {
        width: 0;
        padding: 0;
        font-size: 23px;
        transition: 0.5s all;
      }

      &.open {
        ${InputSearch} {
          width: 100%;
          transition: 0.5s all;
          padding-right: 35px;

          &::placeholder {
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export { Container, InputSearch, IconSearch };
