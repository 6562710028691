import {
  authenticationSigninMock,
  authenticationSignoutMock,
  getDomainAuthMock,
  refreshTokenMock,
  shouldUseMock
} from './mock';
import {
  apiCall,
  authPrefix,
  baseUrl,
  extendUrl,
  internalPrefix,
  channel,
  ssoPrefix
} from './config';

export default {
  authenticationSignin: body => {
    if (shouldUseMock()) {
      return authenticationSigninMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signin${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  authenticationSSOSignin: body => {
    if (shouldUseMock()) {
      return authenticationSigninMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}${ssoPrefix}signin?code=${body.code}&channel=${body.channel}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  authenticationSignout: body => {
    if (shouldUseMock()) {
      return authenticationSignoutMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signout${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  refreshToken: token => {
    if (shouldUseMock()) {
      return refreshTokenMock();
    }
    const { refreshtoken: refreshToken, userid: userId } = token;
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}refreshtoken${internalPrefix}`,
      'POST',
      { refreshToken, channel, userId },
      { withXApiKey: true, encryptBody: true }
    );
  },

  getDomain: body => {
    if (shouldUseMock()) {
      return getDomainAuthMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}accessdomain${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  }
};
