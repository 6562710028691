import produce from 'immer';

import { SIDEBAR_CLOSE, SIDEBAR_OPEN } from 'redux/actions';

const initialState = {
  id: '',
  open: false,
  payload: {}
};

const sidebarReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SIDEBAR_CLOSE: {
        draft = initialState;

        break;
      }

      case SIDEBAR_OPEN: {
        if (action.id) {
          draft.id = action.id;
          draft.open = true;
          draft.payload = action.payload || {};
        }

        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default sidebarReducer;
