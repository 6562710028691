import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ToolTipWrapper, ToolTipData } from './style';

const Tooltip = ({ text = '', children, customProps = {} }) => {
  return (
    <ToolTipWrapper {...customProps}>
      <ReactTooltip
        type="light"
        textColor="#5c5c5c"
        multiline
        className="tooltip"
        style={{ width: 'auto', wordBreak: 'break-all' }}
        overridePosition={({ left, top }, _e, _t, node) => {
          return {
            top: typeof node === 'string' ? left : Math.max(top, 10),
            left: typeof node === 'string' ? left : Math.max(left, 0)
          };
        }}
        {...customProps}
      />
      <ToolTipData data-tip={text} data-html={text.indexOf('</') > -1}>
        {children}
      </ToolTipData>
    </ToolTipWrapper>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.element,
  customProps: PropTypes.object
};

export default Tooltip;
