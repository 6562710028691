import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;

  > span {
    z-index: ${props => props.theme.zindex.body};
  }
`;

export const Title = styled.div`
  position: absolute;
  top: 5%;
  width: 100%;
  text-align: center;
`;

export const ContentListWrapper = styled.div`
  position: absolute;
  top: 20%;
`;

export const ContentList = styled.ul`
  text-align: center;
`;

export const ItemList = styled.li`
  text-align: center;
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;
