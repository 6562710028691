import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { APP_MENU_TOGGLE, SIDEBAR_CLOSE } from 'redux/actions';

import { Container, Checkbox, Label, IconWrapper, Icon } from './style';

const MenuButton = ({
  menuToggle,
  menuToggleOpen,
  notificationToggle,
  openNotifications,
  onCloseSidebar
}) => (
  <Container>
    <Checkbox checked={menuToggleOpen} onChange={() => {}} />
    <Label
      onClick={() => {
        menuToggle();
        onCloseSidebar();
        if (notificationToggle) openNotifications();
      }}
    >
      {/* <MenuIcon>&nbsp;</MenuIcon> */}
      <IconWrapper>
        {menuToggleOpen ? (
          <Icon className="icon-close-x" />
        ) : (
          <Icon className="icon-hamburger small" />
        )}
      </IconWrapper>
    </Label>
  </Container>
);

MenuButton.propTypes = {
  menuToggleOpen: PropTypes.bool.isRequired,
  notificationToggle: PropTypes.bool.isRequired,
  menuToggle: PropTypes.func,
  openNotifications: PropTypes.func,
  onCloseSidebar: PropTypes.func
};

export default connect(
  state => ({
    menuToggleOpen: state.app.menuToggleOpen,
    notificationToggle: state.app.notificationToggle
  }),
  dispatch => ({
    menuToggle: () => dispatch({ type: APP_MENU_TOGGLE }),
    onCloseSidebar: () => dispatch({ type: SIDEBAR_CLOSE })
  })
)(MenuButton);
