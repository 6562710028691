import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Img from 'ui/assets/img/group.png';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';

import {
  ContentWrapper,
  Image,
  Title,
  Subtitle,
  BtnContainer
} from 'pages/Signup/steps/Confirm/style';

import { Container } from 'ui/gridSystem';

import { withMediaQueries } from 'hoc/withMediaQueries';
import routes from 'routes';

const ConfirmChangedPassword = ({ mediaIsPhone, pushUrl }) => (
  <Container>
    <ContentWrapper>
      <Image src={Img} />
      <Title>
        <ProDisplay type="modalTitle">Password modificata!</ProDisplay>
      </Title>
      <Subtitle>
        <ProDisplay configuration={{ lineHeight: 1.63 }}>
          Clicca qui per accedere con le tue nuove credenziali.
        </ProDisplay>
      </Subtitle>
      <BtnContainer>
        <Button
          type={Button.TYPE.PRIMARY}
          onClick={() => pushUrl(routes.access.path)}
        >
          Accedi
        </Button>
      </BtnContainer>
    </ContentWrapper>
  </Container>
);

ConfirmChangedPassword.propTypes = {
  // HOC withMediaQueires
  mediaIsPhone: PropTypes.bool.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default withMediaQueries(
  connect(
    state => ({}),
    dispatch => ({
      pushUrl: url => dispatch(push(url))
    })
  )(ConfirmChangedPassword)
);
