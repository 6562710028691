import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import validator from 'utils/regex-validator';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';


import { Button, CustomInput } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import InviteAFriendImage from 'ui/assets/img/inviteafriend.png';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Image,
  EmailContainer,
  InputContainer,
  ButtonContainer,
  ModalWrapper
} from './style';

const modalId = '../InviteFriendModal';

const InviteFriendModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Image src={InviteAFriendImage} />
              <Title>
                <ProDisplay type="modalTitle">
                  Siamo tanti ma mai abbastanza!
                </ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{
                    fontSize: 18,
                    lineHeight: 1.5,
                    letterSpacing: '0.25px'
                  }}
                >
                  Regala ai tuoi amici un mese gratis e{' '}
                  <ProDisplay configuration={{ fontWeight: 'bold' }}>
                    {' '}
                    ottieni uno sconto del 10%
                  </ProDisplay>{' '}
                  sulla tua prossima playlist premium
                </ProDisplay>
              </Subtitle>
              <EmailContainer>
                <InputContainer>
                  <CustomInput
                    type="email"
                    placeholder="Email"
                    onChange={value => setEmail(value)}
                    error={email && !validator.email(email)}
                  />
                </InputContainer>
                <ButtonContainer>
                  <Button
                    onClick={onClose}
                    type={Button.TYPE.PRIMARY}
                    disabled={!validator.email(email)}
                  >
                    Invia
                  </Button>
                </ButtonContainer>
              </EmailContainer>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

InviteFriendModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

InviteFriendModal.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(InviteFriendModal);
