import styled from 'styled-components/macro';

export const UserListContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: white;
  height: 80px;
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  font-size: 20px;
`;

export const Icon = styled.span`
  font-size: 22px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.mineShaft};
  cursor: pointer;
  margin-right: 10px;

  &.smaller {
    font-size: 16px;
  }

  & div {
    margin-left: 5px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 6px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TitleContent = styled.div`
  color: ${props => props.theme.colors.mineShaft};
`;

export const ValueContent = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  color: ${props => props.theme.colors.azureDark};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  button {
    display: initial;
    margin-right: 10px;
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PlaylistEmpty = styled.div``;

export const ValueWrapper = styled.div`
  width: 100%;
`;

export const FootContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
