import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  InputNumber, QuantityNav, QuantityButton, Icon
} from './style';

const CustomInputNumber = ({
  value: val, min, max, onChange
}) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  useEffect(() => {
    if (onChange) onChange(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const updateValue = (newValue = value) => setValue(Math.max(Math.min(newValue, 99), 0));
  const increase = () => updateValue(value + 1);
  const decrease = () => updateValue(value - 1);

  return (
    <>
      <InputNumber min={min} max={max} value={value} onChange={({ target: { value: v } = {} }) => updateValue(v)} />
      <QuantityNav>
        <QuantityButton className="quantity-up" disabled={value >= max} onClick={increase}>
          <Icon className="icon-grow" />
        </QuantityButton>
        <QuantityButton className="quantity-down" disabled={value <= min} onClick={decrease}>
          <Icon className="icon-decrease" />
        </QuantityButton>
      </QuantityNav>
    </>
  );
};

CustomInputNumber.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func
};

CustomInputNumber.defaultProps = {
  value: 0,
  min: 0,
  max: 99
};

export default CustomInputNumber;
