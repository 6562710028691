import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';
import { SubTitle, Title } from 'pages/Signup/steps/UserAccount/style';
import { Checkbox } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import SearchBar from 'ui/atoms/SearchBar';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  SubscriptionPlanInterface,
  SubscriptionsFiltersInterface,
} from 'interface/subscriptions';
import {
  ALL_PROSPECT_GET,
  SET_PREMIUM_SUBSCRIPTIONS_FILTER,
} from 'redux/actions/subscriptions';

import { CollectionPrice } from 'const';
import { push } from 'redux-first-history';
import {
  SubscriptionsContainer,
  ListItems,
  Label,
  Labels,
  ListItem,
  LabelsWrapper,
  NotAviable,
  LabelNotAviable,
} from './style';
// eslint-disable-next-line import/order
import routes from 'routes';

const Subscriptions = ({
  mediaIsPhone,
  plan,
  onChange,
  assoc_subscriptions,
  premium_filters,
  setSubscriptionFilters,
  all_prospect,
  getAllProspect,
  pushUrl,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [search, setSearch] = useState(premium_filters.search);

  const { name, product_title, price = CollectionPrice, ...planData } = plan || {};

  useEffect(() => {
    if (!plan) {
      pushUrl(routes.mySubscription.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSearch = value => {
    setSearch(value);
    setSubscriptionFilters({
      search: value,
    });
  };

  useEffect(() => {
    if (!(all_prospect && Object.keys(all_prospect).length > 0)) getAllProspect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubscriptions(
      Object.keys(all_prospect || {}).reduce((acc, key) => {
        const array = (all_prospect[key] || []).map((item = {}) => {
          let countSel = (planData.learning_contents || []).filter(
            cl => cl.type === 'selection'
          ).length;

          let countCol = (planData.learning_contents || []).filter(
            cl => cl.type === 'collection'
          ).length;

          const custom_pack = (planData.learning_contents || []).filter(
            cl => cl.type === 'custom_pack'
          );

          const notAviable = Boolean(
            item.offerts.filter(
              of =>
                of.variationId.toString() ===
                  planData.variation_id.toString() &&
                of.status.toLowerCase() !== 'inactive'
            ).length
          );

          if (custom_pack.length) {
            custom_pack.forEach(cp => {
              if (cp.ref_content_type.toLowerCase() === 'selection') {
                countSel += parseInt(cp.limit);
              }
              if (cp.ref_content_type.toLowerCase() === 'collection') {
                countCol += parseInt(cp.limit);
              }
            });
          }

          return {
            email: item.email,
            notAviable,
            variations: [
              {
                variationType: planData?.type,
                subscriptionId: null,
                variationId: planData?.variation_id,
                productId: planData?.product_id,
                productPackage: {
                  countSel: countSel.toString(),
                  countCol: countCol.toString(),
                  listSel: (planData?.learning_contents || [])
                    .filter(cl => cl.type === 'selection')
                    .map(s => s.nid),
                  listCol: (planData?.learning_contents || [])
                    .filter(cl => cl.type === 'collection')
                    .map(c => c.nid),
                },
              },
            ],
          };
        });

        return [...acc, ...array];
      }, [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_prospect, assoc_subscriptions]);

  const onChangeSelectedAll = () => {
    setSelectedAll(prevState => !prevState);
    const totalPrice = !selectedAll
      ? (subscriptions?.filter(s => !s.notAviable)?.length || 0) *
        parseFloat(price)
      : 0;
    const ids = !selectedAll ? subscriptions.filter(s => !s.notAviable) : [];
    setUserIds(ids);
    ids.length > 0 ? onChange(ids, totalPrice) : onChange([], totalPrice);
  };

  const isChecked = ({ email = '' }) =>
    (userIds || []).findIndex(({ email: aEmail = '' }) => aEmail === email) >=
    0;

  const onChangeSelectSingle = sub => {
    const { email = '' } = sub;
    const ids = isChecked({ email })
      ? [...userIds].filter(({ email: aEmail = '' }) => aEmail !== email)
      : [...userIds, { ...sub }];
    const totalPrice = ids.length * parseFloat(price);

    setUserIds(ids);
    onChange(ids, totalPrice);
  };

  const applySearch = subscription => (subscription?.email).includes(search);

  const getFilteredArray = (array = []) => {
    if (search !== '') {
      return (array || []).filter(applySearch);
    }
    return array;
  };

  return (
    <>
      <Container>
        <Row>
          <SubscriptionsContainer>
            <Col md={10} sm={12}>
              <div>
                <Title>
                  <ProDisplay
                    type="title"
                    configuration={
                      mediaIsPhone ? { fontSize: '24', lineHeight: 1.22 } : null
                    }
                  >
                    Arricchisci l’abbonamento
                  </ProDisplay>
                </Title>
                <SubTitle>
                  <ProText type="description">
                    Sblocca la playlist premium <b>“{product_title ? product_title : name }”</b> a:
                  </ProText>
                </SubTitle>
              </div>
              <Col lg={4} md={6} sm={12} className="p-0">
                <SearchBar
                  placeholder="Cerca"
                  onChange={value => onChangeSearch(value)}
                  hidden
                  value={search}
                  onSubmit={evt => evt.preventDefault()}
                />
              </Col>
              <Col sm={12} className="p-0">
                <LabelsWrapper>
                  <Checkbox
                    onChange={onChangeSelectedAll}
                    checked={selectedAll}
                    label={
                      <Labels>
                        <Label>
                          <ProText type="1" configuration={{ fontSize: '12' }}>
                            Utenti
                          </ProText>
                        </Label>
                        {/*  {!mediaIsPhone ? (
                          <Label>
                            <ProText
                              type="1"
                              configuration={{ fontSize: '12' }}
                            >
                              Tipo di pacchetto
                            </ProText>
                          </Label>
                        ) : null} */}
                      </Labels>
                    }
                  />
                </LabelsWrapper>
              </Col>
              <Col sm={12} className="p-0">
                <ListItems>
                  {(getFilteredArray(subscriptions) || []).map(
                    (subscription = {}, idx) => (
                      <ListItem key={`user-${idx}`}>
                        <Checkbox
                          label={
                            <Labels>
                              <Label disabled={subscription.notAviable}>
                                <ProText
                                  type="1"
                                  configuration={{ fontSize: '14' }}
                                >
                                  {subscription?.email}
                                </ProText>
                              </Label>
                              {subscription.notAviable && (
                                <NotAviable>
                                  <ProText>
                                    <LabelNotAviable>
                                      Già presente nel piano formativo
                                    </LabelNotAviable>
                                  </ProText>
                                </NotAviable>
                              )}
                            </Labels>
                          }
                          name="user-checkbox"
                          onChange={() => onChangeSelectSingle(subscription)}
                          value={subscription?.email}
                          checked={isChecked({
                            email: subscription?.email,
                          })}
                          disabled={subscription.notAviable}
                        />
                      </ListItem>
                    )
                  )}
                </ListItems>
              </Col>
            </Col>
          </SubscriptionsContainer>
        </Row>
      </Container>
    </>
  );
};

Subscriptions.propTypes = {
  plan: SubscriptionPlanInterface,
  onChange: PropTypes.func.isRequired,

  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  //HOC (connect, state)
  premium_filters: SubscriptionsFiltersInterface,
  all_prospect: PropTypes.object.isRequired,
  assoc_subscriptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  //HOC (connect, dispatch)
  setSubscriptionFilters: PropTypes.func.isRequired,
  getAllProspect: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const {
      premium_filters,
      all_prospect,
      assoc_subscriptions,
    } = state.subscriptions;
    return {
      assoc_subscriptions,
      all_prospect,
      premium_filters,
    };
  },
  dispatch => ({
    getAllProspect: () => dispatch({ type: ALL_PROSPECT_GET._REQUEST }),
    setSubscriptionFilters: premium_filters =>
      dispatch({
        type: SET_PREMIUM_SUBSCRIPTIONS_FILTER,
        premium_filters,
      }),
    pushUrl: url => dispatch(push(url)),
  })
)(withMediaQueries(Subscriptions));
