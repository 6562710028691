import styled from 'styled-components/macro';

export const ConainerPortals = styled.div`
  max-width: 800px;
  min-height: 90vh;
  margin: 0 auto;
`;

export const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
`;

export const Icon = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
`;

export const Title = styled.h1``;

export const SubTitle = styled.h2``;

export const CardsContainer = styled.div`
  margin: 35px auto 5px;
  max-width: 780px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: 48%;
  height: 150px;
  margin-bottom: 30px;
  padding: 58px 88px 59px 89px;
  border-radius: 6px;
  box-shadow: 0 10px 30px 0 rgba(11, 58, 78, 0.1);
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.color};
  cursor: pointer;
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const Logo = styled.img``;
