import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProText from 'ui/typography/proText';
import {
  StreghtWrapper,
  StrenghtBar,
  StrenghtLabel,
  StrenghtValue
} from './style';

const PasswordStrength = ({ password }) => {
  const [valColor, setValColor] = useState();

  const labelSecurity = {
    '#f48585': 'scarsa',
    '#d5d532': 'debole',
    orange: 'appena sufficiente',
    '#88e688': 'accettabile',
    '#23b023': 'efficace'
  };
  const widthSecurity = {
    '#f48585': '15%',
    '#d5d532': '25%',
    orange: '55%',
    '#88e688': '75%',
    '#23b023': '100%'
  };

  useEffect(() => {
    const hasNumber = value => new RegExp(/[0-9]/).test(value);
    const hasMixed = value =>
      new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
    const hasSpecial = value =>
      new RegExp(/[*.+!\-@=$%^&(){}[\]?"#/\\,><':;|_~`]/).test(value);

    const strengthColor = count => {
      if (count < 2) return '#f48585';
      if (count < 3) return '#d5d532';
      if (count < 4) return 'orange';
      if (count < 5) return '#88e688';
      if (count < 6) return '#23b023';
      return '';
    };

    const strengthIndicator = value => {
      let strengths = 0;
      if (value.length > 5) strengths += 1;
      if (value.length > 7) strengths += 1;
      if (hasNumber(value)) strengths += 1;
      if (hasSpecial(value)) strengths += 1;
      if (hasMixed(value)) strengths += 1;
      return strengths;
    };
    if (password) setValColor(strengthColor(strengthIndicator(password)));
  }, [password]);

  return (
    <StreghtWrapper>
      <StrenghtBar>
        <StrenghtValue valColor={valColor} widthBar={widthSecurity[valColor]} />
      </StrenghtBar>
      <StrenghtLabel>
        <ProText type={'4'}>Sicurezza:</ProText>
        <ProText type={'2'}>{labelSecurity[valColor]}</ProText>
      </StrenghtLabel>
    </StreghtWrapper>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string
};

export default PasswordStrength;
