import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';

import Img from 'ui/assets/img/group.png';
import { ModalWrapper } from '../InviteFriendModal/style';
import { Title, Subtitle, ContentWrapper, Icon, Image, Head } from './style';

const modalId = 'ResendOTPModal';

const ResendOTPModal = ({ onClose, isOpen }) => {
  const [timeoutId, setTimeoutId] = useState();
  const closeModal = () => {
    return window.setTimeout(onClose, 4000);
  };
  const clearTimer = () => window.clearTimeout(timeoutId);
  useEffect(() => {
    isOpen && setTimeoutId(closeModal());
    !isOpen && clearTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Image src={Img} />
              <Title>
                <ProDisplay type="modalTitle">
                  Email inviata con successo
                </ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  <Head>
                    A breve riceverai una nuova email con il codice OTP da
                    inserire.
                  </Head>
                </ProDisplay>
              </Subtitle>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ResendOTPModal.propTypes = {
  // HOC (connect, state)
  isOpen: PropTypes.bool,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

ResendOTPModal.id = modalId;

export default connect(
  state => {
    const { open: isOpen } = state.modal;
    return {
      isOpen
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(ResendOTPModal);
