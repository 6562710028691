import styled from 'styled-components/macro';

const SubscriptionsContainer = styled.div`
  background-color: ${props => props.theme.colors.transparent};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 110px - 90px);

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - 160px - 90px);
  }
  @media ${props => props.theme.device.mobile} {
    min-height: calc(100vh - 270px - 90px);
  }
`;

const ListItems = styled.div``;

const ListItem = styled.div`
  border-bottom: solid 1px ${props => props.theme.colors.filterColor};
  padding: 18px 0;

  &:first-child {
    border-top: none;
  }
`;

const Labels = styled.div`
  display: flex;
  margin-left: 20px;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

const Label = styled.div`
  width: 50%;

  color: ${props =>
    props.disabled ? props.theme.colors.primary.base : 'inherit'};

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    padding-bottom: 5px;

    &:last-child {
      padding-bottom: 0;
    }
    &:first-child {
      padding-bottom: 8px;
    }
  }
`;

const LabelsWrapper = styled.div`
  padding: 29px 0 18px 0;

  ${Label} {
    padding-bottom: 0;
  }
`;

const NotAviable = styled.div`
  height: 28px;
  margin: 0 0 0 61px;
  padding: 6px 10px;
  border-radius: 14px;
  background-color: ${props => props.theme.colors.cultured3};

  @media ${props => props.theme.device.mobile} {
    margin: 0;
    max-width: fit-content;
  }
`;

const LabelNotAviable = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-size: 12px;
  font-weight: 600;
`;

export {
  SubscriptionsContainer,
  ListItems,
  Label,
  Labels,
  ListItem,
  LabelsWrapper,
  NotAviable,
  LabelNotAviable
};
