import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 372px;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Text = styled.div`
  padding-bottom: 16px;
  text-align: center;

  color: ${props => props.theme.colors.greenDark};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 27px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-right: 0;
    padding-bottom: 23px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 250px;
`;

export const BtnContainer = styled.div`
  width: 177px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  font-size: 30px;
  right: 0;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;
