import { takeLatest, put, call, select } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';

import ModalErrors from 'ui/components/Modals/ModalErrors';
import fiveMinutes from 'api/fiveMinutes';
import { GET_FIVE_MINUTES, GET_NEWS } from 'redux/actions/fiveMinutes';

function* getFiveMinutes({ forceReload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { fiveMinutes: { success } = {} } = yield select(
      state => state.fiveMinutes
    );
    if (forceReload || !success) {
      const { data } = yield call(fiveMinutes.fiveMinutes, usrId);
      yield put({
        type: GET_FIVE_MINUTES._SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > fiveMinutes > getFiveMinutes', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFiveMinutesWatch() {
  yield takeLatest(GET_FIVE_MINUTES._REQUEST, getFiveMinutes);
}

function* getNews({ id: itemId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { data } = yield call(fiveMinutes.getNews, { userId, itemId });
    yield put({
      type: GET_NEWS._SUCCESS,
      data,
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > fiveMinutes > getNews', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsWatch() {
  yield takeLatest(GET_NEWS._REQUEST, getNews);
}

export default [getFiveMinutesWatch(), getNewsWatch()];
