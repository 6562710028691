import styled, { css } from 'styled-components/macro';

// eslint-disable-next-line import/no-cycle
import CommonCard from './index';

export const Content = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media ${props => props.theme.device.tablet} {
    padding: 16px 16px;
  }
`;

export const funzioneCalcoloStile = props => {
  let style;
  switch (props.type) {
    case CommonCard.TYPE.SMALL:
      style = css`
        height: auto;
        margin-bottom: 0;

        ${Content} {
          padding: 22px 35px;
          @media ${props.theme.device.mobile} {
            padding: 15px 15px 0 15px;
          }
        }
      `;
      break;
    case CommonCard.TYPE.MEDIUM:
      style = css`
        height: 245px;

        @media ${props.theme.device.mobile} {
          height: auto;
        }
        ${Content} {
          padding: 22px 35px;
          @media ${props.theme.device.mobile} {
            padding: 15px 15px 0 15px;
          }
        }
      `;
      break;
    case CommonCard.TYPE.LARGE:
      style = css`
        min-height: 416px;
        height: auto;
        @media ${props.theme.device.tablet} {
          min-height: 397px;
        }
        ${Content} {
          padding: 22px 35px;
          @media ${props.theme.device.mobile} {
            padding: 15px 15px 0 15px;
          }
        }
      `;
      break;
    default:
      break;
  }

  return style;
};

export const CommonCardContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 10px 40px 0 rgba(24, 42, 50, 0.03);
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 18px;
  position: relative;

  ${props => funzioneCalcoloStile(props)};

  &.border {
    border: 1px solid grey;
    box-shadow: none;
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.azureDark};
`;

export const Header = styled.div`
  display: flex;
  border-bottom: solid 1px ${props => props.theme.colors.ghostWhite};
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  justify-content: space-between;

  @media ${props => props.theme.device.mobile} {
    padding: 17px 15px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 16px;
  }

  & span {
    cursor: pointer;
  }
`;

export const Icon = styled.span`
  font-size: x-large;
  color: ${props => props.theme.colors.primary.base};
  opacity: 0.65;

  &.align-left {
    margin-right: auto;
    margin-left: 20px;
  }
`;

export const Text = styled.h5`
  color: ${props => props.theme.colors.primary.base};
`;
