import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import { Error } from 'ui/atoms/CustomInput/style';

import ProDisplay from 'ui/typography/proDisplay';
import { InputAutoSuggestContainer, Placeholder } from './style';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (list, value) => {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(`^${escapedValue}`, 'i');

  return list.filter(item => regex.test(item.label));
};

const renderSuggestion = suggestion => <span>{suggestion.label}</span>;

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion.
const getSuggestionValue = suggestion => suggestion.label;

const InputAutoSuggestion = ({
  suggestions,
  placeholder,
  onChange,
  onBlur,
  dataValue = '',
  error,
  limit = 3
}) => {
  // Autosuggest is a controlled component.
  // This means that you need to provide an input value
  // and an onChange handler that updates this value (see below).
  // Suggestions also need to be provided to the Autosuggest,
  // and they are initially empty because the Autosuggest is closed.
  const [suggestionList, setSuggestionList] = useState([[]]);
  const [inputValue, setInputValue] = useState(dataValue);
  const [focus, setFocus] = useState(false);

  const handleOnChange = (event, { newValue, method }) => {
    setInputValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleOnBlur = (event, { newValue, method }) => {
    setFocus(prevState => !prevState);
    if (onBlur) onBlur();
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestionList(getSuggestions(suggestions, value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestionList([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    value: inputValue,
    onChange: handleOnChange,
    onFocus: () => setFocus(prevState => !prevState),
    onBlur: handleOnBlur
  };

  //When the input is focused, Autosuggest will consult this function when to render suggestions.
  const shouldRenderSuggestions = value =>
    value.toString().trim().length > limit;

  const getDefaultError = () => 'Errore: il campo non è valido';

  return (
    <>
      <InputAutoSuggestContainer focus={focus} value={inputValue} error={error}>
        <Autosuggest
          suggestions={suggestionList}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          inputProps={inputProps}
        />
        <Placeholder>
          <Placeholder>
            <ProDisplay
              configuration={{ fontSize: focus || inputValue ? 12 : null }}
            >
              {placeholder}
            </ProDisplay>
          </Placeholder>
        </Placeholder>
      </InputAutoSuggestContainer>
      {error ? (
        <Error>
          <ProDisplay type="2" configuration={{ fontSize: 12 }}>
            {typeof error === 'string' ? error : getDefaultError()}
          </ProDisplay>
        </Error>
      ) : null}
    </>
  );
};

InputAutoSuggestion.propTypes = {
  suggestions: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  dataValue: PropTypes.any,
  error: PropTypes.bool,
  limit: PropTypes.number
};

export default InputAutoSuggestion;
