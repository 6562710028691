import styled, { css } from 'styled-components/macro';

export const Placeholder = styled.span`
  color: ${props => props.theme.colors.placeholder};
  position: absolute;
  left: 10px;
  width: 100%;
  transition: 125ms ease-in;
  pointer-events: none;
    
  > div {
    display: inline-block;
    width: auto;
    transition: font-size 250ms ease-in;
  };
`;

export const InputAutoSuggestContainer = styled.div`

  position: relative;
  width: 100%;
  
  .react-autosuggest__container {
    height: 55px;
    position: relative;
  }
  
  .react-autosuggest__input {
    border-radius: 4px;
    border: solid 1px ${props => props.theme.colors.lightSilver};
    background-color: ${props => props.theme.colors.white}fff;
    height: 100%;
    width: 100%;
    padding: 12px 20px 0;
    -webkit-transition: all 125ms ease-in;
    transition: all 125ms ease-in;
  }
  
  .react-autosuggest__input--focused {
    outline: none;      
    appearance: none;
    border-color: ${props => props.theme.colors.azureDark};
    outline: 0;
    box-shadow: 0 0 0 0.17647rem ${props => props.theme.mixin.rgba(props.theme.colors.azureDark, 0.2)};
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 55px;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.elevenGray};
    background-color: ${props => props.theme.colors.white};
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: ${props => props.theme.colors.gainGray};
  }
  
  ${Placeholder} {
    ${props => (!(props.focus || props.value) ? css`
      top: 50%;
      transform: translate(0,-50%);
    ` : css`
      top: 2px;
    `)};
  };
  
  ${props => props.error && css`
    .react-autosuggest__input {
      border-color: ${!props.focus && props.theme.colors.redError};
      color: ${!props.focus && props.theme.colors.redError};
    };
  `};
`;
