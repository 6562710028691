import produce from 'immer';
import {
  TEAMS_PROGRESS_GET,
  SET_TEAMS_PROGRESS_FILTERS,
  VIEWS_GET,
  POSITIVE_GET,
  GET_NOTIFICATIONS
} from 'redux/actions/teams';

const initialState = {
  teams_progress_list: [],
  filters: {
    search: ''
  },
  views: [],
  positive: [],
  notifications: []
};

const teamsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_TEAMS_PROGRESS_FILTERS: {
        draft = {
          ...initialState,
          filters: action.filters || initialState.filters
        };

        break;
      }
      case TEAMS_PROGRESS_GET._SUCCESS:
        {
          const { list } = action;
          draft.teams_progress_list = list;
        }
        break;
      case VIEWS_GET._SUCCESS: {
        draft.views = action.response;
        break;
      }
      case POSITIVE_GET._SUCCESS: {
        draft.positive = action.response;
        break;
      }
      case GET_NOTIFICATIONS._SUCCESS: {
        draft.notifications = action.notifications;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default teamsReducer;
