import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Papa from 'papaparse';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';
import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { MODAL_CLOSE, MODAL_OPEN } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Button, DropZone } from 'ui/atoms';
import { csvInput } from 'utils/supported-input-files';
import validator from 'utils/regex-validator';
import SuccessModal from '../SuccessModal';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Head,
  ButtonsWrapper,
  BtnContainer,
  FileContainer,
  IconWrapper,
  Name,
  Column,
  Size,
  InputFile,
  Download,
  HeaderModal,
  IconDelete,
  IconInfo
} from './style';

const modalId = 'LoadListModal';

const LoadListModal = ({
  onClose,
  load_list,
  isOpen,
  openSuccessModal,
  onLoadList,
  emptySubcriptionsLength
}) => {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [validFormat, setValidFormat] = useState(true);
  const [parsedFiles, setParsedFiles] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    !isOpen && setDroppedFiles([]);
    setValidFormat(true);
  }, [isOpen]);

  useEffect(() => {
    let newList = [];

    if (Array.isArray(parsedFiles)) {
      newList = parsedFiles.filter(email => validator.email(email));
      //console.log(newList);
      if (newList.length > 0 && newList.length <= emptySubcriptionsLength) {
        setError();
        onLoadList(newList);
        const payload = {
          title: load_list?.success?.title,
          description: load_list?.success?.subtitle
        };
        onClose();
        openSuccessModal(payload);
      } else if (newList.length === 0) {
        setError('Nessuna delle email inserite è valida.');
      } else if (newList.length > emptySubcriptionsLength) {
        setError('Hai inserito troppe email.');
      }
    }
    setDroppedFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiles]);

  const parseMe = (file, callBack) => {
    Papa.parse(file, {
      complete(results) {
        //if (results.errors) console.log('Parsed issues', results.errors);
        const returnElms = results.data
          .map(elm => {
            const [firstCol] = elm;
            return firstCol;
          })
          .filter(e => e);
        callBack(returnElms);
      }
    });
  };

  const handleOnClick = () => {
    parseMe(droppedFiles[0], setParsedFiles);
  };

  const uploadTrigger = () => {
    document.getElementById('inputFile').click();
  };

  const checkSupportedFiles = files => {
    const isSupported = csvInput.findIndex(t => t === files[0].type);
    if (isSupported === -1) {
      setValidFormat(false);
    } else {
      setDroppedFiles(files);
      setValidFormat(true);
    }
  };

  const handleFileDrop = monitor => {
    if (monitor) {
      const { files } = monitor.getItem();
      checkSupportedFiles(files);
    }
  };

  const handleFileUpload = () => {
    const { files } = document.getElementById('inputFile');
    checkSupportedFiles(files);
  };

  const formatSize = size => {
    if (size < 1024) return `${size}B`;
    if (size < 1048576) return `${(size / 1024).toFixed(1)}kB`;
    return `${(size / 1048576).toFixed(1)}MB`;
  };

  const deleteFiles = () => {
    setDroppedFiles([]);
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <HeaderModal>
                <Title>
                  <ProDisplay
                    type="modalTitle"
                    configuration={{ color: 'primary', colorType: 'base' }}
                  >
                    {load_list.title}
                  </ProDisplay>
                </Title>
                <Icon className="icon-close-x" onClick={onClose} />
              </HeaderModal>
              {droppedFiles.length === 0 ? (
                <DndProvider backend={HTML5Backend}>
                  <DropZone
                    onDrop={handleFileDrop}
                    onClick={uploadTrigger}
                    validFormat={validFormat}
                    error={error}
                  />
                  <InputFile
                    type="file"
                    id="inputFile"
                    onChange={handleFileUpload}
                    accept=".csv"
                  />
                </DndProvider>
              ) : (
                <FileContainer>
                  <Column>
                    <Name>{droppedFiles[0]?.name}</Name>
                    <Size>{formatSize(droppedFiles[0]?.size)}</Size>
                  </Column>
                  <Column isFlex>
                    <IconWrapper onClick={deleteFiles}>
                      <IconDelete className="icon-icona-elimina" />
                    </IconWrapper>
                  </Column>
                </FileContainer>
              )}
              <Subtitle>
                <IconWrapper className="no-pointer pr-1">
                  <IconInfo className="icon-icona-info-active" />
                </IconWrapper>
                <ProDisplay type="label" configuration={{ color: 'mineShaft' }}>
                  <Head>{load_list.description}</Head>
                  <Download
                    href="https://s3-eu-west-1.amazonaws.com/lo.s4b2.sit/files/esempio.csv"
                    download="esempio.csv"
                    target="_blank"
                  >
                    <ProDisplay
                      type="label"
                      configuration={{
                        color: 'primary',
                        colorType: 'base',
                        textDecoration: 'underline'
                      }}
                    >
                      Scarica il file
                    </ProDisplay>
                  </Download>
                  <Head>{load_list.description_two}</Head>
                </ProDisplay>
              </Subtitle>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button onClick={onClose} type={Button.TYPE.SECONDARY}>
                    {load_list?.cancel}
                  </Button>
                </BtnContainer>
                <BtnContainer>
                  <Button
                    text="Carica"
                    onClick={handleOnClick}
                    type={Button.TYPE.PRIMARY}
                    disabled={droppedFiles.length === 0}
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

LoadListModal.propTypes = {
  // HOC (connect, state)
  title: PropTypes.string,
  description: PropTypes.string,
  description_two: PropTypes.string,
  isOpen: PropTypes.bool,
  load_list: PropTypes.object,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  openSuccessModal: PropTypes.func.isRequired
};

LoadListModal.defaultProps = {
  isOpen: false
};

LoadListModal.id = modalId;

export default connect(
  state => {
    const {
      payload: { title, description, description_two } = {}
    } = state.modal;
    const { load_list } = state.configuration;
    const isOpen = state.modal.open;
    return {
      title,
      description,
      description_two,
      load_list,
      isOpen
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    openSuccessModal: payload =>
      dispatch({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        payload
      })
  })
)(LoadListModal);
