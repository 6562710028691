import {
  getActivityMock,
  getStatementsMock,
  postStatementsMock,
  putActivityMock,
  shouldUseMock
} from './mock';
import { apiCall, baseUrl, extendUrl, trackingPrefix } from './config';

export default {
  getStatements: (email, type, itemId) => {
    if (shouldUseMock()) {
      return getStatementsMock();
    }
    const encodedAgent = window.encodeURI(`{"mbox":"mailto:${email}"}`);
    return apiCall(
      `${trackingPrefix}${baseUrl}${extendUrl}statements?agent=${encodedAgent}&verb=http://adlnet.gov/expapi/verbs/${type}&activity=https://intesasanpaoloformazione.com/s4b${itemId}`,
      'GET'
    );
  },
  postStatements: body => {
    if (shouldUseMock()) {
      return postStatementsMock();
    }
    return apiCall(
      `${trackingPrefix}${baseUrl}${extendUrl}statements`,
      'POST',
      body
    );
  },
  getActivity: (email, itemId) => {
    if (shouldUseMock()) {
      return getActivityMock();
    }
    const encodedAgent = window.encodeURI(`{"mbox":"mailto:${email}"}`);
    return apiCall(
      `${trackingPrefix}${baseUrl}${extendUrl}activities/state?activityId=https://intesasanpaoloformazione.com/s4b${itemId}&agent=${encodedAgent}&stateId=currentTime`,
      'GET'
    );
  },
  putActivity: (email, itemId, body) => {
    if (shouldUseMock()) {
      return putActivityMock();
    }
    const encodedAgent = window.encodeURI(`{"mbox":"mailto:${email}"}`);
    return apiCall(
      `${trackingPrefix}${baseUrl}${extendUrl}activities/state?activityId=https://intesasanpaoloformazione.com/s4b${itemId}&agent=${encodedAgent}&stateId=currentTime`,
      'PUT',
      body
    );
  }
};
