export const lastPaymentsOptions = [
  { label: 'Tutti', value: 'all' },
  { label: 'Ultimi 3 mesi', value: '0' },
  { label: 'Ultimi 6 mesi', value: '1' }
];

export const categoriesOptions = [
  { label: 'Tutte', value: 'all' },
  { label: 'Agricolo', value: 1 },
  { label: 'Turismo', value: 2 }
];
