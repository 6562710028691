import React from 'react';
import { Icon } from './style';

const Word = () => (
  <Icon className="icon-word">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
  </Icon>
);

export default Word;
