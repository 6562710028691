import styled from 'styled-components/macro';
import { NavBarHeight } from 'const';

export const EditDataContainer = styled.div`
  background-color: ${props => props.theme.colors.transparent};
  min-height: calc(100vh - 97px);

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - 86px);
  }
  @media ${props => props.theme.device.mobile} {
    min-height: calc(100vh - 140px);
  }
`;

export const Title = styled.div`
  margin-top: 60px;

  @media ${props => props.theme.device.mobile} {
    margin-top: 32px;
  }
`;

export const SubTitle = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  margin-bottom: 20px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const HeadWrapper = styled.div`
  width: 100%;
  height: ${NavBarHeight}px;
  background-color: ${props => props.theme.colors.primary.base};
  display: flex;
  align-items: center;
`;

export const Head = styled.div`
  width: 100%;
  height: ${NavBarHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: ${props => !props.img && 'lightgray'};
  height: 35px;
  cursor: pointer;
  width: auto;
  min-width: 200px;
  max-width: 260px;
`;

export const FooterContainer = styled.div`
  border-top: solid 1px ${props => props.theme.colors.filterColor};
  margin-top: 40px;
  height: 93px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;

  @media ${props => props.theme.device.tablet} {
    height: 86px;
  }
  @media ${props => props.theme.device.mobile} {
    height: 140px;
  }
`;

export const Foot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;

  @media ${props => props.theme.device.tablet} {
    min-width: 228px;
  }

  @media ${props => props.theme.device.mobile} {
    min-width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }
`;
