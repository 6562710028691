import {
  addWorkoutMock,
  shouldUseMock,
} from './mock';
import { apiCall, baseUrl, extendUrl, socialPrefix } from './config';

export default {
  addNote: payload => {
    if (shouldUseMock()) {
      return addWorkoutMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}SOME_BEAUTIFUL_ENDPOINT`,
      'POST',
      payload
    );
  },
};
