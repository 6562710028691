import React from 'react';

import { Col, Container, Row } from 'ui/gridSystem';

const PageNotFound = () => {
  return (
    <Container>
      <Row className="mb-5">
        <Col sm={12}>
          <span>Page Not Found</span>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
