import { takeLatest, put, select } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';

import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  PREMIUM_PURCHASE_SUBMIT, SET_EMAILS_PREMIUM_SUBSCIRPTION
} from 'redux/actions/subscriptions';
import {
  SIGNUP_ORDER_PREVIEW_ORDER_ZUORA
} from 'redux/actions/signup';
import { createCartPayloadFromLocalCartPremium } from '../../utils/common';

function* premiumPurchaseSubmit({ subscriptions = [] }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { selected_subscription_plan } = yield select(state => state.subscriptions);
    if(selected_subscription_plan && subscriptions.length) {
      const payload = createCartPayloadFromLocalCartPremium({...selected_subscription_plan, quantity: subscriptions.length});
      yield put({ type: SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._REQUEST, payload: { items: payload, isPremium: true } });
      yield put({ type: SET_EMAILS_PREMIUM_SUBSCIRPTION, premium_subscription_emails: subscriptions });
      yield put({ type: PREMIUM_PURCHASE_SUBMIT._SUCCESS });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > premiumPurchase > premiumPurchaseSubmit', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* premiumPurchaseSubmitWatch() {
  yield takeLatest(PREMIUM_PURCHASE_SUBMIT._REQUEST, premiumPurchaseSubmit);
}

export default [premiumPurchaseSubmitWatch()];
