import { createAsyncActionType } from './index';

export const AUTHENTICATION_SIGNIN = createAsyncActionType(
  'authentication',
  'AUTHENTICATION_SIGNIN'
);
export const AUTHENTICATION_SSO_SIGNIN = createAsyncActionType(
  'authentication',
  'AUTHENTICATION_SSO_SIGNIN'
);

export const AUTHENTICATION_SIGNOUT = createAsyncActionType(
  'authentication',
  'AUTHENTICATION_SIGNOUT'
);

export const GET_DOMAINS = createAsyncActionType(
  'authentication',
  'GET_DOMAINS'
);

export const REGISTER_PROSPECT = createAsyncActionType(
  'authentication',
  'REGISTER_PROSPECT'
);

export const RESET_DATA_TREATMENT = '@@authentication/RESET_DATA_TREATMENT';
