import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  TableCardMobileContainer,
  Field,
  Label
} from 'ui/components/TableCardMobile/style';
import { CustomInput, CustomSelect } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { styles } from 'utils/multi-select';
import validator from 'utils/regex-validator';
import { SelectWrapper } from '../TableCard/style';
import { mappingVariations } from '../NewSubscriptions';

const TableCardMobileEmpty = ({
  subscription,
  handleOnChangeEmail,
  emailName,
  options,
  handleOnChangeVariation
}) => {
  const [value, setValue] = useState(emailName);

  const { id, email, variations, error } = subscription;

  const handleOnChange = newValue => {
    setValue(newValue);
  };

  useEffect(() => {
    if (emailName) {
      setValue(emailName);
    }
  }, [emailName]);

  const handleOnBlur = () => {
    handleOnChangeEmail(id, value);
  };

  return (
    <>
      <TableCardMobileContainer>
        <Field>
          <Label>
            <ProDisplay type="tableLabel">Utenti</ProDisplay>
          </Label>
          <CustomInput
            type="text"
            value={email}
            placeholder="Email utente"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={error}
          />
        </Field>
        <Field>
          <Label>
            <ProDisplay type="tableLabel">Abbonamento</ProDisplay>
          </Label>
          <SelectWrapper>
            <CustomSelect
              placeholder="Seleziona"
              options={options}
              onBlur={newVariations =>
                handleOnChangeVariation(id, variations, newVariations)
              }
              disabled={!email || (email && !validator.email(email))}
              isMulti
              closeOnSelect={false}
              selected={variations.map(mappingVariations)}
              styles={styles}
            />
          </SelectWrapper>
        </Field>
      </TableCardMobileContainer>
    </>
  );
};

TableCardMobileEmpty.propTypes = {
  subscription: PropTypes.object.isRequired,
  handleOnChangeEmail: PropTypes.func.isRequired,
  emailName: PropTypes.string,
  options: PropTypes.array,
  handleOnChangeVariation: PropTypes.func
};

export default withMediaQueries(TableCardMobileEmpty);
