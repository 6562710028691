import React from 'react';
import PropTypes from 'prop-types';
import ProText from 'ui/typography/proText';

import {
  InnerCircle,
  OuterCircle,
  FirstSector,
  SecondSector,
  Container
} from './style';

const ProgressBarCircular = ({
  bgColorLighter,
  percentage,
  deg,
  color1,
  color2
}) => (
  <Container>
    <OuterCircle
      percentage={percentage}
      deg={deg}
      color1={color1}
      color2={color2}
    >
      {percentage <= 50 && <FirstSector percentage={percentage} />}
      <SecondSector percentage={percentage} />
      <InnerCircle bgColorLighter={bgColorLighter}>
        <ProText
          type="detailAction"
          configuration={{ fontSize: '15', color: 'white' }}
        >
          {percentage}%
        </ProText>
      </InnerCircle>
    </OuterCircle>
  </Container>
);

ProgressBarCircular.propTypes = {
  bgColorLighter: PropTypes.bool,
  percentage: PropTypes.number,
  deg: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string
};

ProgressBarCircular.defaultProps = {
  percentage: 0,
  deg: '120deg',
  color1: '#ff9500',
  color2: '#fbd66a'
};

export default ProgressBarCircular;
