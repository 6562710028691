import styled from 'styled-components/macro';

const Container = styled.div.attrs(() => ({
  className: 'menu-button-container'
}))`
  margin-left: auto;
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: initial;
  }
`;

const Checkbox = styled.input.attrs(() => ({
  id: 'menu-toggle',
  className: 'menu-checkbox',
  type: 'checkbox'
}))`
  display: none;

  &:checked + .menu-button .menu-icon {
    background: ${props => props.theme.colors.transparent};
  }

  &:checked + .menu-button .menu-icon::before {
    top: 0;
    left: 0;
    transform: rotate(135deg);
  }

  &:checked + .menu-button .menu-icon::after {
    top: 0;
    left: 0;
    width: 22px;
    transform: rotate(-135deg);
  }
`;

const Label = styled.label.attrs(() => ({
  className: 'menu-button',
  htmlFor: 'menu-toggle'
}))`
  margin-bottom: 0;
  vertical-align: super;
  .menu-icon {
    position: relative;
    vertical-align: middle;
    &,
    &::before,
    &::after {
      height: 2px;
      background-color: ${props => props.theme.colors.primary.darker};
      display: inline-block;
      border-radius: 2px;
    }

    & {
      width: 16px;
    }

    &::before {
      width: 22px;
    }

    &::after {
      width: 9px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -8px;
      left: -6px;
    }

    &::after {
      top: 8px;
      left: 7px;
    }
  }
`;
const IconWrapper = styled.div`
  cursor: pointer;
`;

const Icon = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.white};

  &.small {
    font-size: 20px;
  }
`;

const MenuIcon = styled.span.attrs(() => ({
  className: 'menu-icon'
}))``;
export { Container, Checkbox, Label, MenuIcon, IconWrapper, Icon };
