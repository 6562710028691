import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

export const ContentsContainer = styled.div`
  overflow-y: auto;
  ${customScrollbar()};
  max-height: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const Text = styled.div`
  height: 100%;
  overflow-y: auto;
  margin-top: 10px;
  width: 100%;
  ${customScrollbar()}
`;

export const Link = styled.span`
  cursor: pointer;
  color: blue;
  span {
    text-decoration: underline;
    font-size: 12px;
  }
`;

export const Smaller = styled.span`
  font-size: 12px;
`;

export const CheckboxWrapper = styled.div`
  padding: 10px 0 15px 0;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const Radio = styled.div`
  padding: 10px 0 15px 0;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const RadioDescription = styled.div.attrs(props => ({
  className: `${props.top ? 'top' : ''}`
}))`
  line-height: 1.14;
  font-size: 14px;
  color: darkslategrey;
  padding-top: 8px;
  padding-bottom: 8px;

  &.top {
    padding-bottom: 8px;
    padding-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 180px;
  margin: auto;
  margin-top: 10px;
`;

export const CheckboxDescription = styled.div`
  padding-bottom: 8px;
`;
