import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';

import {
  TEAMS_PROGRESS_GET,
  VIEWS_GET,
  POSITIVE_GET
} from 'redux/actions/teams';
import {
  CollaboratorsFiltersInterface,
  ViewsInterface,
  PositivesInterface
} from 'interface/teams';

import { Container, Row, Col } from 'ui/gridSystem';
import {
  UserCard,
  CommonCard,
  CustomCarousel,
  Spinner,
  Footer
} from 'ui/components';

import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';

import routes from 'routes';

import { SearchBar } from 'ui/atoms';
import { MODAL_OPEN } from 'redux/actions';
import SelectedCollectionModal from 'ui/components/Modals/SelectedCollectionModal';
import ProText from 'ui/typography/proText';
import MainContainer from '../style';
import { Settings } from './settings';
import {
  TeamsActivityContainer,
  SearchWrapper,
  SearchContainer,
  Icon,
  Link,
  CardWrapper,
  UserCardContainer,
  IntestationWrapper,
  Text,
  ContentWrapper,
  Content,
  ListContainer,
  ListNumber,
  ResultContainer,
  Number,
  TitleSection,
  SearchBarWrapper
} from './style';

const TeamsActivity = ({
  mediaIsPhone,
  list,
  views,
  positive,
  filters,
  getCollaboratorsList,
  pushUrl,
  getPositive,
  getViews,
  openSelectedCollectionModal,
  myUserId
}) => {
  const [visibleSearch, openSearch] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const [search, setSearch] = useState(filters.search);

  useEffect(() => {
    getPositive();
    getViews();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCollaboratorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLocation = url => {
    pushUrl(url);
  };

  const handleOnchageSearch = value => {
    setSearch(value.toLowerCase());
  };

  const openModal = collection => {
    setSelectedList(collection);
    openSelectedCollectionModal();
  };

  return (
    <>
      <MainContainer>
        <Container>
          <TeamsActivityContainer>
            <TitleSection>
              <ProDisplay
                type="menutitle"
                configuration={mediaIsPhone ? { fontSize: '24' } : null}
              >
                L'attività dei miei collaboratori
              </ProDisplay>
            </TitleSection>
            <SearchWrapper>
              <SearchContainer>
                <ProDisplay type="label">
                  {list ? list.length : 0} Utenti
                </ProDisplay>
                <Icon
                  className="icon-ricerca"
                  onClick={() => openSearch(!visibleSearch)}
                />
                {!visibleSearch ? (
                  <SearchBarWrapper>
                    <SearchBar
                      placeholder="Cerca"
                      onChange={value => handleOnchageSearch(value)}
                      value={search}
                      hidden
                    />
                  </SearchBarWrapper>
                ) : (
                  ''
                )}
              </SearchContainer>
              {list?.length > 3 && (
                <Link onClick={() => changeLocation(routes.allActivity.path)}>
                  <ProDisplay type="link">Vedi tutti</ProDisplay>
                </Link>
              )}
            </SearchWrapper>
            <CardWrapper>
              <CustomCarousel config={Settings} isTeam>
                {list &&
                  ([...list].slice(0, 5) || [])
                    .filter(
                      collaborator =>
                        collaborator?.firstname
                          ?.toLowerCase()
                          .includes(search) ||
                        collaborator?.lastname?.toLowerCase().includes(search)
                    )
                    .map((collaborator, index) => (
                      <UserCardContainer key={`collaborator-${index}`}>
                        <UserCard
                          user={collaborator}
                          openSelectedCollectionModal={openModal}
                          onClick={() => {
                            if (collaborator.userName !== myUserId)
                              pushUrl(
                                `${routes.followProfileManager.path}/${collaborator.userName}`
                              );
                            else pushUrl(`${routes.userProfile.path}`);
                          }}
                        />
                      </UserCardContainer>
                    ))}
              </CustomCarousel>
            </CardWrapper>
            <TitleSection>
              <ProDisplay
                type="menutitle"
                configuration={mediaIsPhone ? { fontSize: '24' } : null}
              >
                Overview dei contenuti
              </ProDisplay>
            </TitleSection>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <CommonCard
                  hasHeader
                  title="I più visti"
                  text="Vedi tutti"
                  onClick={() =>
                    changeLocation(`${routes.allContent.path}/mostviewed`)
                  }
                >
                  <IntestationWrapper>
                    <Text>
                      <ProDisplay configuration={{ fontSize: '14' }}>
                        Contenuti
                      </ProDisplay>
                    </Text>
                    <Text style={{ textTransform: 'uppercase' }}>
                      <ProDisplay configuration={{ fontSize: '14' }}>
                        Visualizzazioni
                      </ProDisplay>
                    </Text>
                  </IntestationWrapper>
                  <ContentWrapper>
                    {views ? (
                      ([...views].slice(0, 5) || []).map((content, index) => (
                        <Content key={`mostviewed-${index}`}>
                          <ListContainer>
                            <ListNumber>
                              <ProText type="list">{index + 1}</ProText>
                            </ListNumber>
                            <ProDisplay>{content.title}</ProDisplay>
                          </ListContainer>
                          <ResultContainer>
                            <Icon className="icon-visualizzazioni" />
                            <Number>
                              <ProDisplay type="label">
                                {content.views}
                              </ProDisplay>
                            </Number>
                          </ResultContainer>
                        </Content>
                      ))
                    ) : (
                      <Spinner />
                    )}
                  </ContentWrapper>
                </CommonCard>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <CommonCard
                  hasHeader
                  title="Contenuti più apprezzati"
                  text="Vedi tutti"
                  onClick={() =>
                    changeLocation(`${routes.allContent.path}/mostpopular`)
                  }
                >
                  <IntestationWrapper>
                    <Text>
                      <ProDisplay configuration={{ fontSize: '14' }}>
                        Contenuti
                      </ProDisplay>
                    </Text>
                    <Text style={{ textTransform: 'uppercase' }}>
                      <ProDisplay configuration={{ fontSize: '14' }}>
                        Like
                      </ProDisplay>
                    </Text>
                  </IntestationWrapper>
                  <ContentWrapper>
                    {positive ? (
                      ([...positive].slice(0, 5) || []).map(
                        (content, index) => (
                          <Content key={`mostpopular-${index}`}>
                            <ListContainer>
                              <ListNumber>
                                <ProText type="list">{index + 1}</ProText>
                              </ListNumber>
                              <ProDisplay>{content.title}</ProDisplay>
                            </ListContainer>
                            <ResultContainer>
                              <Icon className="icon-star" />
                              <Number>
                                <ProDisplay type="label">
                                  {content.positive}
                                </ProDisplay>
                              </Number>
                            </ResultContainer>
                          </Content>
                        )
                      )
                    ) : (
                      <Spinner />
                    )}
                  </ContentWrapper>
                </CommonCard>
              </Col>
            </Row>
            <SelectedCollectionModal list={selectedList} />
          </TeamsActivityContainer>
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

TeamsActivity.propTypes = {
  mediaIsPhone: PropTypes.bool,

  //HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  list: PropTypes.array,
  filters: CollaboratorsFiltersInterface,
  views: ViewsInterface,
  positive: PositivesInterface,
  getCollaboratorsList: PropTypes.func.isRequired,
  getViews: PropTypes.func.isRequired,
  getPositive: PropTypes.func.isRequired,
  openSelectedCollectionModal: PropTypes.func.isRequired,
  myUserId: PropTypes.string
};

export default connect(
  state => {
    const { teams_progress_list: list, filters, views, positive } = state.teams;
    const {
      user: { userId: myUserId }
    } = state.authentication;
    return {
      list,
      filters,
      views,
      positive,
      myUserId
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getCollaboratorsList: () =>
      dispatch({
        type: TEAMS_PROGRESS_GET._REQUEST
      }),
    getViews: () =>
      dispatch({
        type: VIEWS_GET._REQUEST
      }),
    getPositive: () =>
      dispatch({
        type: POSITIVE_GET._REQUEST
      }),
    openSelectedCollectionModal: () =>
      dispatch({ type: MODAL_OPEN, id: 'SelectedCollectionModal' })
  })
)(withMediaQueries(TeamsActivity));
