import React from 'react';
import PropTypes from 'prop-types';
import ProText from 'ui/typography/proText';
import { checkField, validations } from 'utils/password';
import {
  SuggestionsWrapper,
  Suggestion,
  TitleSuggestion,
  SuggestionItem,
  IconCheck
} from './style';

const PasswordSuggestion = ({ password, padding }) => (
  <SuggestionsWrapper padding={padding}>
    <Suggestion>
      <ProText type="dosts">
        Evita password semplici da indovinare o usate su altri siti web.
      </ProText>
    </Suggestion>

    <TitleSuggestion>
      <ProText type="titleSuggestion">
        Per la tua sicurezza la tua password deve:
      </ProText>
    </TitleSuggestion>
    {validations.map((err, i) => (
      <Suggestion key={i} compiled={password.value}>
        <IconCheck
          compiled={password.value}
          valid={checkField(err.err, password.value)}
          className="icon-icona-check-rounded"
        />
        <SuggestionItem>
          <ProText type="titleSuggestion">{err.msg}</ProText>
        </SuggestionItem>
      </Suggestion>
    ))}
  </SuggestionsWrapper>
);

PasswordSuggestion.propTypes = {
  password: PropTypes.object,
  padding: PropTypes.number
};

export default PasswordSuggestion;
