import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import {
  Title,
  ContentWrapper,
  Icon,
  ContentList,
  ItemList,
  ContentListWrapper
} from './style';
import { ModalWrapper } from '../InviteFriendModal/style';


const modalId = 'PlaylistPremiumModal';

const PlaylistPremiumModal = ({ onClose, field_addons }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Title>
              <ProDisplay type="modalTitle">Playlist Premium</ProDisplay>
            </Title>
            <ContentListWrapper>
              <ContentList>
                {(field_addons || []).map((item = {}) => (
                  <ItemList key={item.id}>{item?.title}</ItemList>
                ))}
              </ContentList>
            </ContentListWrapper>
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

PlaylistPremiumModal.propTypes = {
  // HOC (connect, state)
  field_addons: PropTypes.array,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

PlaylistPremiumModal.id = modalId;

export default connect(
  state => {
    const { payload: { field_addons = [] } = {} } = state.modal;
    return {
      field_addons
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(PlaylistPremiumModal);
