const durationFormat = time => {
  const min = (time / 60).toFixed(0);
  const sec = (time % 60).toFixed(0);
  return `${min > 0 ? `${min} min` : ''} ${sec > 0 ? `${sec}sec` : ''}`;
};

export const secsToTime = secs => {
  const totalMins = Math.round(secs / 60);
  const hours = Math.floor(totalMins / 60);
  const mins = totalMins % 60;
  return (hours > 0)
    ? `${hours}h ${mins}min`
    : `${mins}min`
}

export const clockToTime = clockString => {
  const timeSplits = clockString.split(':')
  const numberSplits = timeSplits.map(t => parseInt(t))
  return secsToTime(
    numberSplits[2] * 60 * 60
    + numberSplits[1] * 60
    + numberSplits[0]
  )
}

export default durationFormat;
