import { createAsyncActionType } from './index';

export const GET_BOOKMARKS = createAsyncActionType('social', 'GET_BOOKMARKS');

export const ADD_BOOKMARK = createAsyncActionType('social', 'ADD_BOOKMARKS');

export const REMOVE_BOOKMARK = createAsyncActionType(
  'social',
  'REMOVE_BOOKMARKS'
);

export const REMOVE_BOOKMARK_BY_ID = createAsyncActionType(
  'social',
  'REMOVE_BOOKMARK_BY_ID'
);

export const HANDLE_FEEDBACK = createAsyncActionType(
  'social',
  'HANDLE_FEEDBACK'
);

export const GET_FOLLOW_COUNT = createAsyncActionType(
  'social',
  'GET_FOLLOW_COUNT'
);

export const UPDATE_SOCIAL = '@@playlist/UPDATE_SOCIAL';
export const UPDATE_SOCIAL_EVENT = '@@playlist/UPDATE_SOCIAL_EVENT';

export const GET_FOLLOWERS = createAsyncActionType('social', 'GET_FOLLOWERS');

export const ADD_FOLLOW = createAsyncActionType('social', 'ADD_FOLLOW');

export const REMOVE_FOLLOW = createAsyncActionType('social', 'REMOVE_FOLLOW');

export const GET_FOLLOWED_INFO = createAsyncActionType(
  'social',
  'GET_FOLLOWED_INFO'
);

export const USER_SIDE_INITIAL_STATE = '@@social/USER_SIDE_INITIAL_STATE';
