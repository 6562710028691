import styled from 'styled-components/macro';

export const CloseWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.darker};
  height: 100%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Close = styled.span`
  font-size: x-large;
  color: ${props => props.theme.colors.white};
`;

export const Body = styled.div`
  padding-top: 20px;
  display: flex;
  /* align-items: center; */
  min-height: calc(100vh - 70px - 126px);
  height: fit-content;

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - 70px);
    justify-content: center;
  }
  @media ${props => props.theme.device.mobile} {
    align-items: unset;
  }
`;

export const BackWrapper = styled.div`
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${props => props.theme.colors.primary.darker};
  }

  & > span {
    margin-left: 10px;
  }
`;

export const IconBack = styled.div`
  transform: rotate(180deg);
  font-size: 30px;
`;

export const Title = styled.div`
  margin: 20px 0;
`;

export const Section = styled.div``;

export const SectionTitle = styled.div``;
