import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  CustomInputNumberContainer,
  InputNumber,
  QuantityNav,
  QuantityButton,
  Icon,
} from './style';

const CustomInputNumber = ({
  value: val,
  min,
  max = 99,
  onChange,
  disabled,
  // trackingCallback,
}) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    if (disabled) setValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const updateValue = (newValue = value) => {
    const valCal = Math.max(Math.min(newValue, max), 0);
    setValue(valCal);

    if (onChange) onChange(valCal);
  };

  const increase = () => {
    // trackingCallback('add_to_cart');
    updateValue(value + 1);
  };
  const decrease = () => {
    // trackingCallback('remove_from_cart');
    updateValue(value - 1);
  };

  return (
    <CustomInputNumberContainer disabled={disabled}>
      <QuantityNav>
        <QuantityButton
          className="quantity-down"
          disabled={disabled || value <= min}
          onClick={decrease}
        >
          <Icon
            className="icon-icona-rimuovi"
            disabled={value <= min || disabled}
          />
        </QuantityButton>
      </QuantityNav>
      <InputNumber
        disabled={disabled}
        min={min}
        max={max}
        value={value}
        onChange={({ target: { value: v } = {} }) => updateValue(v)}
      />
      <QuantityNav>
        <QuantityButton
          className="quantity-up"
          disabled={value >= max}
          onClick={increase}
        >
          <Icon className="icon-plus" disabled={disabled || value >= max} />
        </QuantityButton>
      </QuantityNav>
    </CustomInputNumberContainer>
  );
};

CustomInputNumber.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  // trackingCallback: PropTypes.func,
};

CustomInputNumber.defaultProps = {
  value: 0,
  min: 0,
  max: 99,
  // trackingCallback: () => {},
};

export default CustomInputNumber;
