import { createAsyncActionType } from './index';

export const GET_STATEMENTS = createAsyncActionType(
  'tracking',
  'GET_STATEMENTS'
);

export const POST_STATEMENTS = createAsyncActionType(
  'tracking',
  'POST_STATEMENTS'
);

export const GET_ACTIVITY = createAsyncActionType('tracking', 'GET_ACTIVITY');

export const PUT_ACTIVITY = createAsyncActionType('tracking', 'PUT_ACTIVITY');
