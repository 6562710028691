import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Col, Container, Row } from 'ui/gridSystem';

import { Button, CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { Footer } from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import routes from 'routes';
import validator from 'utils/regex-validator';
import { UPDATE_PASSWORD } from 'redux/actions/landingPage';
import { Head, HeadWrapper, Logo } from '../../style';
import {
  ChangePasswordContainer,
  Title,
  Body,
  ButtonWrapper,
  InputWrapper,
  Form
} from './style';
import getQueryParams from 'utils/getQueryParams';
import { Close, CloseWrapper } from '../Access/style';

const newField = () => ({
  value: undefined,
  valid: true
});

const ChangePassword = ({
  mediaIsPhone,
  pushUrl,
  updatePassword,
  reset_email,
  headerFooterVisible,
  domain
}) => {
  const initialForm = {
    password: newField(),
    confirmpassword: newField(),
    otp: newField()
  };
  const [form, setForm] = useState(initialForm);
  const [email, setEmail] = useState(reset_email);

  const queryEmail = getQueryParams('resetEmail');

  useEffect(() => {
    if (queryEmail) {
      setEmail(queryEmail);
    }
  }, [queryEmail]);

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const validateOtp = () => {
    setForm(s => {
      const newState = { ...s };
      if (newState.otp.value !== undefined) {
        newState.otp.valid =
          newState.otp.value.length >= 4 && newState.otp.value.length <= 6
            ? true
            : 'Il codice otp deve avere un massimo di 6 caratteri';
      }
      return newState;
    });
  };

  const validatePassword = () => {
    setForm(s => {
      const newState = { ...s };
      if (newState.password.value !== undefined) {
        newState.password.valid =
          validator.password(newState.password.value) ||
          'La password deve essere composta da almeno 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero ed un carattare speciale tra cui = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `';
      }
      if (newState.confirmpassword.value !== undefined) {
        newState.confirmpassword.valid =
          newState.password.value === newState.confirmpassword.value;
      }
      return newState;
    });
  };

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });

    if (callback) callback();
  };

  const buttonDisabled =
    form.password.value === undefined ||
    form.confirmpassword.value === undefined ||
    form.otp.value === undefined ||
    form.password.value !== form.confirmpassword.value ||
    form.password.valid !== true ||
    form.confirmpassword.valid !== true ||
    form.otp.valid !== true;

  const redirectHomepage = () => {
    setForm(initialForm);
    pushUrl(routes.landingPage.path);
    window.location.reload();
  };

  const handleUpdatePassword = () => {
    updatePassword({
      username: email,
      passwordUser: form.password.value,
      otp: form.otp.value
    });
    setForm(initialForm);
  };

  if (!email?.length && !queryEmail?.length) {
    pushUrl(routes.resetPassword.path);
  }

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={domain?.brand?.logo_white} />
            <CloseWrapper
              onClick={() => {
                pushUrl(routes.access.path);
                setForm(initialForm);
              }}
            >
              <Close className="icon-close-x" />
            </CloseWrapper>
          </Head>
        </Container>
      </HeadWrapper>
      <Container>
        <Row>
          <ChangePasswordContainer>
            <Col lg={4} md={6} sm={12}>
              <Body>
                <Title>
                  {mediaIsPhone ? (
                    <ProDisplay type="title" configuration={{ fontSize: '20' }}>
                      Reimposta la password
                    </ProDisplay>
                  ) : (
                    <ProDisplay type="title">Reimposta la password</ProDisplay>
                  )}
                </Title>
                <Form>
                  <InputWrapper>
                    <CustomInput
                      type="text"
                      autocomplete="new-password"
                      placeholder="Codice OTP"
                      value={form.otp.value}
                      onChange={val => handleOnChange('otp', val)}
                      onBlur={() =>
                        handleOnBlur('alphaNumeric', 'otp', validateOtp)
                      }
                      error={
                        typeof form.otp.valid === 'string'
                          ? form.otp.valid
                          : !form.otp.valid
                      }
                    />
                  </InputWrapper>
                </Form>
                <InputWrapper>
                  <CustomInput
                    type="password"
                    placeholder="Nuova Password"
                    value={form.password.value}
                    onChange={val => handleOnChange('password', val)}
                    onBlur={() =>
                      handleOnBlur('alphaNumeric', 'password', validatePassword)
                    }
                    error={
                      typeof form.password.valid === 'string'
                        ? form.password.valid
                        : !form.password.valid
                    }
                  />
                </InputWrapper>
                <CustomInput
                  type="password"
                  placeholder="Conferma password"
                  value={form.confirmpassword.value}
                  onChange={val => handleOnChange('confirmpassword', val)}
                  onBlur={validatePassword}
                  error={!form.confirmpassword.valid}
                />
                <ButtonWrapper>
                  <Button
                    type={Button.TYPE.PRIMARY}
                    text="Conferma"
                    disabled={buttonDisabled}
                    onClick={handleUpdatePassword}
                  />
                </ButtonWrapper>
              </Body>
            </Col>
          </ChangePasswordContainer>
        </Row>
      </Container>
      {headerFooterVisible && <Footer />}
    </>
  );
};

ChangePassword.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  reset_email: PropTypes.string.isRequired,
  headerFooterVisible: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
  domain: PropTypes.object
};

export default connect(
  state => {
    const { reset_email, headerFooterVisible } = state.landingPage;
    return {
      reset_email,
      headerFooterVisible,
      domain: state.domain
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    updatePassword: body => dispatch({ type: UPDATE_PASSWORD._REQUEST, body })
  })
)(withMediaQueries(ChangePassword));
