import display from "./proDisplay.newtypes";
import text from "./proText.newtypes";

function objToEnum(obj) {
  return Object.fromEntries(
    Object.keys(obj)
      .map(d => [
        d.replace(/[A-Z]/g, letter => `_${letter}`)
          .toUpperCase(),
        d
      ])
  )
}

const TYPES = {
  DISPLAY: objToEnum(display),
  TEXT: objToEnum(text)
};

export default TYPES;