import styled from 'styled-components/macro';

export const StreghtWrapper = styled.div`
  padding: 1rem 0;
`;

export const StrenghtBar = styled.div`
  background-color: ${props => props.theme.colors.ghostWhite};
  border-radius: 5px;
  height: 5px;
  position: relative;
`;

export const StrenghtValue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.valColor};
  width: ${props => props.widthBar};
  transition: all ease-in-out 500ms;
  border-radius: 5px;
`;

export const StrenghtLabel = styled.div`
  padding-top: 1rem;
`;
