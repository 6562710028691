import { createAsyncActionType } from './index';

export const USER_RESET_PASSWORD = createAsyncActionType(
  'landingPage',
  'USER_RESET_PASSWORD'
);

export const UPDATE_PASSWORD = createAsyncActionType(
  'updatePassword',
  'UPDATE_PASSWORD'
);

export const SET_PASSWORD_MANAGER = createAsyncActionType(
  'landingPage',
  'SET_PASSWORD_MANAGER'
);

export const SET_PASSWORD_MANAGER_FROM_BANK = createAsyncActionType(
  'landingPage',
  'SET_PASSWORD_MANAGER_FROM_BANK'
);

export const GET_LANDING_PAGE = createAsyncActionType(
  'landingPage',
  'GET_LANDING_PAGE'
);

export const GET_CONTENTS_CAROUSEL = createAsyncActionType(
  'landingPage',
  'GET_CONTENTS_CAROUSEL'
);

export const CREATE_USER = createAsyncActionType('landingPage', 'CREATE_USER');

export const REMEMBER_ME = '@@landingPage/REMEMBER_ME';
export const RESET_PASSWORD_EMAIL = '@@landingPage/RESET_PASSWORD_EMAIL';
export const FIRST_ACCESS_SET = '@@landingPage/FIRST_ACCESS_SET';

export const GET_LANDINGPAGE_PRODUCTS = createAsyncActionType(
  'landingPage',
  'GET_LANDINGPAGE_PRODUCTS'
);

export const GET_FULL_CATALOG = createAsyncActionType(
  'landingPage',
  'GET_FULL_CATALOG'
);
