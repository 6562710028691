import React from 'react';
import { RightArrow, Icon, RightArrowWrapper } from './style';

const CustomRightArrow = ({ onClick, offset='0px' }) => {
  return (
    <RightArrowWrapper offset={offset}>
      <RightArrow onClick={onClick}>
        <Icon className="icon-btn-arrow" />
      </RightArrow>
    </RightArrowWrapper>
  );
};

export default CustomRightArrow;
