import colors from './partials/colors';
import constants from './partials/constants';
import { fonts } from './partials/fonts';
import zIndex from './partials/zIndex';
import { device } from './partials/device';
import { mixin } from './partials/mixin';

function theme(themeColor) {
  const themeColors = colors(themeColor);

  return {
    colors: themeColors,
    device,
    constants,
    fontset: fonts,
    zindex: zIndex,
    mixin
  };
}

export default theme;
