import React from 'react';
import PropTypes from 'prop-types';

import { Current, NumerationContainer } from './style';
import ProText from 'ui/typography/proText';

const Numeration = ({ currentStep, totalSteps }) => (
  <NumerationContainer>
    <Current>
      <ProText type="5" configuration={{ fontSize: '26' }}>
        {currentStep}
      </ProText>
    </Current>
    <ProText type="description">di {totalSteps}</ProText>
  </NumerationContainer>
);

Numeration.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number
};

export default Numeration;
