import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Title, Subtitle, ContentWrapper, Icon } from './style';

import { ModalWrapper } from '../InviteFriendModal/style';


const modalId = 'DeletePaymentWarningModal';

const DeletePaymentWarningModal = ({ onClose }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Title>
              <ProDisplay type="modalTitle">Attenzione</ProDisplay>
            </Title>
            <Subtitle>
              <ProDisplay
                configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
              >
                Non è possibile eliminare il metodo di pagamento selezionato.
              </ProDisplay>
            </Subtitle>
            <Subtitle>
              <ProDisplay
                configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
              >
                Assicurati che il metodo di pagamento che desideri rimuovere non
                sia associato a nessun abbonamento attivo.
              </ProDisplay>
            </Subtitle>
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

DeletePaymentWarningModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

DeletePaymentWarningModal.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(DeletePaymentWarningModal);
