import { breakpoint } from 'ui/theme/partials/device';

const {
  largeDesktop,
  desktop,
  laptop,
  tablet,
  mobile
} = breakpoint;

export const responsive5min = {
  largeDesktop: {
    breakpoint: { max: 3000, min: largeDesktop },
    items: 6
  },
  desktop: {
    breakpoint: { max: largeDesktop, min: desktop },
    items: 5
  },
  laptop: {
    breakpoint: { max: desktop, min: laptop },
    items: 4
  },
  tablet: {
    breakpoint: { max: laptop, min: tablet },
    items: 4
  },
  mobile: {
    breakpoint: { max: tablet, min: mobile },
    items: 3
  },
  xsMobile: {
    breakpoint: { max: mobile, min: 490 },
    items: 2.5
  },
  xxsMobile: {
    breakpoint: { max: 490, min: 400 },
    items: 2
  },
  xxxsMobile: {
    breakpoint: { max: 400, min: 300 },
    items: 1.5
  },
  xxxxsMobile: {
    breakpoint: { max: 300, min: 0 },
    items: 1.2
  },
}

export const responsiveProducts = {
  largeDesktop: {
    breakpoint: { max: 3000, min: largeDesktop },
    items: 3
  },
  desktop: {
    breakpoint: { max: largeDesktop, min: desktop },
    items: 3
  },
  laptop: {
    breakpoint: { max: desktop, min: laptop },
    items: 3
  },
  tablet: {
    breakpoint: { max: laptop, min: tablet },
    items: 3
  },
  mobile: {
    breakpoint: { max: tablet, min: mobile },
    items: 2
  },
  xsMobile: {
    breakpoint: { max: mobile, min: 530 },
    items: 1.75
  },
  xxsMobile: {
    breakpoint: { max: 530, min: 500 },
    items: 1.5
  },
  xxxsMobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1.35
  },
  xxxxsMobile: {
    breakpoint: { max: 440, min: 0 },
    items: 1.2
  },
  xxxxxsMobile: {
    breakpoint: { max: 370, min: 0 },
    items: 1
  },
}

export const responsiveEvents = {
  largeDesktop: {
    breakpoint: { max: 3000, min: largeDesktop },
    items: 3
  },
  desktop: {
    breakpoint: { max: largeDesktop, min: desktop },
    items: 2
  },
  laptop: {
    breakpoint: { max: desktop, min: laptop },
    items: 1
  },
  tablet: {
    breakpoint: { max: laptop, min: tablet },
    items: 3
  },
  mobile: {
    breakpoint: { max: tablet, min: mobile },
    items: 3
  },
  xsMobile: {
    breakpoint: { max: mobile, min: 440 },
    items: 2
  },
  xxsMobile: {
    breakpoint: { max: 440, min: 360 },
    items: 1.5
  },
  xxxsMobile: {
    breakpoint: { max: 360, min: 300 },
    items: 1.25
  },
  xxxxsMobile: {
    breakpoint: { max: 300, min: 0 },
    items: 1
  },
}

export const responsiveKeepWatching = {
  largeDesktop: {
    breakpoint: { max: 3000, min: largeDesktop },
    items: 4
  },
  desktop: {
    breakpoint: { max: largeDesktop, min: desktop },
    items: 3
  },
  laptop: {
    breakpoint: { max: desktop, min: laptop },
    items: 3
  },
  tablet: {
    breakpoint: { max: laptop, min: tablet },
    items: 3
  },
  mobile: {
    breakpoint: { max: tablet, min: mobile },
    items: 3
  },
  xsMobile: {
    breakpoint: { max: mobile, min: 520 },
    items: 2.5
  },
  xxsMobile: {
    breakpoint: { max: 520, min: 420 },
    items: 2
  },
  xxxsMobile: {
    breakpoint: { max: 420, min: 320 },
    items: 1.5
  },
  xxxxsMobile: {
    breakpoint: { max: 320, min: 0 },
    items: 1
  },
}

export const responsiveSmallCards = {
  largeDesktop: {
    breakpoint: { max: 3000, min: largeDesktop },
    items: 6
  },
  desktop: {
    breakpoint: { max: largeDesktop, min: desktop },
    items: 5
  },
  laptop: {
    breakpoint: { max: desktop, min: laptop },
    items: 4
  },
  tablet: {
    breakpoint: { max: laptop, min: 701 },
    items: 3.6
  },
  mobile: {
    breakpoint: { max: 700, min: 650 },
    items: 3.4
  },
  xsMobile: {
    breakpoint: { max: 650, min: 610 },
    items: 3.2
  },
  xxsMobile: {
    breakpoint: { max: 609, min: 550 },
    items: 2.8
  },
  xxxsMobile: {
    breakpoint: { max: 549, min: 490 },
    items: 2.5
  },
  xxxxsMobile: {
    breakpoint: { max: 489, min: 450 },
    items: 2.2
  },
  xxxxxsMobile: {
    breakpoint: { max: 449, min: 400 },
    items: 2.1
  },
  xxxxxxsMobile: {
    breakpoint: { max: 399, min: 361 },
    items: 1.8
  },
  xxxxxxxsMobile: {
    breakpoint: { max: 360, min: 0 },
    items: 1.6
  }
};

export const responsiveLargeCards = {
  desktop: {
    breakpoint: { max: 3000, min: desktop },
    items: 4
  },
  laptop: {
    breakpoint: { max: desktop, min: laptop },
    items: 3
  },
  tablet: {
    breakpoint: { max: laptop, min: tablet },
    items: 3
  },
  mobile: {
    breakpoint: { max: tablet, min: 576 },
    items: 3
  },
  xsMobile: {
    breakpoint: { max: 576, min: 0 },
    items: 2.3
  },
  xxsMobile: {
    breakpoint: { max: 478, min: 460 },
    items: 2.2
  },
  xxxsMobile: {
    breakpoint: { max: 459, min: 412 },
    items: 2
  },
  xxxxsMobile: {
    breakpoint: { max: 411, min: 376 },
    items: 1.8
  },
  xxxxxsMobile: {
    breakpoint: { max: 375, min: 324 },
    items: 1.5
  },
  xxxxxxsMobile: {
    breakpoint: { max: 323, min: 0 },
    items: 1.4
  }
};

export const responsiveLandingCards = (size = 600) => {
  size -= 40;
  const cardWidth = 320;
  return {
    desktop: {
      breakpoint: { max: 3000, min: desktop },
      items: size / cardWidth
    },
    laptop: {
      breakpoint: { max: desktop, min: laptop },
      items: size / cardWidth
    },
    tablet: {
      breakpoint: { max: laptop, min: tablet },
      items: size / cardWidth
    },
    mobile: {
      breakpoint: { max: tablet, min: 576 },
      items: size / cardWidth
    },
    xsMobile: {
      breakpoint: { max: 576, min: 370 },
      items: size / (cardWidth - 5)
    },
    xxsMobile: {
      breakpoint: { max: 370, min: 0 },
      items: size / (cardWidth - 20)
    }
  };
};
