import styled from 'styled-components/macro';

export const DropZoneWrapper = styled.div`
  width: 90%;
  height: 140px;
  background-color: ${props => props.theme.colors.ghostWhite};
  border: 2px dashed ${props => props.theme.colors.primary.lighter};
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.placeholder};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const Link = styled.a`
  padding-right: 4px;
  color: ${props => props.theme.colors.primary.base};
  text-decoration: underline;
  height: fit-content;
  &:hover {
    text-decoration: underline;
  }
`;

export const IconWrapper = styled.div`
  margin-left: 10px;
  transform: rotate(-90deg);
  cursor: pointer;
`;

export const Icon = styled.span`
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
`;

export const ErrorText = styled.p`
  color: ${props => props.theme.colors.redError};
  margin-top: 5px;
`;

export const Description = styled.div`
  max-width: 80%;
`;
