import { takeLatest, put, call } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import { CONFIG_TEXT_GET } from 'redux/actions/configuration';

import configuration from 'api/configuration';
import ModalErrors from 'ui/components/Modals/ModalErrors';

function* configTextGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const response = yield call(configuration.getConfigText);
    yield put({
      type: CONFIG_TEXT_GET._SUCCESS,
      response: response.data
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > app > configTextGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* configTextGetWatch() {
  yield takeLatest(CONFIG_TEXT_GET._REQUEST, configTextGet);
}

export default [configTextGetWatch()];
