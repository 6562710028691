import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { DELETE_NOTE } from 'redux/actions/notes';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon
} from './style';

const modalId = 'DeleteNoteModal';

const DeleteNoteModal = ({ onClose, noteId, deleteNote }) => {
  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Title>
                <ProDisplay type="modalTitle">Elimina questa nota.</ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  Sei sicuro di voler eliminare questa nota?
                </ProDisplay>
              </Subtitle>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    onClick={onClose}
                    type={Button.TYPE.TERTIARY}
                    text="Annulla"
                  />
                </BtnContainer>
                <BtnContainer>
                  <Button
                    onClick={() => {
                      deleteNote(noteId);
                      onClose();
                    }}
                    type={Button.TYPE.PRIMARY}
                    text="Conferma"
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

DeleteNoteModal.propTypes = {
  noteId: PropTypes.string,
  onClick: PropTypes.func,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  deleteNote: PropTypes.func
};

DeleteNoteModal.id = modalId;

export default connect(
  state => {
    const { noteId } = state.modal.payload;
    return { noteId };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    deleteNote: id => dispatch({ type: DELETE_NOTE._REQUEST, id })
  })
)(DeleteNoteModal);
