import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';
import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import DetailSection from 'pages/OnBoarding/DrawProfile/DetailSection';

import { MODAL_CLOSE } from 'redux/actions';
import { ModalWrapper } from '../InviteFriendModal/style';
import { ContentWrapper, Icon } from './style';

const modalId = 'PlaylistDetailModal';

const PlaylistDetailModal = ({ onClose, payload }) => {
  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            width="80%"
            height="70vh"
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <DetailSection item={payload} catalogModal />
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

PlaylistDetailModal.propTypes = {
  // HOC (connect, state)
  payload: PropTypes.object,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

PlaylistDetailModal.id = modalId;

export default connect(
  state => {
    const { payload } = state.modal;
    return { payload };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(PlaylistDetailModal);
