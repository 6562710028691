import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import routes from 'routes';
import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import Button from 'ui/atoms/Button';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Title, ContentWrapper, Icon, BtnContainer } from './style';

import { ModalWrapper } from '../InviteFriendModal/style';


const modalId = 'EmailModal';

const EmailModal = ({ onClose, manager_info, pushUrl }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Title>
              <ProDisplay type="modalTitle">
                La mail inserita risulta essere già registrata
              </ProDisplay>
            </Title>
            {manager_info.errorMessage
              === 'An account with the given email already exists.' && (
              <BtnContainer>
                <Button
                  onClick={() => pushUrl(routes.access.path)}
                  type={Button.TYPE.PRIMARY}
                >
                  Accedi
                </Button>
              </BtnContainer>
            )}
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

EmailModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  manager_info: PropTypes.object,
  pushUrl: PropTypes.func.isRequired
};

EmailModal.id = modalId;

export default connect(
  state => {
    const { user: manager_info } = state.authentication;
    return {
      manager_info
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(EmailModal);
