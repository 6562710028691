import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';

import Img from 'ui/assets/img/group.png';
import { PaymentUpdateConfigSuccessInterface } from 'interface/configuration';
import routes from 'routes';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Image,
  IconCheck,
  Head,
  Bottom
} from './style';

const modalId = 'PaymentUpdateModal';

const PaymentUpdateModal = ({
  pushUrl,
  onClose,
  payment_update: { title, description, list = [], end } = {}
}) => {
  const CloseAndChangeLocation = () => {
    onClose();
    pushUrl(routes.managerProfile.path);
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={CloseAndChangeLocation} />
              <Image src={Img} />
              <Title>
                <ProDisplay type="modalTitle">{title}</ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  {description ? <Head>{description}</Head> : null}
                  {(list || []).map(item => (
                    <div>
                      <IconCheck className="icon-check" />
                      {item}
                    </div>
                  ))}
                  {end ? <Bottom>{end}</Bottom> : null}
                </ProDisplay>
              </Subtitle>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

PaymentUpdateModal.propTypes = {
  // HOC (connect, state)
  payment_update: PaymentUpdateConfigSuccessInterface,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

PaymentUpdateModal.id = modalId;

export default connect(
  state => {
    const { payment_update } = state.configuration;
    return {
      payment_update
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    pushUrl: url => dispatch(push(url))
  })
)(PaymentUpdateModal);
