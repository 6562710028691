import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import moment from 'moment';
import validator from 'utils/regex-validator';

import { CustomInput } from 'ui/atoms';

import {
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCVCElement
} from 'ui/components';

import { Row, Col } from 'ui/gridSystem';
import compose from 'utils/compose';
import { LOADER_CLOSE, LOADER_OPEN, SET_TIMESTAMP } from 'redux/actions';

const creditCardFormValidity = {
  owner: false,
  card_number: false,
  expiry: false,
  cvc: false
};

const CreditCard = ({
  setData,
  stripe,
  billingDetails: billing_details,
  clientSecret: client_secret,
  disabled,
  callback,
  loaderOpen,
  loaderClose,
  setTimestamp
}) => {
  const [formValidity, setFormValidity] = useState(creditCardFormValidity);
  const [cardOwner, setCardOwner] = useState();
  const [cardNumber, setCardNumberRef] = useState();
  const [cardExpiry, setExpiryRef] = useState();
  const [cardCvc, setCvcRef] = useState();

  const createPaymentMethod = () =>
    new Promise(res => {
      // eslint-disable-next-line react/prop-types
      loaderOpen();
      stripe
        .retrieveSetupIntent(client_secret)
        .then(result => {
          const { error, setupIntent } = result;
          loaderClose();

          if (setupIntent) {
            loaderOpen();
            setTimestamp(moment().valueOf());
            stripe
              .confirmCardSetup(client_secret, {
                payment_method: {
                  card: cardNumber,
                  billing_details: {
                    ...billing_details,
                    name: cardOwner
                  }
                }
              })
              .then(response => {
                loaderClose();
                if (callback) {
                  callback(response);
                }
                res(response);
              })
              .catch(err => {
                console.log(err);
                loaderClose();
                setData(undefined);
              });
          } else if (error) {
            console.log('retrieveSetupIntent ', error);
          }
        })
        .catch(error => {
          console.log(error);
          loaderClose();
        });
    });

  const setCreditCard = () => {
    const formValid =
      Object.values(formValidity).filter(value => !value === false).length <= 0;
    if (formValid && cardOwner && cardNumber && cardExpiry && cardCvc) {
      setData({
        billing_details: {
          name: cardOwner
        }
      });
      createPaymentMethod();
    } else {
      setData(undefined);
    }
  };

  useEffect(() => {
    if (setData) {
      setCreditCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValidity]);

  const CreditCardRef = (ref, field, callbackRef) => {
    ref.addEventListener('change', event => {
      const { error, empty, complete } = event;
      if (error || empty || !complete) {
        callbackRef(undefined);
        setFormValidity(prevState => ({
          ...prevState,
          [field]: error ? error.message : true
        }));
      } else {
        callbackRef(ref);
        setFormValidity(prevState => ({
          ...prevState,
          [field]: false
        }));
      }
    });
  };

  const handleOnchangeName = value => {
    setFormValidity(prevState => ({
      ...prevState,
      owner: !validator.string(value)
    }));
    setCardOwner(value);
  };

  return (
    <div>
      <form
        id="credit-card"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Row className="mb-3">
          <Col sm={12}>
            <CustomInput
              text="Numero di carta"
              error={formValidity.card_number}
              autocomplete="cc-number"
            >
              <StripeCardNumberElement
                onReady={ref =>
                  CreditCardRef(ref, 'card_number', setCardNumberRef)
                }
                disabled={disabled}
              />
            </CustomInput>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} className="mb-3">
            <CustomInput
              type="text"
              text="Intestatario carta"
              value={cardOwner}
              onChange={value => handleOnchangeName(value)}
              error={formValidity.owner && cardOwner.length > 0}
              disabled={disabled}
              autocomplete="cc-name"
            />
          </Col>
          <Col md={3} sm={6} className="mb-3">
            <CustomInput
              text="Valida fino a"
              error={formValidity.expiry}
              autocomplete="cc-exp"
            >
              <StripeCardExpiryElement
                onReady={ref => CreditCardRef(ref, 'expiry', setExpiryRef)}
                disabled={disabled}
              />
            </CustomInput>
          </Col>
          <Col md={3} sm={6} className="mb-3">
            <CustomInput
              text="CVC"
              error={formValidity.cvc}
              autocomplete="cc-csc"
            >
              <StripeCardCVCElement
                onReady={ref => CreditCardRef(ref, 'cvc', setCvcRef)}
                disabled={disabled}
              />
            </CustomInput>
          </Col>
        </Row>
      </form>
    </div>
  );
};

CreditCard.propTypes = {
  setData: PropTypes.func,
  stripe: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  clientSecret: PropTypes.string.isRequired,
  billingDetails: PropTypes.object,
  callback: PropTypes.func,
  loaderOpen: PropTypes.func,
  loaderClose: PropTypes.func,
  setTimestamp: PropTypes.func
};

const composed = compose(
  connect(
    () => ({}),
    dispatch => ({
      loaderOpen: () => dispatch({ type: LOADER_OPEN }),
      loaderClose: () => dispatch({ type: LOADER_CLOSE }),
      setTimestamp: timestamp => dispatch({ type: SET_TIMESTAMP, timestamp })
    })
  )
)(injectStripe(CreditCard));
export default composed;
