import styled from 'styled-components/macro';

const Field = styled.div`
  width: 100%;
  padding-bottom: 15px;
  position: relative;

  @media ${props => props.theme.device.mobile} {
    padding-bottom: 15px;
  }
`;

const Label = styled.div`
  margin-bottom: 5px;
`;

const Description = styled.div`
  &.hidden {
    -webkit-text-security: disc;
  }
`;

const Text = styled.span`
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  font-size: 12px;
  margin-right: 11px;
`;

const Editable = styled.span`
  position: absolute;
  bottom: 25%;
  right: 0;
  font-size: x-large;
  opacity: 0.65;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;

  &.only {
    position: relative;
    bottom: 0;

    @media ${props => props.theme.device.tablet} {
      position: absolute;
      bottom: 16px;
    }
  }

  @media ${props => props.theme.device.mobile} {
    bottom: 20px;
  }
`;

export { Label, Icon, Description, Field, Text, Editable };
