import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';
import validator from 'utils/regex-validator';

import { Button, CustomInput, Numeration } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { SET_PASSWORD_MANAGER } from 'redux/actions/landingPage';
import { FlowHeader, FlowHeaderWrapper, Logo } from 'pages/Signup/style';
import getQueryParams from 'utils/getQueryParams';
import PasswordStrength from 'ui/atoms/PasswordStrenght';
import PasswordSuggestion from 'ui/atoms/PasswordSuggestion';
import { ResetPasswordContainer, Title, ButtonWrapper } from './style';

const newField = () => ({
  value: undefined,
  valid: true
});

const initialForm = {
  email: newField(),
  pwd: newField(),
  confirmpwd: newField()
};

const SetFirstPassword = ({ brand, setPassword }) => {
  const [form, setForm] = useState(initialForm);
  const [formValid, setFormValid] = useState(false);
  const email = getQueryParams('email');

  const checkForm = form => {
    const validFields = (Object.keys(form) || []).map(key =>
      Boolean(
        form[key].value &&
          form[key].value.length > 0 &&
          form[key].valid === true
      )
    );
    setFormValid(validFields[1] && validFields[2]);
  };

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const validatePassword = () => {
    setForm(s => {
      const newState = { ...s };
      if (newState.pwd?.value !== undefined) {
        newState.pwd.valid =
          validator.password(newState.pwd.value) ||
          'La password deve essere composta da almeno 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero ed un carattare speciale tra cui = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `';
      }
      if (newState.confirmpwd?.value) {
        newState.confirmpwd.valid =
          newState.pwd?.value === newState.confirmpwd.value;
      }
      checkForm(newState);
      return newState;
    });
  };

  return (
    <>
      <FlowHeaderWrapper>
        <Container>
          <FlowHeader>
            <Logo img={brand?.logo_white} />
            <Numeration currentStep={1} totalSteps={2} />
          </FlowHeader>
        </Container>
      </FlowHeaderWrapper>
      <Container>
        <Row>
          <ResetPasswordContainer>
            <Col xl={4} lg={5} md={8} sm={10}>
              <Title>
                <ProDisplay
                  type="title"
                  configuration={{ color: 'primary', colorType: 'base' }}
                >
                  Reimposta la password
                </ProDisplay>
              </Title>
            </Col>

            <Col xl={4} lg={5} md={8} sm={10} className="mb-3">
              <CustomInput
                type="text"
                placeholder="Email"
                value={email}
                disabled
              />
            </Col>
            <Col xl={4} lg={5} md={8} sm={10} className="mb-3">
              <CustomInput
                type="password"
                placeholder="Nuova Password"
                value={form.pwd.value}
                onChange={val => handleOnChange('pwd', val)}
                onBlur={validatePassword}
                error={
                  typeof form.pwd.valid === 'string'
                    ? form.pwd.valid
                    : !form.pwd.valid
                }
              />
              <PasswordStrength password={form.pwd.value} />
            </Col>
            <Col xl={4} lg={5} md={8} sm={10} className="mb-3">
              <CustomInput
                type="password"
                placeholder="Conferma Nuova Password"
                value={form.confirmpwd.value}
                onChange={val => {
                  handleOnChange('confirmpwd', val);
                  validatePassword();
                }}
                error={!form.confirmpwd.valid}
              />
            </Col>
            <Col xl={4} lg={5} md={8} sm={10} className="mb-3">
              <PasswordSuggestion password={form.pwd} />
            </Col>
            <ButtonWrapper>
              <Button
                type={Button.TYPE.PRIMARY}
                text="Continua"
                disabled={!formValid}
                onClick={() =>
                  setPassword({ email, passwordUser: form.pwd.value })
                }
              />
            </ButtonWrapper>
          </ResetPasswordContainer>
        </Row>
      </Container>
    </>
  );
};

SetFirstPassword.propTypes = {
  // HOC withMediaQueries

  // HOC (connect, state)
  brand: PropTypes.object,

  // HOC (connect, dispatch)
  setPassword: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { brand } = state.domain;
    return {
      brand
    };
  },
  dispatch => ({
    setPassword: payload =>
      dispatch({ type: SET_PASSWORD_MANAGER._REQUEST, payload })
  })
)(withMediaQueries(SetFirstPassword));
