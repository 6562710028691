import styled from 'styled-components/macro';
import {
  MenuHeight,
  MenuHeightLaptop,
  MenuHeightTablet,
  NavBarHeight,
} from 'const';

const MainContainer = styled.div.attrs(props => ({
  className: `${props.noMenu ? 'no-menu' : ''}`
}))`
  width: 100%;
  min-height: calc(100vh - ${NavBarHeight}px);
  background-color: ${props => props.whiteBg
    ? props.theme.colors.white
    : props.theme.colors.ghostWhite
  };
  overflow-x: hidden;
  position: relative;
  padding-bottom: 20px;

  &.fixed {
    position: fixed;
  }

  &.no-menu {
    min-height: calc(100vh - ${NavBarHeight}px - ${MenuHeight}px);

    @media ${props => props.theme.device.laptop} {
      min-height: calc(100vh - ${NavBarHeight}px - ${MenuHeightLaptop}px);
    }
    @media ${props => props.theme.device.tablet} {
      min-height: calc(100vh - ${NavBarHeight}px - ${MenuHeightTablet}px);
    }
  }
`;

export default MainContainer;
