// High-order components must use the spreading operator
// to pass the props down to the real one.
/* eslint-disable react/jsx-props-no-spreading */
import React, { useLayoutEffect, useState } from 'react';

import { breakpoint } from 'ui/theme/partials/device';
import { getCurrentViewport } from 'utils/responsive';

const defaultMediaQueries = {
  mediaIsPhone: false,
  mediaIsTablet: false,
  mediaIsLaptop: false,
  mediaIsDesktop: false,
  mediaIsLargeDesktop: false,
};

const viewportTypes = {
  mediaIsPhone: 0,
  mediaIsTablet: 1,
  mediaIsLaptop: 2,
  mediaIsDesktop: 3,
  mediaIsLargeDesktop: 4
}

export function withMediaQueries(LazyComp) {
  const WithMediaQueries = props => {
    const [mediaQueries, setMediaQueries] = useState(defaultMediaQueries);
    const [currentViewport, setCurrentViewport] = useState('');

    useLayoutEffect(() => {
      function updateSize() {
        const newMediaQueries = {
          ...mediaQueries,
          mediaIsPhone:
            // window.innerWidth >= breakpoint.mobile &&
            window.innerWidth < breakpoint.tablet,
          mediaIsTablet:
            window.innerWidth >= breakpoint.tablet &&
            window.innerWidth < breakpoint.laptop,
          mediaIsLaptop:
            window.innerWidth >= breakpoint.laptop &&
            window.innerWidth < breakpoint.desktop,
          mediaIsDesktop:
            window.innerWidth >= breakpoint.desktop &&
            window.innerWidth < breakpoint.largeDesktop,
          mediaIsLargeDesktop: window.innerWidth >= breakpoint.largeDesktop
        };
        setMediaQueries(newMediaQueries);
        const newCurrentViewport = getCurrentViewport(newMediaQueries);
        setCurrentViewport(newCurrentViewport);
      };

      window.addEventListener('resize', updateSize);
      updateSize();

      return () => window.removeEventListener('resize', updateSize);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <LazyComp
      {...props}
      {...mediaQueries}
      currentViewport={currentViewport}
      viewportTypes={viewportTypes}
    />;
  };

  WithMediaQueries.displayName = `WithMediaQueries(${LazyComp.displayName ||
    LazyComp.name ||
    'Component'})`;

  return WithMediaQueries;
}
