import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import Img from 'ui/assets/img/group.png';
import ProDisplay from 'ui/typography/proDisplay';
import { ContentWrapper, Image, Title } from 'pages/Signup/steps/Confirm/style';
import { Container } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Button } from 'ui/atoms';
import compose from 'utils/compose';
import ProText from 'ui/typography/proText';
import routes from 'routes';
import { ButtonSection, ButtonWrapper, Text } from './styles';

const ConfirmCreateUser = ({ mediaIsPhone, pushUrl }) => {
  return (
    <Container>
      <ContentWrapper>
        <Image src={Img} />
        <Title>
          <ProDisplay type="modalTitle">Registrazione completata</ProDisplay>
        </Title>
        <Text>
          <ProText
            type="description"
            configuration={mediaIsPhone ? { fontSize: '16' } : null}
          >
            Da questo momento puoi accedere ad un'esperienza di formazione
            davvero unica!
          </ProText>
        </Text>
        <Text>
          <ProText
            type="description"
            configuration={mediaIsPhone ? { fontSize: '16' } : null}
          >
            Cosa aspetti? Comincia subito!
          </ProText>
        </Text>
        <ButtonSection>
          <ButtonWrapper className="with-margin">
            <Button
              name="Scarica"
              onClick={() =>
                window.open(
                  'https://apps.apple.com/us/app/skills4capital/id1575702730',
                  '_blank'
                )
              }
              type={Button.TYPE.STORE}
              isDownload
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              onClick={() => {
                pushUrl(routes.homepage.path);
              }}
              type={Button.TYPE.PRIMARY}
            >
              Vai alla Home
            </Button>
          </ButtonWrapper>
        </ButtonSection>
      </ContentWrapper>
    </Container>
  );
};

ConfirmCreateUser.propTypes = {
  // HOC (connect, state)

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,

  // HOC withMediaQueires
  mediaIsPhone: PropTypes.bool.isRequired
};

const composed = compose(
  connect(
    () => ({}),
    dispatch => ({
      pushUrl: url => dispatch(push(url))
    })
  ),
  withMediaQueries
)(ConfirmCreateUser);
export default composed;
