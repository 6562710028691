export const imageParse = (data, headers) => {
  try {
    const responseText = data.test();

    let binary = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < responseText.length; i++) {
      // eslint-disable-next-line no-bitwise
      binary += String.fromCharCode(responseText.charCodeAt(i) & 255);
    }

    const image = `data:${headers['content-type'] || 'image/jpg'};base64,${btoa(
      unescape(encodeURIComponent(binary))
    )}`;

    return image;
  } catch (err) {
    console.log('error parsing image', err);
    return '';
  }
};
