import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 372px;
  text-align: center;
`;

export const BtnContainer = styled.div`
  width: 177px;

  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 5%;
  font-size: 30px;
  right: 4%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;
