import {
  getDomainMock,
  getFilteredDomainSettingsMock,
  shouldUseMock
} from './mock';
import { apiCall, baseUrl, extendUrl } from './config';

export default {
  getDomain: () => {
    if (shouldUseMock()) {
      return getDomainMock();
    }
    return apiCall(`${baseUrl}${extendUrl}filtered_domain`, 'GET', null, {
      isDrupal: true
    });
  },
  getFilteredDomainSetting: () => {
    if (shouldUseMock()) {
      return getFilteredDomainSettingsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}filtered_domain_settings`, 'GET', null, { isDrupal: true });
  }
};
