import styled from 'styled-components/macro';

export const FooterRegistrationContainer = styled.div`
  border-top: solid 1px ${props => props.theme.colors.filterColor};
  /* height: fit-content; */
  max-height: 110px;
  width: 100%;
  background-color: white;

  @media ${props => props.theme.device.tablet} {
    max-height: 160px;
  }
  @media ${props => props.theme.device.mobile} {
    max-height: 270px;
  }
`;

export const Foot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
  }
`;

export const TotalPrice = styled.div`
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    //  font-size: 14px;
    line-height: 1.43;
    justify-content: center;
    margin-bottom: 16px;
  }
`;

export const Price = styled.span`
  margin-left: 10px;
`;

export const Label = styled.div`
  text-transform: uppercase;
  margin-right: 10px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  margin: 20px 0;
  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    padding: 0 2rem;
  }
`;

export const BtnsWrapper = styled.div`
  display: flex;
  margin: 20px 0;

  @media ${props => props.theme.device.mobile} {
    display: block;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;
  margin-left: 20px;

  @media ${props => props.theme.device.tablet} {
    /* min-width: 228px; */
  }

  @media ${props => props.theme.device.mobile} {
    min-width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;

export const SubscriptionPrice = styled.div`
  margin-left: 20px;
`;
