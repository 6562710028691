import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { RadioButton } from 'ui/atoms';
import MasterCard from 'ui/atoms/IconCreditCard/MasterCard';
import Visa from 'ui/atoms/IconCreditCard/Visa';
import Amex from 'ui/atoms/IconCreditCard/Amex';
import ConfirmModalCard from 'ui/components/Modals/DeletePaymentConfirmActionModal';
import { DELETE_PAYMENT } from 'redux/actions/profile';
import { BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET } from 'redux/actions/billings';
import ProText from 'ui/typography/proText';
import {
  PaymentCardContainer,
  ActionsWrapper,
  ActionBtn,
  Icons,
  Radio,
  RadioDescription,
} from './style';

const PaymentMethod = ({
  children,
  title,
  isOpen,
  isCreditCard,
  onSelect,
  actionable,
  deletePaymentMethod,
  setDefaultZuoraPaymentMethod,
  paymentId,
  noCheckbox,
  type,
  isCCZuora,
  isSEPAZuora,
}) => (
  <PaymentCardContainer isOpen={isOpen} actionable={actionable} isCCZuora={isCCZuora} isSEPAZuora={isSEPAZuora}>
    <Radio>
      {noCheckbox ? (
        <div style={{ width: '100%' }}>
          <ProText type="4">{title}</ProText>
        </div>
      ) : (
        <RadioButton
          checked={isOpen}
          name={`radio-payment-${isCreditCard ? 'card' : 'bank'}`}
          label={title}
          onChange={onSelect}
        />
      )}

      {isCreditCard ? (
        <Icons>
          <MasterCard />
          <Visa />
          <Amex />
        </Icons>
      ) : null}
    </Radio>
    {!isOpen && actionable ? (
      <ActionsWrapper>
        <ActionBtn onClick={() => deletePaymentMethod(paymentId)}>
          <ProText type="label">
            {type === 'CreditCard' ? 'Rimuovi carta' : 'Rimuovi conto'}
          </ProText>
        </ActionBtn>
        <ActionBtn onClick={() => setDefaultZuoraPaymentMethod(paymentId)}>
          <ProText type="label">
            Imposta come default
          </ProText>
        </ActionBtn>
      </ActionsWrapper>
    ) : null}
    {actionable && <ConfirmModalCard />}
    {Boolean(children) && <RadioDescription>{children}</RadioDescription>}
  </PaymentCardContainer>
);

PaymentMethod.propTypes = {
  paymentId: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool,
  isCreditCard: PropTypes.bool,
  onSelect: PropTypes.func,
  actionable: PropTypes.bool,
  noCheckbox: PropTypes.bool,
  isCCZuora: PropTypes.bool,
  isSEPAZuora: PropTypes.bool,

  //HOC (connect, dispatch)
  deletePaymentMethod: PropTypes.func.isRequired,
  setDefaultZuoraPaymentMethod: PropTypes.func.isRequired,
  type: PropTypes.string,
};

PaymentMethod.defaultProps = {
  actionable: false,
};

export default connect(
  () => ({}),
  dispatch => ({
    deletePaymentMethod: (paymentId = '') =>
      dispatch({ type: DELETE_PAYMENT, paymentId }),
    // openConfirmModalCard: () =>
    //   dispatch({ type: MODAL_OPEN, id: 'DeletePaymentConfirmActionModal' })
    setDefaultZuoraPaymentMethod: (payment_method_id) =>
      dispatch({ type: BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET._REQUEST, payment_method_id }),
  })
)(PaymentMethod);
