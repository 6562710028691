import { createAsyncActionType } from './index';

export const SET_SELECTED_GATEWAY = '@@billings/SET_SELECTED_GATEWAY';

export const BILLING_CLIENT_SECRET_RESET =
  '@@billings/BILLING_CLIENT_SECRET_RESET';

export const BILLINGS_GET_PAYMENT_GATEGAYS = createAsyncActionType(
  'billings',
  'BILLINGS_GET_PAYMENT_GATEGAYS'
);

export const BILLING_GET_PAYMENT_METHODS = createAsyncActionType(
  'billings',
  'BILLING_GET_PAYMENT_METHODS'
);

export const BILLING_GET_CLIENT_SECRET = createAsyncActionType(
  'billings',
  'BILLING_GET_CLIENT_SECRET'
);

export const BILLING_IS_PREMIUM_PURCHASE =
  '@@billings/BILLING_IS_PREMIUM_PURCHASE';

export const BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL = createAsyncActionType('billings', 'BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL');

export const RESET_BILLINGS = '@@billings/RESET_BILLINGS';
export const SET_PAYMENT_METHODS = '@@billings/RESET_BILLINGS';

export const BILLINGS_ZUORA_SIGNATURE_GET = createAsyncActionType('billings', 'BILLINGS_ZUORA_SIGNATURE_GET');
export const BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET = createAsyncActionType('billings', 'BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET');
export const BILLINGS_ZUORA_PAYMENT_METHOD_ADD = createAsyncActionType('billings', 'BILLINGS_ZUORA_PAYMENT_METHOD_ADD');
