export const subscription_type = {
  all: {
    name: 'All',
    label: 'Tutti',
    value: 'all'
  },
  full_training_subscription: {
    name: 'Full',
    label: 'FULL',
    value: 'full_training_subscription'
  },
  standard_training_subscription: {
    name: 'Obbligatoria',
    label: 'SOLO FORMAZIONE OBBLIGATORIA',
    value: 'standard_training_subscription'
  }
};

export const getLabelByType = ({ type: subType = '' }) => {
  switch (subType.toLowerCase()) {
    case 'Learning':
      return 'Pacchetto';
    case 'Learning subscription':
      return 'Abbonamento';
    default:
      return '-';
  }
};

export const getLabelByVarType = type => {
  switch (type.toLowerCase()) {
    case 'learning':
      return 'Pacchetto';
    case 'learning subscription':
      return 'Abbonamento';

    default:
      return '-';
  }
};

export const categories = {
  all: {
    name: 'all',
    label: 'Tutte',
    value: 'all'
  },
  management: {
    name: 'management',
    label: 'Gestione dei processi aziendali',
    value: 'management'
  },
  payments: {
    name: 'payments',
    label: 'Pagamenti e fatturazioni',
    value: 'payments'
  },
  access: {
    name: 'access',
    label: 'Accesso al creditore',
    value: 'access'
  }
};
