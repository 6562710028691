export const calcPagination = (
  { count = 0, from = 0, totalCount = 100 },
  itemPerPage = 0
) => {
  const pagination = {
    skip: 0,
    take: itemPerPage,
    isLastPage: true
  };

  let skip = 0;
  skip = (from + count) || skip;

  const isLastPage = ((count + skip) >= totalCount) || false;

  return {
    ...pagination,
    skip,
    isLastPage
  };
};
