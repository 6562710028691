import { FontBase } from 'const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'prodisplay',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'unset',
  fontSize: FontBase, // 16
  textAlign: 'unset',
  textTransform: 'unset',
  cursor: 'inherit',
};

const types = {
  loginTitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 40, fontWeight: 500 },
    mediaIsDesktop: { ...baseConfig, fontSize: 30, fontWeight: 500 },
    mediaIsLaptop: { ...baseConfig, fontSize: 30, fontWeight: 500 },
    mediaIsTablet: { ...baseConfig, fontSize: 36, fontWeight: 500 },
    mediaIsPhone: { ...baseConfig, fontSize: 30, fontWeight: 500 },
  },
  customInputUtility: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 20 },
    mediaIsDesktop: { ...baseConfig, fontSize: 16 },
    mediaIsLaptop: { ...baseConfig, fontSize: 16 },
    mediaIsTablet: { ...baseConfig, fontSize: 20 },
    mediaIsPhone: { ...baseConfig, fontSize: 16 },
  },
  courseCardTitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 20, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 20, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 20, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 14, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 12, fontWeight: 700 },
  },
  courseCardLabel: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 12 },
    mediaIsDesktop: { ...baseConfig, fontSize: 12 },
    mediaIsLaptop: { ...baseConfig, fontSize: 12 },
    mediaIsTablet: { ...baseConfig, fontSize: 11 },
    mediaIsPhone: { ...baseConfig, fontSize: 8 },
  },
  packageDetailTitle: {
    mediaIsLargeDesktop: { ...baseConfig, fontSize: 60, fontWeight: 700 },
    mediaIsDesktop: { ...baseConfig, fontSize: 55, fontWeight: 700 },
    mediaIsLaptop: { ...baseConfig, fontSize: 47, fontWeight: 700 },
    mediaIsTablet: { ...baseConfig, fontSize: 35, fontWeight: 700 },
    mediaIsPhone: { ...baseConfig, fontSize: 35, fontWeight: 700 },
  },
}

export default types;