export const parseOrders = orders => {
  const flatProduct = ({ variation, ...props }) => ({
    ...props,
    num: props.qty,
    order_item_id: props.id,
    ...variation,
    variation_id: variation?.id,
    product_price: props.total_price,
    price_number: props.total_price,
    product_type: variation.bundle
  });

  return orders.map(({ order_items, ...order }) => ({
    ...order,
    order_id: order.id,
    payment_method_id: order.paymentMethodId,
    order_items: (order_items || []).reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        flatProduct(currentValue)
      ],
      []
    )
  }));
};

export const parseProducts = orders => {
  const calculateProducts = (p, n) => {
    const times = Array(parseInt(n, 10));
    const out = [];
    // eslint-disable-next-line no-unused-vars
    for (const t of times) {
      out.push(p);
    }
    return out;
  };

  return orders.reduce(
    (ordAcc, order) => [
      ...ordAcc,
      ...order.order_items.reduce(
        (acc, { qty, variation, ...orderItem }) => [
          ...acc,
          ...calculateProducts(
            {
              ...variation,
              ...orderItem,
              variation_id: variation?.id,
              order_item_id: orderItem.id,
              type: variation.bundle
            },
            qty
          )
        ],
        []
      )
    ],
    []
  );
};
