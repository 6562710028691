const getDisplayVerb = type => {
  switch (type) {
    case 'initialized':
      return {
        'en-US': 'initialized',
        'it-IT': 'ha inizializzato'
      };
    case 'completed':
      return {
        'en-US': 'completed',
        'it-IT': 'ha completato'
      };
    case 'terminated':
      return {
        'en-US': 'terminated',
        'it-IT': 'ha terminato'
      };

    default:
      return {};
  }
};
export const getBodyStatements = params => {
  const body = {
    version: '1.0.0',
    actor: {
      objectType: 'Agent',
      mbox: `mailto:${params.email}`,
      name: `${params.firstname} ${params.lastname}`
    },
    verb: {
      id: `http://adlnet.gov/expapi/verbs/${params.type}`,
      display: getDisplayVerb(params.type)
    },
    object: {
      objectType: 'Activity',
      id: `https://intesasanpaoloformazione.com/s4b${params.itemId}`,
      definition: {
        name: {
          'it-IT': params.title || ''
        },
        description: {
          'it-IT': params.description || params.title
        }
      }
    }
  };
  if (params.type !== 'initialized') {
    body.result = {
      duration: `PT${params.duration}S`
    };
  }
  return body;
};

export const videoOptions = {
  autoplay: false,
  // playbackRates: [0.5, 1, 1.25, 1.5, 2],
  width: 720,
  height: 300,
  controls: true,
  muted: true,
  sources: [
    {
      src: null,
      type: 'video/mp4'
    }
  ],
  poster:
    'https://d22cxfqtum6m93.cloudfront.net/media/2021-04/logo-new-positivo-2.png?VersionId=kUDh6Z2LDMLxKZ.cV1MVGVCvIvc7FsIp%22'
};
