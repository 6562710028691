import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';



import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { DeleteSubscriptionInterface } from 'interface/configuration';
import { EMAIL_SUBSCRIPTION_DISABLE } from 'redux/actions/subscriptions';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon
} from './style';

const modalId = 'DisableSubscriptionModal';

const DisableSubscriptionModal = ({
  onClose,
  disable_subscription,
  disableEmailSubscription
}) => {
  const handleOnClick = () => {
    disableEmailSubscription();
    onClose();
  };
  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Title>
                <ProDisplay type="modalTitle">
                  {disable_subscription.title}
                </ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  {disable_subscription.description}
                </ProDisplay>
              </Subtitle>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button onClick={onClose} type={Button.TYPE.SECONDARY}>
                    {disable_subscription.cancel}
                  </Button>
                </BtnContainer>
                <BtnContainer>
                  <Button onClick={handleOnClick} type={Button.TYPE.PRIMARY}>
                    {disable_subscription.confirm}
                  </Button>
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

DisableSubscriptionModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  disable_subscription: DeleteSubscriptionInterface,
  disableEmailSubscription: PropTypes.func.isRequired
};

DisableSubscriptionModal.id = modalId;

export default connect(
  state => {
    const { disable_subscription } = state.configuration;
    return {
      disable_subscription
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    disableEmailSubscription: userId => dispatch({ type: EMAIL_SUBSCRIPTION_DISABLE._REQUEST, userId })
  })
)(DisableSubscriptionModal);
