export function createAsyncActionType(section = '', type = '') {
  const action = {
    _REQUEST: `@@${section.toLowerCase()}/${type.toUpperCase()}_REQUEST`,
    _SUCCESS: `@@${section.toLowerCase()}/${type.toUpperCase()}_SUCCESS`,
    _ERROR: `@@${section.toLowerCase()}/${type.toUpperCase()}_ERROR`
  };

  return action;
}

// app
export const LOADER_OPEN = '@@global/LOADER_OPEN';
export const LOADER_CLOSE = '@@global/LOADER_CLOSE';
export const SET_TIMESTAMP = '@@global/SET_TIMESTAMP';
export const APP_MENU_TOGGLE = '@@global/APP_MENU_TOGGLE';
export const HIDE_NAVBAR = '@@global/HIDE_NAVBAR';

// modal
export const MODAL_OPEN = '@@global/MODAL_OPEN';
export const MODAL_CLOSE = '@@global/MODAL_CLOSE';

//authentication
export const AUTH_LOGIN = createAsyncActionType('global', 'AUTH_LOGIN');

// sidebar
export const SIDEBAR_OPEN = '@@global/SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = '@@global/SIDEBAR_CLOSE';
