import styled from 'styled-components/macro';

export const ZuoraContainer = styled.div`
  height: 300px;
  width: 100%;
  
  > label {
    padding-bottom: 10px;
  }
  
  @media ${props => props.theme.device.mobile} {
    height: 500px;
  }
`;

export const ZuoraPayment = styled.div.attrs(() => ({
  id: 'zuora_payment'
}))`
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  iframe {
    height: 100%;
    width: 100%;
  }
`;
