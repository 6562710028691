import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Img from 'ui/assets/img/group.png';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';

import { Container } from 'ui/gridSystem';

import { push } from 'redux-first-history';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { WelcomeInterface } from 'interface/configuration';
import routes from 'routes';
import { ContentWrapper, Image, Title, Subtitle } from '../Confirm/style';

const ConfirmFromBank = ({
  welcome,
  welcomeFromBankLink,
  pushUrl
}) => (
  <Container>
    <ContentWrapper>
      <Image src={Img} />
      <Title>
        <ProDisplay type="modalTitle">
          {welcome.title}
        </ProDisplay>
      </Title>
      <Subtitle>
        <ProDisplay configuration={{ lineHeight: 1.63 }}>
          {welcomeFromBankLink.description}
        </ProDisplay>
      </Subtitle>
      <Button
        type={Button.TYPE.PRIMARY}
        onClick={() => pushUrl(routes.mySubscription.path)}
      >
        {welcome.call_to_action}
      </Button>
    </ContentWrapper>
  </Container>
);

ConfirmFromBank.propTypes = {
  // HOC withMediaQueires

  // HOC (connect, state)
  welcome: WelcomeInterface,
  welcomeFromBankLink: WelcomeInterface,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
};

export default withMediaQueries(
  connect(
    state => {
      const { welcome, welcomeFromBankLink } = state.configuration;
      const { isFromBank } = state.signup;
      return {
        welcome,
        welcomeFromBankLink,
        isFromBank,
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
    })
  )(ConfirmFromBank)
);
