import routes from 'routes';

export function getPreviousPath(router) {
  if (router.previousLocations.length !== 1) {
    const current = router.location.pathname;
    if (current.match(/\//g).length > 3) {
      const backAll = router.previousLocations.find(
        prev =>
          prev.location.pathname.indexOf('playlists-all') > -1 ||
          prev.location.pathname.indexOf('five-minutes-all') > -1 ||
          prev.location.pathname.indexOf('package') > -1
      );
      if (backAll) {
        return backAll.location.pathname;
      }
      return routes.homepage.path;
    }
    return router.previousLocations[1].location.pathname;
  }
  return routes.homepage.path;
};