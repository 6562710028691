import { takeLatest, put, call } from 'redux-saga/effects';
import domain from 'api/domain';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import { setCallHeader } from 'api/config';
import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import { GET_DOMAIN, GET_FILTERED_DOMAIN_SETTINGS } from 'redux/actions/domain';
import {
  GET_LANDING_PAGE,
  GET_LANDINGPAGE_PRODUCTS
} from 'redux/actions/landingPage';

function* domainGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { data = [] } = {} } = yield call(domain.getDomain);

    yield put({
      type: GET_DOMAIN._SUCCESS,
      response: data[0]
    });
    setCallHeader('Domain', data[0].id);
    yield put({ type: GET_LANDING_PAGE._REQUEST });
    yield put({ type: GET_LANDINGPAGE_PRODUCTS._REQUEST });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > domain > domainGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* domainGetWatch() {
  yield takeLatest(GET_DOMAIN._REQUEST, domainGet);
}

function* getFilteredDomainSettings() {
  try {
    yield put({ type: LOADER_OPEN });
    const {data: settings} = yield call(domain.getFilteredDomainSetting);
    yield put({
      type: GET_FILTERED_DOMAIN_SETTINGS._SUCCESS,
      settings
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > app > getFilteredDomainSettings', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFilteredDomainSettingsWatch() {
  yield takeLatest(GET_FILTERED_DOMAIN_SETTINGS._REQUEST, getFilteredDomainSettings);
}

export default [domainGetWatch(), getFilteredDomainSettingsWatch()];
