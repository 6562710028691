import styled from 'styled-components/macro';

export const NumerationContainer = styled.div`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: baseline;
`;

export const Current = styled.span`
  color: ${props => props.theme.colors.white};
  margin-right: 5px;
`;
