import React from 'react';
import { LeftArrow, Icon, LeftArrowWrapper } from './style';

const CustomLeftArrow = ({ onClick, offset='0px' }) => {
  return (
    <LeftArrowWrapper offset={offset}>
      <LeftArrow onClick={onClick}>
        <Icon className="icon-btn-arrow" />
      </LeftArrow>
    </LeftArrowWrapper>
  );
};

export default CustomLeftArrow;
