export const validations = [
  { err: 'length', msg: 'essere composta da almeno 8 caratteri' },
  { err: 'capital', msg: 'contenere almeno una lettera maiuscola' },
  { err: 'letter', msg: 'contenere almeno una lettera minuscola' },
  { err: 'number', msg: 'contenere almeno un numero' },
  {
    err: 'special',
    msg:
      'contenere almeno un carattare speciale tra cui = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `'
  }
];
export const errorPwd =
  'La password deve essere composta da almeno 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero ed un carattare speciale tra cui = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `';

export const checkField = (field, pwd) => {
  switch (field) {
    case 'length':
      if (pwd && pwd.length >= 8) return true;
      break;
    case 'capital':
      if (pwd && /^(?=.*[A-Z])/g.test(pwd)) return true;
      break;
    case 'letter':
      if (pwd && /^(?=.*[a-z])/g.test(pwd)) return true;
      break;
    case 'number':
      if (pwd && /^(?=.*[0-9])/g.test(pwd)) return true;
      break;
    case 'special':
      if (pwd && /^(?=.*[*.+!\-@=$%^&(){}[\]?"#/\\,><':;|_~`])/g.test(pwd))
        return true;
      break;
    default:
      return false;
  }
  return false;
};
