import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { SIDEBAR_CLOSE } from 'redux/actions';
import { SidebarContainer, SidebarWrapper } from './style';
import BookmarksBox from '../BookmarksBox';
import NotificationsBox from '../NotificationsBox';

const Sidebar = ({ onClose, mediaIsPhone, sidebarToggle, id }) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(document.getElementsByTagName('body')[0].clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementsByTagName('body')[0].clientHeight]);

  const getChildrenById = contentId => {
    switch (contentId) {
      case 'Bookmark':
        return <BookmarksBox onClose={onClose} />;
      case 'Notification':
        return <NotificationsBox onClose={onClose} />;

      default:
        return <></>;
    }
  };

  return (
    <SidebarWrapper
      height={height}
      onClick={onClose}
      className={sidebarToggle ? 'open' : ''}
    >
      <SidebarContainer
        height={height}
        onClick={e => e.stopPropagation()}
        className={sidebarToggle ? 'open' : ''}
      >
        {getChildrenById(id)}
      </SidebarContainer>
    </SidebarWrapper>
  );
};
Sidebar.propTypes = {
  mediaIsPhone: PropTypes.bool,

  // HOC (connect, state)
  sidebarToggle: PropTypes.bool,
  id: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func
};

export default withMediaQueries(
  connect(
    state => {
      const { sidebarToggle } = state.app;
      const { id } = state.sidebar;
      return { sidebarToggle, id };
    },
    dispatch => ({
      onClose: () => dispatch({ type: SIDEBAR_CLOSE })
    })
  )(Sidebar)
);
