import PropTypes from 'prop-types';

export const SubscriptionsConfigSuccessInterface = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.array,
  end: PropTypes.string
});

export const PaymentUpdateConfigSuccessInterface = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.array,
  end: PropTypes.string
});

export const PremiumPurchaseConfigSuccessInterface = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string
});

export const DeleteSubscriptionInterface = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  cancel: PropTypes.string,
  confirm: PropTypes.string
});

export const WelcomeInterface = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  call_to_action: PropTypes.string
});

export const ConfirmPasswordInterface = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  call_to_action: PropTypes.string
});

export const ConfigTextInterface = PropTypes.shape({
  subscriptions_config_success: SubscriptionsConfigSuccessInterface,
  delete_subscription: DeleteSubscriptionInterface,
  welcome: WelcomeInterface,
  confirm_password: ConfirmPasswordInterface
});

export const ModeInterface = PropTypes.oneOf(['MANAGER', 'USER']);
