/* eslint-disable no-bitwise */
import { ENCRIPT_KEY } from 'api/config';
import CryptoJS from 'crypto-js';

export const aesEncrypt = body => {
  const message = JSON.stringify(body);

  const data = `${[...Array(16)]
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join('')}${message}`;

  const iv = [...Array(16)]
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join('');

  const cipher = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(ENCRIPT_KEY[process.env.REACT_APP_DEPLOY_ENV]),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }
  );
  return cipher.toString();
};
