import styled from 'styled-components/macro';

export const TeamsInterestContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  padding-top: 35px;
  display: flex;
  flex-wrap: wrap;
  
  @media ${props => props.theme.device.tablet} {
    padding: 35px 22px 0 22px;
    justify-content: space-between;
  }
  
  @media ${props => props.theme.device.mobile} {
    justify-content: center;
  }
`;
