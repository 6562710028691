import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { DeleteSubscriptionInterface } from 'interface/configuration';
import { EMAIL_SUBSCRIPTION_DELETE } from 'redux/actions/subscriptions';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon
} from './style';

const modalId = 'ConfirmModal';

const ConfirmModal = ({
  onClose,
  delete_subscription,
  deleteEmailSubscription,
  isFingerLearning
}) => {
  const handleOnClick = () => {
    deleteEmailSubscription(isFingerLearning);
    onClose();
  };
  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Title>
                <ProDisplay type="modalTitle">
                  {isFingerLearning
                    ? 'Sei sicuro di voler eliminare il pacchetto?'
                    : "Sei sicuro di voler eliminare l'abbonamento?"}
                </ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  {isFingerLearning
                    ? "L'eliminazione del pacchetto verrà applicata entro le successive 24 ore."
                    : "L'eliminazione dell'abbonamento verrà applicata entro le successive 24 ore."}
                </ProDisplay>
              </Subtitle>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button onClick={onClose} type={Button.TYPE.TERTIARY}>
                    {delete_subscription.cancel}
                  </Button>
                </BtnContainer>
                <BtnContainer>
                  <Button onClick={handleOnClick} type={Button.TYPE.PRIMARY}>
                    {delete_subscription.confirm}
                  </Button>
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  delete_subscription: DeleteSubscriptionInterface,
  deleteEmailSubscription: PropTypes.func.isRequired,
  isFingerLearning: PropTypes.bool
};

ConfirmModal.id = modalId;

export default connect(
  state => {
    const { delete_subscription } = state.configuration;
    const {
      payload: { isFingerLearning }
    } = state.modal;
    return {
      delete_subscription,
      isFingerLearning
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    deleteEmailSubscription: isFingerLearning =>
      dispatch({ type: EMAIL_SUBSCRIPTION_DELETE._REQUEST, isFingerLearning })
  })
)(ConfirmModal);
