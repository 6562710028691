import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { DELETE_PAYMENT_CONFIRM_ACTION } from 'redux/actions/profile';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon
} from './style';

const modalId = 'DeletePaymentConfirmActionModal';

const DeletePaymentConfirmActionModal = ({ onClose, onConfirm, paymentId }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Title>
              <ProDisplay type="modalTitle">
                Sei sicuro di voler eliminare questo metodo di pagamento?
              </ProDisplay>
            </Title>
            <ButtonsWrapper>
              <BtnContainer>
                <Button onClick={onClose} type={Button.TYPE.TERTIARY}>
                  Annulla
                </Button>
              </BtnContainer>
              <BtnContainer>
                <Button
                  onClick={() => onConfirm(paymentId)}
                  type={Button.TYPE.PRIMARY}
                >
                  Conferma
                </Button>
              </BtnContainer>
            </ButtonsWrapper>
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

DeletePaymentConfirmActionModal.propTypes = {
  // HOC (connect, state)
  paymentId: PropTypes.string.isRequired,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

DeletePaymentConfirmActionModal.id = modalId;

export default connect(
  state => ({
    paymentId: state.modal?.payload?.paymentId
  }),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    onConfirm: (paymentId = '') =>
      dispatch({ type: DELETE_PAYMENT_CONFIRM_ACTION, paymentId })
  })
)(DeletePaymentConfirmActionModal);
