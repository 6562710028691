import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';

import { MODAL_CLOSE } from 'redux/actions';
import { ADD_PARTECIPANTS, GET_USERS } from 'redux/actions/notes';
import ProDisplay from 'ui/typography/proDisplay';
import { Checkbox } from 'ui/atoms';
import { ProfilePicture } from 'ui/components';
import ScrollLoader from 'ui/atoms/ScrollLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ContentWrapper,
  Icon,
  Head,
  Results,
  UserWrapper,
  Name,
  CheckboxWrapper,
  EmptyMsg
} from './style';
import { ModalGlobalContainer } from '../Modal.style';
import Modal, { stopEvent } from '../Modal';

const modalId = 'ShareNoteModal';

const ShareNoteModal = ({
  notes,
  noteId,
  onClose,
  addPartecipants,
  users,
  getAllUsers,
  nextPagePresent,
  nextPage
}) => {
  const [editedNote, setEditedNote] = useState();
  const [usersConfirmed, setUsersConfirmed] = useState([]);
  // const [search, setSearch] = useState('');

  useEffect(() => {
    if (notes) {
      setEditedNote(notes.find(nt => nt.id === noteId));
      if (notes.find(nt => nt.id === noteId)?.users) {
        setUsersConfirmed(notes.find(nt => nt.id === noteId)?.users);
      }
      if (notes.find(nt => nt.id === noteId)?.partecipants) {
        setUsersConfirmed(notes.find(nt => nt.id === noteId)?.partecipants);
      }
    }
  }, [noteId, notes]);

  const fetchData = () => {
    getAllUsers(nextPage);
  };

  const handleUser = user => {
    const index = usersConfirmed.findIndex(u => u.userId === user.userId);
    const newUsers = [...usersConfirmed];
    if (index < 0) {
      newUsers.push(user);
    } else {
      newUsers.splice(index, 1);
    }
    setUsersConfirmed(newUsers);
  };

  const onSaveNote = () => {
    addPartecipants(
      editedNote.id,
      usersConfirmed.map(user => user.userId)
    );
    setUsersConfirmed([]);
    onClose();
  };

  return (
    <Modal id={modalId} onClose={onSaveNote}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="478px"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onSaveNote} />
              <Head>
                <Title>
                  <ProDisplay type="modalTitle">Condividi</ProDisplay>
                </Title>
              </Head>
              <Results id="scrollableDiv">
                <InfiniteScroll
                  dataLength={users?.length}
                  height={300}
                  next={fetchData}
                  hasMore={nextPagePresent}
                  loader={<ScrollLoader />}
                  scrollableTarget="scrollableDiv"
                >
                  {users?.length > 0 ? (
                    users.map(user => (
                      <UserWrapper key={user.userId}>
                        <ProfilePicture
                          profile={{
                            pictureId: user.imageUrl,
                            firstname: user.givenName,
                            lastname: user.familyName
                          }}
                          widthContainer="35px"
                          heightContainer="35px"
                          fontSizeInitials={14}
                        />
                        <Name>
                          <ProDisplay type="nameUsers">
                            {user.givenName} {user.familyName}
                          </ProDisplay>
                        </Name>
                        <CheckboxWrapper>
                          <Checkbox
                            checked={
                              !!usersConfirmed?.find(
                                u => u.userId === user.userId
                              )
                            }
                            onChange={() => handleUser(user)}
                            rounded
                            disabled={
                              !!usersConfirmed?.find(
                                u => u.userId === user.userId
                              )
                            }
                          />
                        </CheckboxWrapper>
                      </UserWrapper>
                    ))
                  ) : (
                    <EmptyMsg>
                      <ProDisplay type="1">
                        Non sono al momento presenti utenti con cui condividere
                        la nota.
                      </ProDisplay>
                    </EmptyMsg>
                  )}
                </InfiniteScroll>
              </Results>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ShareNoteModal.propTypes = {
  // HOC (connect, state)
  notes: PropTypes.array,
  noteId: PropTypes.string,
  users: PropTypes.array,
  nextPagePresent: PropTypes.bool,
  nextPage: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  addPartecipants: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func
};

ShareNoteModal.id = modalId;

export default connect(
  state => {
    const { noteId } = state.modal.payload;
    const { notes } = state.notes.notes;
    const { users } = state.notes;
    const { nextPagePresent, nextPage } = state.social;
    return { noteId, users, notes, nextPagePresent, nextPage };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    getAllUsers: (nextPage = null) =>
      dispatch({ type: GET_USERS._REQUEST, nextPage }),
    addPartecipants: (noteId, body) =>
      dispatch({ type: ADD_PARTECIPANTS._REQUEST, noteId, body })
  })
)(ShareNoteModal);
