import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';

import Img from 'ui/assets/img/group.png';
import { ModalWrapper } from '../InviteFriendModal/style';
import { Title, Subtitle, ContentWrapper, Icon, Image, Head } from './style';

const modalId = 'SuccessModal';

const SuccessModal = ({ pushUrl, onClose, title, description, redirectTo }) => {
  const CloseAndChangeLocation = () => {
    onClose();
    if (redirectTo) pushUrl(redirectTo);
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={CloseAndChangeLocation} />
              <Image src={Img} />
              {title ? (
                <Title>
                  <ProDisplay type="modalTitle">{title}</ProDisplay>
                </Title>
              ) : null}
              {description ? (
                <Subtitle>
                  <ProDisplay
                    configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                  >
                    <Head>{description}</Head>
                  </ProDisplay>
                </Subtitle>
              ) : null}
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

SuccessModal.propTypes = {
  // HOC (connect, state)
  title: PropTypes.string,
  description: PropTypes.string,
  redirectTo: PropTypes.string,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

SuccessModal.id = modalId;

export default connect(
  state => {
    const { payload: { title, description, redirectTo } = {} } = state.modal;
    return {
      redirectTo,
      title,
      description
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    pushUrl: url => dispatch(push(url))
  })
)(SuccessModal);
