import styled, { css } from 'styled-components/macro';

const getFirstSkew = percent => {
  const deg = `${92 - (percent * 360) / 100}deg`;
  return css`
    transform: rotate(92deg) skew(${deg});
  `;
};

const getSecondSkew = percent => {
  if (percent <= 50)
    return css`
      transform: rotate(-90deg) skew(0);
      bottom: 0;
    `;
  const deg = `${90 - ((percent - 50) * 360) / 100}deg`;
  return css`
    transform: rotate(-90deg) skew(${deg});
  `;
};

export const Container = styled.div`
  width: 58px;
  height: 58px;
  margin-top: 9px;
`;

export const OuterCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  ${props =>
    props.percentage === 0
      ? css`
          background-color: ${props.theme.colors.white};
        `
      : css`
          background-image: linear-gradient(
            ${props.deg},
            ${props.color1},
            ${props.color2}
          );
        `}

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const FirstSector = styled.div`
  background-color: white !important;
  transform-origin: bottom left;
  transform: rotate(92deg) skew(0deg);
  position: absolute;
  width: 5000px;
  height: 5000px;
  left: 50%;
  bottom: 50%;
  ${props => getFirstSkew(props.percentage)};
`;

export const SecondSector = styled(FirstSector)`
  ${props => getSecondSkew(props.percentage)};
  /* transform: rotate(-90deg) skew(89deg); */
`;

export const InnerCircle = styled.div`
  width: 85%;
  height: 85%;
  border-radius: 100%;
  background-color: ${props =>
    props.bgColorLighter
      ? props.theme.colors.primary.lighter
      : props.theme.colors.primary.base};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
