import styled from 'styled-components/macro';

export const TableCardMobileContainer = styled.div`
  width: 100%;
  border-radius: 14px;
  background-color: ${props => props.theme.colors.ghostWhite};
  padding: 0 20px 20px;
  position: relative;
  box-shadow: -4px 6px 12px rgba(0, 0, 0, 0.08);

  // &.active {
  //   background-color: ${props => props.theme.colors.white};
  //   box-shadow: 0 2px 55px 0 rgba(171, 171, 171, 0.5);

  //   input[type='text'] {
  //     margin: 0;
  //   }
  // }

  // &.deleted {
  //   opacity: 0.36;
  // }

  // &.new-user {
  //   padding-top: 24px;
  // }

  .text-brand {
    color: ${props => props.theme.colors.primary.base};
  }
`;

export const Field = styled.div`
  padding-bottom: 15px;
`;

export const Label = styled.div`
  padding-bottom: 3px;
  color: ${props => props.theme.colors.mineShaft};
  margin-top: 6px;
`;

export const WrapperButton = styled.div`
  @media ${props => props.theme.device.tablet} {
    position: absolute;
    right: 20px;
    letter-spacing: normal;
  }

  @media ${props => props.theme.device.mobile} {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 0px;
    opacity: 0.9;
    width: 60%;
  }
`;

export const AccordionWrapper = styled.div`
  margin-bottom: 10px;
`;

export const WrapperIconMobile = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;
  right: 0;
`;

export const ButtonWrapperEditMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionRow = styled.div`
  /* width: 100%;
  height: fit-content;
  padding: 0 8px;
  background-color: ${props => props.theme.colors.ghostWhite}; */
  &.deleted {
    opacity: 0.36;
  }
`;

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  & > div:first-child {
    max-width: 72%;
  }
  & > div:last-child {
    width: 24%;
  }
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
`;

export const BtnWrapper = styled.div`
  width: 95px;
`;
