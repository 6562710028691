import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import {
  ModalGlobalContainer,
  ModalWrapper
} from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';

import Img from 'ui/assets/img/group.png';
import { SubscriptionsConfigSuccessInterface } from 'interface/configuration';
import routes from 'routes';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Image,
  IconCheck,
  Head,
  Bottom
} from './style';

const modalId = 'SubscriptionModal';

const SubscriptionModal = ({
  pushUrl,
  onClose,
  subscriptions_config_success,
  cb,
  isPremiumPurchase
}) => {
  const CloseAndChangeLocation = () => {
    onClose();
    if (cb) cb();
    pushUrl(routes.mySubscription.path);
  };

  return (
    <Modal id={modalId} onClose={CloseAndChangeLocation}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={CloseAndChangeLocation} />
              <Image src={Img} />
              <Title>
                <ProDisplay type="modalTitle">
                  {isPremiumPurchase
                    ? 'Il piano formativo dei tuoi collaboratori è stato aggiornato! '
                    : subscriptions_config_success.title}
                </ProDisplay>
              </Title>
              <Subtitle>
                <ProDisplay
                  configuration={{ lineHeight: 1.5, letterSpacing: '0.25px' }}
                >
                  <Head>
                    {isPremiumPurchase
                      ? "A breve i tuoi colleghi riceveranno una mail di conferma per continuare l'esperienza formativa su Skills4Capital.  Ti ricordiamo che se hai scelto l’addebito su conto corrente dovrai attendere pochi giorni per permettere l’addebito e l’effettiva attivazione del servizio."
                      : subscriptions_config_success.description}
                  </Head>
                  {!isPremiumPurchase &&
                    (subscriptions_config_success?.list || []).map(
                      (item, index) => (
                        <div key={`check-${index}`}>
                          <IconCheck className="icon-check" />
                          {item}
                        </div>
                      )
                    )}
                  <Bottom>
                    {!isPremiumPurchase && subscriptions_config_success.end}
                  </Bottom>
                </ProDisplay>
              </Subtitle>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

SubscriptionModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  subscriptions_config_success: SubscriptionsConfigSuccessInterface,
  pushUrl: PropTypes.func.isRequired,
  cb: PropTypes.func,
  isPremiumPurchase: PropTypes.bool
};

SubscriptionModal.id = modalId;

export default connect(
  state => {
    const { subscriptions_config_success } = state.configuration;
    return {
      subscriptions_config_success,
      cb: state.modal.payload?.cb
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    pushUrl: url => dispatch(push(url))
  })
)(SubscriptionModal);
