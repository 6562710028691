import styled, { css } from 'styled-components';

export const SelectInput = styled.select`
  position: absolute;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.transparent};
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 12px 0 0 20px;
  transition: all 125ms ease-in;
  appearance: none;
  text-indent: unset;

  ${props =>
    props.disabled
    && css`
      background-color: rgba(232, 232, 234, 0.2);
    `};

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.azureDark};
    outline: 0;
    box-shadow: 0 0 0 0.17647rem
      ${props => props.theme.mixin.rgba(props.theme.colors.azureDark, 0.2)};
  }

  ${props =>
    props.error
    && css`
      border-color: ${!props.focus && props.theme.colors.redError};
      background-color: ${!props.focus
        && props.theme.mixin.rgba(props.theme.colors.redError, 0.21)};
      color: ${!props.focus && props.theme.colors.redError};
    `};
`;

export const Icon = styled.span`
  position: absolute;
  top: 13px;
  right: 10px;
  font-size: 30px;
  transform: rotate(90deg);
  z-index: 0;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;

  ${props =>
    props.focus
    && css`
      transform: rotate(270deg);
    `};
`;

export const Option = styled.option``;
