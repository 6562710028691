import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Col, Container, Row } from 'ui/gridSystem';
import { SubTitle, Title } from 'pages/Signup/steps/UserAccount/style';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { PaymentMethod } from 'ui/components';
import { Description } from 'pages/Signup/steps/Billings/style';
import Amex from 'ui/atoms/IconCreditCard/Amex';
import MasterCard from 'ui/atoms/IconCreditCard/MasterCard';
import Visa from 'ui/atoms/IconCreditCard/Visa';
import { withMediaQueries } from 'hoc/withMediaQueries';

import { Data } from 'pages/PremiumPurchase/steps/Billings/style';

import Button from 'ui/atoms/Button';
import routes from 'routes';
import { BILLING_GET_PAYMENT_METHODS, BILLINGS_ZUORA_PAYMENT_METHOD_ADD } from 'redux/actions/billings';
import PaymentUpdateModal from 'ui/components/Modals/PaymentUpdateModal';
import DeletePaymentWarningModal from 'ui/components/Modals/DeletePaymentWarningModal';
import DeletePaymentConfirmActionModal from 'ui/components/Modals/DeletePaymentConfirmActionModal';
import { HeadWrapper, Head, Logo } from 'pages/LandingPage/style';
import { ButtonWrapper, Foot, FooterContainer } from '../EditData/style';
import { EditBillingsContainer, Text, Name, Card, IconCard } from './style';
import CreditCardZuora from '../../../../ui/components/BillingMethods/CreditCardZuora';
import SEPAZuora from '../../../../ui/components/BillingMethods/SEPAZuora';

const EditCards = ({
  mediaIsPhone, pushUrl, getPaymentMethods, payment_methods,
  addZuoraPaymentMethod, company, domain,
}) => {
  const [paymentType, setPaymentType] = useState('');

  useEffect(() => {
    if (!payment_methods?.length) {
      getPaymentMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleOnSubmit = () => {
    pushUrl(routes.managerProfile.path);
  };

  const getTitleCard = card_info => {
    const {
      type, creditCardType = '', creditCardExpirationYear, creditCardExpirationMonth,
      bankTransferAccountNumberMask, creditCardMaskNumber, bankTransferAccountName, creditCardHolderName,
    } = card_info;
    const expiration = creditCardExpirationMonth && creditCardExpirationYear ? `${creditCardExpirationMonth}/${creditCardExpirationYear}` : '';
    const name = bankTransferAccountName || creditCardHolderName;
    let card_brand = '';
    let card_icon;
    switch (creditCardType.toLowerCase()) {
      case 'AmericanExpress':
        card_brand = 'American Express';
        card_icon = <Amex />;
        break;
      case 'mastercard':
        card_brand = 'MasterCard';
        card_icon = <MasterCard />;
        break;
      case 'visa':
        card_brand = 'Visa';
        card_icon = <Visa />;
        break;
      default:
        break;
    }
    return (
      <Card>
        <Name>
          {card_icon && <IconCard>{card_icon}</IconCard>}
          {type === 'CreditCard'
            ? `${card_brand} termina con ${creditCardMaskNumber.slice(creditCardMaskNumber.length - 4)}`
            : `IBAN termina con ${bankTransferAccountNumberMask.slice(bankTransferAccountNumberMask.length - 4)}`}
        </Name>
        <Data>
          <ProText configuration={{ fontSize: '12' }}>{name}</ProText>
          {expiration && (
            <ProText configuration={{ fontSize: '12' }}>{expiration}</ProText>
          )}
        </Data>
      </Card>
    );
  };

  const handleCallbackErrorZuora = (errorMessage) => {
    // const payload = {
    //   errorTitle: 'Attenzione, metodo di pagamento non valido',
    //   errorText:
    //     'Inserisci un metodo di pagamento valido per poter concludere l’acquisto'
    // };
    // openModalError(payload);
    process.env.REACT_APP_DEPLOY_ENV !== 'PROD' && console.log('Zuora error ', errorMessage);
  };

  const handleCallbackSuccessZuora = (payment_method_id) => {
    addZuoraPaymentMethod(payment_method_id);
    setPaymentType('');
  };

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo
              onClick={() => pushUrl(routes.landingPage.path)}
              img={domain?.brand?.logo_white}
            />
          </Head>
        </Container>
      </HeadWrapper>
      <Container>
        <Row>
          <EditBillingsContainer>
            <Col md={10} sm={12}>
              <div>
                <Title>
                  <ProDisplay
                    type="title"
                    configuration={
                      mediaIsPhone ? { fontSize: '24', lineHeight: 1.22 } : null
                    }
                  >
                    Pagamento
                  </ProDisplay>
                </Title>
                <SubTitle>
                  <ProText type="description">
                    Modifica o aggiungi metodo di pagamento predefinito
                  </ProText>
                </SubTitle>
              </div>
              {payment_methods?.length ? (
                <>
                  <Text>
                    <ProText type="4" configuration={{ fontSize: '12' }}>
                      I tuoi metodi di pagamento
                    </ProText>
                  </Text>
                  {(payment_methods || []).map((payment_method = {}) => (
                    <PaymentMethod
                      noCheckbox
                      paymentId={payment_method.id}
                      key={`payment-${payment_method.id}`}
                      title={getTitleCard(payment_method)}
                      actionable={((payment_methods?.length || 0) > 1) && !payment_method.defaultPaymentMethod}
                      type={payment_method?.type}
                    />
                  ))}
                </>
              ) : null}
              <>
                <Text>
                  <ProText type="4" configuration={{ fontSize: '12' }}>
                    Altri metodi di pagamento
                  </ProText>
                </Text>
                {/****** Payment Method Zuora Credit Card ******/}
                <PaymentMethod
                  key="zuora_credit-card"
                  title="Carta di credito"
                  isOpen={paymentType === 'zuora_credit-card'}
                  onSelect={() => setPaymentType('zuora_credit-card')}
                  isCCZuora
                >
                  <Description>
                    <ProText type="description" configuration={{ fontWeight: 300 }}>
                      Trasferimento di denaro sicuro tramite il tuo conto bancario.
                    </ProText>
                  </Description>
                  {paymentType === 'zuora_credit-card'
                  && <CreditCardZuora callbackError={(error) => handleCallbackErrorZuora(error)} callbackSuccess={(payment_method_id) => handleCallbackSuccessZuora(payment_method_id)} />}
                </PaymentMethod>
                {/****** Payment Method Zuora SEPA  ******/}
                <PaymentMethod
                  key="zuora_sepa"
                  title="Addebito su conto corrente"
                  isOpen={paymentType === 'zuora_sepa'}
                  onSelect={() => setPaymentType('zuora_sepa')}
                  isSEPAZuora
                >
                  <Description>
                    <ProText type="description" configuration={{ fontWeight: 300 }}>
                      Attiva il pagamento pre-autorizzato in forma automatica sul tuo
                      conto corrente (SDD), il modo più semplice per gestire i tuoi
                      pacchetti
                    </ProText>
                  </Description>
                  {paymentType === 'zuora_sepa'
                  && <SEPAZuora callbackError={(error) => handleCallbackErrorZuora(error)} callbackSuccess={(payment_method_id) => handleCallbackSuccessZuora(payment_method_id)} />}
                </PaymentMethod>
              </>
            </Col>
          </EditBillingsContainer>
        </Row>
      </Container>
      <FooterContainer>
        <Container>
          <Foot>
            <ButtonWrapper>
              {/* <Button
                text="Annulla"
                type={Button.TYPE.SECONDARY}
                onClick={() => pushUrl(routes.managerProfile.path)}
              /> */}
            </ButtonWrapper>
            <ButtonWrapper id="payment-confirmBtn">
              <Button
                text="Chiudi"
                type={Button.TYPE.PRIMARY}
                onClick={handleOnSubmit}
              />
            </ButtonWrapper>
          </Foot>
        </Container>
      </FooterContainer>
      <PaymentUpdateModal />
      <DeletePaymentConfirmActionModal />
      <DeletePaymentWarningModal />
    </>
  );
};

EditCards.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  //HOC (connect, state)
  payment_methods: PropTypes.array.isRequired,
  company: PropTypes.object,
  domain: PropTypes.object,

  //HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  addZuoraPaymentMethod: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { payment_methods } = state.billings;
    const { selected_payment, company } = state.profile;
    return {
      company,
      payment_methods,
      selected_payment,
      domain: state.domain,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getPaymentMethods: () => dispatch({ type: BILLING_GET_PAYMENT_METHODS._REQUEST }),
    addZuoraPaymentMethod: payment_method_id => dispatch({ type: BILLINGS_ZUORA_PAYMENT_METHOD_ADD._REQUEST, payment_method_id }),
  })
)(withMediaQueries(EditCards));
