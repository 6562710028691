import styled from 'styled-components/macro';

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 35px 0px;
`;

const Icon = styled.span`
  transform: rotate(180deg);
  font-size: 30px;
  margin-right: 12px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.darker};
`;

const Title = styled.div`
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  padding-left: 17px;
`;

const IconViews = styled.span`
  font-size: 22px;
  margin-left: 18px;
`;

const Text = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  margin-right: 40px;

  @media ${props => props.theme.device.mobile} {
    margin-right: 0px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 4px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  height: 50px;
  padding: 0px 20px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;

  > span {
    @media ${props => props.theme.device.mobile} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 130px;
    }
  }
`;

const ListNumber = styled.div`
  min-width: 19px;
  min-height: 19px;
  border-radius: 10px;
  border: solid 1px ${props => props.theme.colors.primary.base};
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.mineShaft};
  margin-right: 40px;
`;

const Number = styled.div`
  margin-left: 12px;
`;

const IntestationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;

export {
  NavWrapper,
  Icon,
  Title,
  Text,
  IconViews,
  ContentWrapper,
  Content,
  ListContainer,
  ListNumber,
  ResultContainer,
  Number,
  IntestationWrapper
};
