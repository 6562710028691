import React from 'react';
import { Boul1Ph, Boul2Ph, PlaceHolder, Rect1Ph, Rect2Ph } from './style';

const Placeholder = () => (
  <PlaceHolder>
    <Rect1Ph />
    <Rect2Ph />
    <Boul1Ph />
    <Boul2Ph />
  </PlaceHolder>
);

export default Placeholder;
