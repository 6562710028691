import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
`;

export const Title = styled.div`
  margin-bottom: 14px;
  width: 70%;
  text-align: center;

  @media ${props => props.theme.device.mobile} {
    width: 80%;
  }
`;

export const Subtitle = styled.div`
  width: 70%;
  margin-bottom: 35px;
  text-align: center;
`;

export const Image = styled.img`
  width: 270px;
  margin-bottom: 16px;

  @media ${props => props.theme.device.mobile} {
    width: 188px;
  }
`;

export const BtnContainer = styled.div`
  width: 266px;

  @media ${props => props.theme.device.mobile} {
    width: 250px;
  }
`;
