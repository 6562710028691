import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Col, Container, Row } from 'ui/gridSystem';
import validator from 'utils/regex-validator';

import { Button, CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { Footer } from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import routes from 'routes';
import { USER_RESET_PASSWORD } from 'redux/actions/landingPage';
import { Head, HeadWrapper, Logo } from 'pages/LandingPage/style';
import { CloseWrapper, Close } from 'pages/LandingPage/sections/Access/style';
import getQueryParams from 'utils/getQueryParams';
import {
  ResetPasswordContainer,
  Text,
  Title,
  Body,
  ButtonWrapper,
  InputWrapper
} from './style';

const ResetPassword = ({
  mediaIsPhone,
  pushUrl,
  userResetPassword,
  headerFooterVisible,
  domain
}) => {
  const [email, setEmail] = useState('');

  const redirectHomepage = () => {
    pushUrl(routes.landingPage.path);
  };

  const handleResetPassword = () => {
    userResetPassword(email);
  };

  const queryEmail = getQueryParams('email');
  useEffect(() => {
    setEmail(queryEmail);
  }, [queryEmail]);

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={domain?.brand?.logo_white} />
            <CloseWrapper onClick={() => pushUrl(routes.access.path)}>
              <Close className="icon-close-x" />
            </CloseWrapper>
          </Head>
        </Container>
      </HeadWrapper>
      <Container>
        <Row>
          <ResetPasswordContainer>
            <Col sm={12}>
              {headerFooterVisible && (
                <Head>
                  <Logo onClick={redirectHomepage} img={domain?.brand?.logo} />
                </Head>
              )}
            </Col>
            <Col xl={5} lg={6} md={7} sm={12}>
              <Body>
                <Title>
                  {mediaIsPhone ? (
                    <ProDisplay type="title" configuration={{ fontSize: '20' }}>
                      Reimposta la password
                    </ProDisplay>
                  ) : (
                    <ProDisplay
                      type="title"
                      configuration={{ fontWeight: '400' }}
                    >
                      Reimposta la password
                    </ProDisplay>
                  )}
                </Title>
                <Text>
                  {mediaIsPhone ? (
                    <ProText
                      type="description"
                      configuration={{ fontSize: '12' }}
                    >
                      Inserisci la tua e-mail. Ti invieremo una e-mail di
                      conferma con il link per ripristinare la password.
                    </ProText>
                  ) : (
                    <ProText type="description">
                      Inserisci la tua e-mail. Ti invieremo una e-mail di
                      conferma con il link per ripristinare la password.
                    </ProText>
                  )}
                </Text>
                <InputWrapper>
                  <CustomInput
                    type="email"
                    placeholder="Indirizzo email"
                    onChange={value => setEmail(value)}
                    error={email && !validator.email(email)}
                    value={email}
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <Button
                    on
                    type={Button.TYPE.PRIMARY}
                    onClick={handleResetPassword}
                    text="Invia"
                    disabled={!validator.email(email)}
                  />
                </ButtonWrapper>
              </Body>
            </Col>
          </ResetPasswordContainer>
        </Row>
      </Container>
      {headerFooterVisible && <Footer />}
    </>
  );
};

ResetPassword.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (connect, state)
  domain: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  userResetPassword: PropTypes.func.isRequired
};

export default connect(
  state => ({
    domain: state.domain
  }),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    userResetPassword: email =>
      dispatch({ type: USER_RESET_PASSWORD._REQUEST, email })
  })
)(withMediaQueries(ResetPassword));
