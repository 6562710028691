import styled from 'styled-components/macro';

export const SuggestionsWrapper = styled.div`
  padding: ${props => (props.padding ? props.padding : 0)}px;
  padding-top: 20px;
`;
export const Suggestion = styled.div`
  color: ${({ compiled, theme }) =>
    compiled ? theme.colors.black : theme.colors.slateGray};
  padding-bottom: 12px;
  width: 100%;
  display: flex;
`;

export const SuggestionItem = styled.div``;

export const TitleSuggestion = styled.div`
  color: ${props => props.theme.colors.black};
  padding: 0.7rem 0;
`;

export const IconCheck = styled.div`
  margin-right: 5px;
  padding-top: 2px;
  color: ${({ valid, compiled, theme }) =>
    !compiled
      ? theme.colors.slateGray
      : valid === true
      ? theme.colors.primary.base
      : theme.colors.redError};
`;
