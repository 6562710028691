import { createAsyncActionType } from './index';

export const PROFILE_DATA_ACCOUNT_GET = createAsyncActionType(
  'profile',
  'PROFILE_DATA_ACCOUNT_GET'
);

export const PROFILE_DATA_PAYMENTS_GET = createAsyncActionType(
  'profile',
  'PROFILE_DATA_PAYMENTS_GET'
);

export const PROFILE_DATA_COMPANY_GET = createAsyncActionType('profile', 'PROFILE_DATA_COMPANY_GET');
export const PROFILE_DATA_COMPANY_SET = '@@profile/PROFILE_DATA_COMPANY_SET';

export const PROFILE_DATA_COMPANY_UPDATE = createAsyncActionType(
  'profile',
  'PROFILE_DATA_COMPANY_UPDATE'
);

export const PROFILE_ASSOC_SUBSCRIPTION_PAYMENT = createAsyncActionType(
  'profile',
  'PROFILE_ASSOC_SUBSCRIPTION_PAYMENT'
);

export const PROFILE_DATA_INITIAL_STATE =
  '@@profile/PROFILE_DATA_INITIAL_STATE';
export const PROFILE_SELECTED_DATA_PAYMENT =
  '@@profile/PROFILE_SELECTED_DATA_PAYMENT';

export const PROFILE_USER_SET = '@@profile/PROFILE_USER_SET';
export const PROFILE_USER_PICTURE_SET = '@@profile/PROFILE_USER_PICTURE_SET';

export const DELETE_PAYMENT = '@@profile/DELETE_PAYMENT';
export const DELETE_PAYMENT_CONFIRM_ACTION =
  '@@profile/DELETE_PAYMENT_CONFIRM_ACTION';

export const PROFILE_GET_DATA_PRIVACY = createAsyncActionType(
  'profile',
  'PROFILE_GET_DATA_PRIVACY'
);

export const PROFILE_POST_DATA_PRIVACY = createAsyncActionType(
  'profile',
  'PROFILE_POST_DATA_PRIVACY'
);
