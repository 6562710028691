import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

const MenuContainer = styled.div
// .attrs(() => ({className: 'menu'}))
`
  border-bottom: 1px solid ${props => props.theme.colors.filterColor};
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.transparent};
  color: ${props => props.theme.colors.greenDark};

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    font-size: 24px;
    padding-top: 18px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .items-list-item {
    position: relative;
    flex: 1 1 auto;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    margin-right: 30px;
    color: ${props => props.theme.colors.darkCharcoal};

    @media ${props => props.theme.device.laptop} {
      margin-right: 43px;
    }

    @media ${props => props.theme.device.tablet} {
      width: 33.3%;
      margin-right: 0;
      text-align: center;
    }

    @media ${props => props.theme.device.mobile} {
      display: inline-block;
      text-align: center;
      width: 100%;
      margin-right: 19px;
    }

    &.active {
      border-bottom: 3px solid ${props => props.theme.colors.primary.base};
      color: ${props => props.theme.colors.primary.base};
      padding-bottom: 8px;
      margin-bottom: -1px;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &.notification {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        top: 10px;
        right: -10px;
        background-color: ${props => props.theme.colors.primary.base};
        border-radius: 100%;
      }
    }
  }

  @media ${props => props.theme.device.mobile} {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    ${customScrollbar()}
  }
`;

const PriceWrapper = styled.div`
  width: 30%;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  align-self: flex-start;

  @media ${props => props.theme.device.laptop} {
    flex-direction: column;
    align-items: flex-end;
    width: 15%;
  }

  @media ${props => props.theme.device.tablet} {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  @media ${props => props.theme.device.tablet} {
    height: 60px;
    border: none;

    :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      /* background-color: ${props => props.theme.colors.filterColor}; */
      top: 127px;
      left: 0;
    }
  }
`;

const Price = styled.div`
  font-weight: bold;
  font-size: 26px;
  color: black;
  width: fit-content;
  text-align: right;

  @media ${props => props.theme.device.tablet} {
    padding-right: 22px;
  }
`;

const Text = styled.div`
  width: fit-content;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${props => props.theme.colors.mineShaft};
  padding-right: 17px;

  @media ${props => props.theme.device.laptop} {
    padding-right: 0;
  }

  @media ${props => props.theme.device.tablet} {
    padding-left: 22px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 22px;
  justify-content: space-between;

  @media ${props => props.theme.device.laptop} {
    align-items: flex-end;
  }
  @media ${props => props.theme.device.tablet} {
    flex-direction: column-reverse;
    padding-top: 0;
  }
`;

const Notification = styled.div`
  position: absolute;
  top: 20%;
  right: 34%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary.darker};
`;

export {
  MenuContainer,
  ItemList,
  PriceWrapper,
  Text,
  Price,
  Wrapper,
  Notification
};
