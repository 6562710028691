import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import { GET_BOOKMARKS, REMOVE_BOOKMARK_BY_ID } from 'redux/actions/social';
import { detailType } from 'utils/common';
import routes from 'routes';
import { push } from 'redux-first-history';
import { GET_PLAYLIST, SET_PLAYLIST_HISTORY } from 'redux/actions/playlist';
import {
  Head,
  Title,
  Icon,
  BmContainer,
  Image,
  BmMain,
  BmTitle,
  BmDuration,
  IconPlay,
  IconClock,
  IconBookmark,
  EmptyMsg
} from './style';

const BookmarksBox = ({
  onClose,
  // HOC (connect, state)
  bookmarks,
  playlistId,
  router,
  // HOC (connect, dispatch)
  cleanPlaylist,
  getBookmarks,
  pushUrl,
  removeBookmarkById,
  setPlaylistHistory,
}) => {
  useEffect(() => {
    getBookmarks();
  }, [getBookmarks]);

  const changeLocation = bm => {
    onClose();
    if (playlistId === bm.itemId) return;
    cleanPlaylist();
    setPlaylistHistory(router.location.pathname);
    const type = bm.type ? bm.type.toLowerCase() : bm.toLowerCase();
    switch (type) {
      case detailType.NEWS:
        pushUrl(`${routes.detail.path}/${detailType.NEWS}/${bm.itemId}`);
        break;
      case 'collection':
      case 'selection':
        pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${bm.itemId}`);
        break;
      case 'learning_object':
      case 'training':
        pushUrl(`${routes.detail.path}/${detailType.COURSE}/${bm.itemId}`);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Head>
        <Title>
          <ProText type="sidebarTitle">Bookmark</ProText>
        </Title>
        <Icon className="icon-close-x" onClick={onClose} />
      </Head>
      {bookmarks?.length === 0 ? (
        <EmptyMsg>
          <ProDisplay type="1">Non ci sono elementi salvati</ProDisplay>
        </EmptyMsg>
      ) : (
        bookmarks?.map(bm => (
          <BmContainer key={bm.bookId} onClick={() => changeLocation(bm)}>
            <Image src={bm.imageUrl} />
            <BmMain>
              <BmTitle>
                <ProDisplay type="titleCollectionItem">
                  {bm.title.substring(0, 90)}
                  {bm.title.length > 90 && '...'}
                </ProDisplay>
              </BmTitle>
              <BmDuration>
                {Math.round(bm.duration) > 0 && (
                  <>
                    {bm.format === 'text' ? (
                      <IconClock className="icon-clock" />
                    ) : (
                      <IconPlay className="icon-play" />
                    )}
                    <ProText type="duration">
                      {Math.round(bm.duration / 60)} min
                    </ProText>
                  </>
                )}
                {Math.round(bm.duration) > 0 && bm.completed === 'true' && (
                  <ProText type="duration"> - </ProText>
                )}
                {bm.completed === 'true' && (
                  <ProText type="duration"> Completato</ProText>
                )}
              </BmDuration>
            </BmMain>
            <IconBookmark
              className="icon-icona-salva-attivo"
              onClick={() => {
                removeBookmarkById({
                  bookId: bm.bookId,
                  itemId: bm.itemId
                });
              }}
            />
          </BmContainer>
        ))
      )}
    </>
  );
};
BookmarksBox.propTypes = {
  // HOC (connect, state)
  bookmarks: PropTypes.array,
  playlistId: PropTypes.string,
  router: PropTypes.object,

  // HOC (connect, dispatch)
  cleanPlaylist: PropTypes.func,
  getBookmarks: PropTypes.func,
  onClose: PropTypes.func,
  pushUrl: PropTypes.func,
  removeBookmarkById: PropTypes.func,
};

export default withMediaQueries(
  connect(
    state => {
      const { bookmarklist: bookmarks } = state.social.bookmarks;
      const { itemid: playlistId } = state.playlist.playlistSelected;
      const { router } = state;
      return { bookmarks, playlistId, router };
    },
    dispatch => ({
      cleanPlaylist: () => dispatch({ type: GET_PLAYLIST._SUCCESS, data: {} }),
      getBookmarks: () => dispatch({ type: GET_BOOKMARKS._REQUEST }),
      pushUrl: url => dispatch(push(url)),
      removeBookmarkById: payload =>
        dispatch({ type: REMOVE_BOOKMARK_BY_ID._REQUEST, payload }),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
    })
  )(BookmarksBox)
);
