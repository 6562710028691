import styled from 'styled-components/macro';

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 35px 0px;
`;

const Icon = styled.span`
  transform: rotate(180deg);
  font-size: 30px;
  margin-right: 12px;
  color: ${props => props.theme.colors.primary.darker};
  cursor: pointer;
`;

const Title = styled.div`
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  padding-left: 17px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchContainer = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.azureDark};

  @media ${props => props.theme.device.mobile} {
    flex-wrap: wrap;
    justify-content: normal;
    width: 100%;
    height: auto;
  }
`;

const IconSwitch = styled.span`
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.darker};
`;

const IconSearch = styled.span`
  font-size: 22px;
  margin-left: 18px;
  cursor: pointer;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;

  @media ${props => props.theme.device.mobile} {
    justify-content: center;
  }
`;

const UserCardContainer = styled.div`
  &:not(:nth-child(3n)) {
    margin-right: 20px;

    @media ${props => props.theme.device.tablet} {
      margin-right: 0px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    &:not(:nth-child(2n)) {
      margin-right: 20px;
    }
  }

  @media ${props => props.theme.device.mobile} {
    &:not(:nth-child(2n)) {
      margin-right: 0;
    }
  }
`;

const UserListContainer = styled.div``;

export {
  NavWrapper,
  Icon,
  Title,
  IconSwitch,
  SearchContainer,
  SearchWrapper,
  IconSearch,
  CardWrapper,
  UserCardContainer,
  UserListContainer
};
