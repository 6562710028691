import PropTypes from 'prop-types';

export const ContentInterface = PropTypes.shape({
  completed: PropTypes.number,
  total: PropTypes.number,
  completed_obligatory: PropTypes.number,
  total_obligatory: PropTypes.number,
  percentage: PropTypes.number,
  percentage_obligatory: PropTypes.number
});

export const CollaboratorInterface = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  profile_image: PropTypes.string,
  comments: PropTypes.number,
  notes: PropTypes.number,
  development_content: ContentInterface,
  mandatory_content: ContentInterface,
  selected_training_needs: PropTypes.array
});

export const CollaboratorsInterface = PropTypes.shape({
  total_items: PropTypes.number,
  count: PropTypes.number,
  from: PropTypes.number,
  collaborators: PropTypes.arrayOf(CollaboratorInterface)
});

export const CollaboratorsFiltersInterface = PropTypes.shape({
  search: PropTypes.string
});

export const ViewInterface = PropTypes.shape({
  idCourseType: PropTypes.string,
  views: PropTypes.number,
  title: PropTypes.string
});

export const ViewsInterface = PropTypes.arrayOf(ViewInterface);

export const PositiveInterface = PropTypes.shape({
  idCourseType: PropTypes.string,
  positive: PropTypes.number,
  title: PropTypes.string
});

export const PositivesInterface = PropTypes.arrayOf(PositiveInterface);
