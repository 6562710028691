import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { MODAL_OPEN } from 'redux/actions';
import { Button } from 'ui/atoms';
import NotesModal, { NotesModalId } from 'ui/components/Modals/NotesModal';
import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { Footer, ProfilePicture } from 'ui/components';
import ProText from 'ui/typography/proText';
import { GET_NOTES } from 'redux/actions/notes';
import routes from 'routes';
import { push } from 'redux-first-history';
import { useParams } from 'react-router-dom';
import ShareNoteModal from 'ui/components/Modals/ShareNoteModal';
import DeleteNoteModal from 'ui/components/Modals/DeleteNoteModal';
import AddCommentModal from 'ui/components/Modals/AddCommentModal';
import { getTimeByLastUpdate } from 'utils/date-format';
import app from 'api/app';
import {
  BtnWrapper,
  DetailHead,
  DetailTitle,
  Header,
  IconUser,
  Main,
  NoteDescription,
  NoteDetailContainer,
  NotePreviewContainer,
  NotesContainer,
  SharedWith,
  Title,
  DetailText,
  IconsContainer,
  IconBack,
  IconEdit,
  IconDelete,
  IconsWrapper,
  Text,
  Image,
  Owner,
  Comment,
  IconPlus
} from './style';
import MainContainer from '../style';

const Notes = ({
  pushUrl,
  getNotes,
  notes,
  mediaIsTablet,
  mediaIsPhone,
  openModal,
  myId
}) => {
  const [currentNote, setCurrentNote] = useState({});
  const [detailVisible, setVisible] = useState(false);
  const [image, setImage] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [noteIdState, setNoteId] = useState('');

  const { noteId } = useParams();

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  useEffect(() => {
    const note = notes?.find(nt => nt.id === noteId);
    setNoteId(noteId);

    if (note) {
      setCurrentNote(note);
    }
  }, [noteId, notes]);

  useEffect(() => {
    if (notes !== undefined && notes?.length > 0) {
      setCurrentNote(notes[0]);
      pushUrl(`${routes.notes.path}/${notes[0].id}`);
    }
  }, [notes, pushUrl]);

  const setNote = id => {
    const selectedNote = notes.find(n => n.id === id);
    setVisible(true);
    pushUrl(`${routes.notes.path}/${selectedNote.id}`);
  };

  useEffect(() => {
    if (currentNote.imageUrl) {
      try {
        app
          .getPicture(currentNote.imageUrl)
          .then(response => {
            const imageDecoded = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );

            setImage(
              `data:${response.headers[
                'content-type'
              ].toLowerCase()};base64,${imageDecoded}`
            );
          })
          .catch(err => {
            console.log('app.getPicture', err);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      setImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNote]);

  return (
    <>
      <MainContainer>
        <Container>
          <Header>
            <Title>
              <ProDisplay
                type="menutitle"
                configuration={{ color: 'greenDark' }}
              >
                Note
              </ProDisplay>
            </Title>
            <BtnWrapper>
              <Button
                type="PRIMARY"
                text="Aggiungi"
                onClick={() => {openModal(NotesModalId, { isEdit: false }); setIsEdit(false)}}
              />
            </BtnWrapper>
          </Header>
          {notes?.length === 0 ? (
            <Text>
              <ProText type="1">Non ci sono note da visualizzare</ProText>
            </Text>
          ) : (
            <Main>
              <NotesContainer
                className={
                  mediaIsTablet || mediaIsPhone ? 'col-12 p-0' : 'col-5 p-0'
                }
              >
                {notes?.map(note => (
                  <NotePreviewContainer
                    key={note.id}
                    onClick={() => setNote(note.id)}
                    className={note.id === currentNote.id ? 'selected' : ''}
                  >
                    <NoteDescription>
                      <ProText type="notetitle">
                        {note.title?.substring(0, 70)}
                        {note.title?.length >= 70 ? '...' : ''}
                      </ProText>
                      <ProText type="notedate">
                        {getTimeByLastUpdate(note.lastUpdateDate)}
                      </ProText>
                    </NoteDescription>
                    {(note.users?.length > 0 ||
                      note.partecipants?.length > 0) && (
                      <SharedWith>
                        {(note.users || note.partecipants)
                          .slice(0, 2)
                          .map(prof => (
                            <ProfilePicture
                              key={prof.userId}
                              widthContainer="25px"
                              heightContainer="25px"
                              fontSizeInitials={14}
                              profile={{
                                pictureId: prof.imageUrl,
                                firstname: prof.givenName,
                                lastname: prof.familyName
                              }}
                            />
                          ))}
                      </SharedWith>
                    )}
                  </NotePreviewContainer>
                ))}
              </NotesContainer>
              <NoteDetailContainer
                className={mediaIsTablet || mediaIsPhone ? '' : 'col-7 p-0'}
                isVisible={detailVisible}
              >
                {(mediaIsPhone || mediaIsTablet) && (
                  <IconsContainer>
                    <IconBack
                      className="icon-arrow-right"
                      onClick={() => setVisible(false)}
                    />
                    <IconsWrapper>
                      {myId === currentNote?.owner?.userId && (
                        <IconEdit
                          className="icon-modifica"
                          onClick={() => {
                            {openModal(NotesModalId, { noteId, isEdit: true }); setIsEdit(true)};
                          }}
                        />
                      )}
                      {((currentNote.users && currentNote.users.length === 0) ||
                        (currentNote.partecipants &&
                          currentNote.partecipants.length === 0)) && (
                        <IconDelete
                          className="icon-icona-elimina"
                          onClick={() => {
                            openModal(DeleteNoteModal.id, { noteId });
                          }}
                        />
                      )}
                    </IconsWrapper>
                  </IconsContainer>
                )}
                <DetailHead>
                  {!mediaIsPhone && !mediaIsTablet && (
                    <IconsWrapper className="align-left">
                      {myId === currentNote?.owner?.userId && (
                        <IconEdit
                          className="icon-modifica"
                          onClick={() => {
                            {openModal(NotesModalId, { noteId, isEdit: true }); setIsEdit(true)};
                          }}
                        />
                      )}
                      {((currentNote.users && currentNote.users.length === 0) ||
                        (currentNote.partecipants &&
                          currentNote.partecipants.length === 0)) && (
                        <IconDelete
                          className="icon-icona-elimina"
                          onClick={() => {
                            openModal(DeleteNoteModal.id, { noteId });
                          }}
                        />
                      )}
                    </IconsWrapper>
                  )}
                  {(currentNote.users?.length > 0 ||
                    currentNote.partecipants?.length > 0) &&
                    (currentNote.users || currentNote.partecipants).map(
                      prof => (
                        <ProfilePicture
                          key={prof.userId}
                          widthContainer="25px"
                          heightContainer="25px"
                          fontSizeInitials={14}
                          profile={{
                            pictureId: prof.imageUrl,
                            firstname: prof.givenName,
                            lastname: prof.familyName
                          }}
                        />
                      )
                    )}
                  {myId === currentNote?.owner?.userId && (
                    <IconUser
                      className="icon-icona-add-user1"
                      onClick={() =>
                        openModal(ShareNoteModal.id, {
                          noteId
                        })
                      }
                    />
                  )}
                  <IconPlus
                    className="icon-plus"
                    onClick={() =>
                      openModal(AddCommentModal.id, {
                        noteId
                      })
                    }
                  />
                </DetailHead>
                {currentNote.owner && (
                  <Owner>
                    <ProText type="3">
                      {myId === currentNote.owner.userId
                        ? 'Tu'
                        : `${currentNote.owner.givenName} ${currentNote.owner.familyName}`}
                    </ProText>
                    <ProText type="notedate">
                      {getTimeByLastUpdate(currentNote.lastUpdateDate)}
                    </ProText>
                  </Owner>
                )}
                <DetailTitle>
                  <ProText
                    type="title"
                    configuration={{
                      lineHeight: 1.3,
                      letterSpacing: '-0.14px'
                    }}
                  >
                    {currentNote.title}
                  </ProText>
                </DetailTitle>
                <DetailText>
                  <ProText
                    type="label"
                    configuration={{ lineHeight: 1.86, color: 'mineShaft' }}
                  >
                    {currentNote.body}
                  </ProText>
                </DetailText>
                {image ? <Image src={image} /> : null}
                {currentNote.comments?.length > 0 &&
                  currentNote.comments.map((c, idx) => (
                    <Comment key={`comment-${idx}`}>
                      <Owner>
                        <ProText type="3">
                          {myId === c.owner.userId
                            ? 'Tu'
                            : `${c.owner.givenName} ${c.owner.familyName}`}
                        </ProText>
                        <ProText type="notedate">
                          {getTimeByLastUpdate(c.dateCreate)}
                        </ProText>
                      </Owner>
                      <ProText
                        type="label"
                        configuration={{ lineHeight: 1.86, color: 'mineShaft' }}
                      >
                        {c.body}
                      </ProText>
                    </Comment>
                  ))}
              </NoteDetailContainer>
            </Main>
          )}
          <NotesModal isEdit={isEdit} noteId={noteIdState} />
          <ShareNoteModal />
          <DeleteNoteModal />
          <AddCommentModal />
        </Container>
      </MainContainer>
      {!mediaIsPhone && !mediaIsTablet && <Footer />}
    </>
  );
};

Notes.propTypes = {
  // HOC (connect, state)
  pushUrl: PropTypes.func,
  getNotes: PropTypes.func,
  notes: PropTypes.array,
  openModal: PropTypes.func,
  myId: PropTypes.string,

  // HOC (connect, dispatch)
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsPhone: PropTypes.bool.isRequired
};

export default withMediaQueries(
  connect(
    state => {
      const {
        notes: { notes }
      } = state.notes;
      const {
        user: { userId: myId }
      } = state.authentication;
      return { notes, myId };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      getNotes: () => dispatch({ type: GET_NOTES._REQUEST }),
      openModal: (id, payload = {}) =>
        dispatch({ type: MODAL_OPEN, id, payload })
    })
  )(Notes)
);
