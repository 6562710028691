import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';
import ProText from 'ui/typography/proText';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  Subtitle,
  ContentWrapper,
  Icon,
  Head,
  TextArea,
  BtnContainer,
  Texts,
  KnobContainer,
  Knob,
  Labels,
  Bottom,
  QuestionContainer
} from './style';
import { ModalGlobalContainer } from '../Modal.style';
import Modal, { stopEvent } from '../Modal';
import { ADD_REVIEW } from 'redux/actions/playlist';

const modalId = 'ReviewModal';

const ReviewModal = ({ onClose, itemId, addReview, objectType='LO' }) => {
  const knobs = [1, 2, 3, 4, 5];
  const [answers, setAnswers] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleEvaluation = (index, qstIndex) => {
    const newValues = [...answers];
    newValues[qstIndex] = index + 1;
    setAnswers(newValues);
  };

  const handleChangeInput = (value, qstIndex) => {
    const newAnswers = [...answers];
    newAnswers[qstIndex] = value;

    setAnswers(newAnswers);
  };

  useEffect(() => {
    if (answers.length > 0) {
      let valid = true;
      answers.forEach(a => {
        valid = valid && answers.length === 3 && a && a.toString().length > 0;
      });
      setBtnDisabled(!valid);
    }
  }, [answers]);

  const onSubmit = () => {
    const body = {
      title: 'Lascia una recensione',
      body: answers[2],
      itemId,
      objectType,
      questions: [
        {
          value: answers[0],
          title: 'Lascia una recensione a questo corso.'
        },
        {
          value: answers[1],
          title:
            'Rispetto alla tematica trattata, quanto reputi efficace la visualizzazione di questo contenuto?'
        }
      ]
    };
    addReview(body);
    setAnswers([]);
    onClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="520px"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <Head>
                <Texts>
                  <Title>
                    <ProDisplay type="modalTitle">Recensione</ProDisplay>
                  </Title>
                  <Subtitle>
                    <ProDisplay type="label">
                      Lascia una recensione a questo corso.
                    </ProDisplay>
                  </Subtitle>
                </Texts>
              </Head>
              <QuestionContainer>
                <ProText type="label">
                  Quanto hai trovato utile questo contenuto?
                </ProText>
                <KnobContainer>
                  {knobs.map((item, index) => {
                    return (
                      <Knob
                        key={`q1-${index}`}
                        className={index < answers[0] ? 'selected' : ''}
                        onClick={() => {
                          handleEvaluation(index, 0);
                        }}
                      />
                    );
                  })}
                </KnobContainer>
                <Labels>
                  <ProText type="label">Per niente</ProText>
                  <ProText type="dots">{' · '.repeat(25)}</ProText>
                  <ProText type="label">Molto</ProText>
                </Labels>
              </QuestionContainer>
              <QuestionContainer>
                <ProText type="label">
                  Rispetto alla tematica trattata, quanto reputi efficace la
                  visualizzazione di questo contenuto?
                </ProText>
                <KnobContainer>
                  {knobs.map((item, index) => {
                    return (
                      <Knob
                        key={`q2-${index}`}
                        className={index < answers[1] ? 'selected' : ''}
                        onClick={() => {
                          handleEvaluation(index, 1);
                        }}
                      />
                    );
                  })}
                </KnobContainer>
                <Labels>
                  <ProText type="label">Per niente</ProText>
                  <ProText type="dots">{' · '.repeat(25)}</ProText>
                  <ProText type="label">Molto</ProText>
                </Labels>
              </QuestionContainer>
              <QuestionContainer>
                <ProText type="label">Lascia una recensione</ProText>
                <TextArea
                  onChange={e => {
                    handleChangeInput(e.target.value, 2);
                  }}
                  value={answers[2] || ''}
                />
              </QuestionContainer>
              <Bottom>
                <BtnContainer>
                  <Button
                    text="Indietro"
                    type="TERTIARY"
                    onClick={() => {
                      setAnswers([]);
                      onClose();
                    }}
                  />
                </BtnContainer>
                <BtnContainer>
                  <Button
                    text="Invia"
                    type="PRIMARY"
                    onClick={onSubmit}
                    disabled={btnDisabled}
                  />
                </BtnContainer>
              </Bottom>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

ReviewModal.propTypes = {
  // HOC (connect, state)
  itemId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  addReview: PropTypes.func
};

ReviewModal.id = modalId;

export default connect(
  state => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    addReview: payload => dispatch({ type: ADD_REVIEW._REQUEST, payload })
  })
)(ReviewModal);
