import React from 'react';
import { Icon } from './style';

const Powerpoint = () => (
  <Icon className="icon-powerpoint">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
  </Icon>
);

export default Powerpoint;

