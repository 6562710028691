import styled, { css } from 'styled-components/macro';

export const RadioDescription = styled.div`
  margin-top: -12px;
  padding: 0 32px 32px 32px;
  width: 100%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: 125ms ease-in;
`;

export const PaymentCardContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 10px 40px 0 rgba(24, 42, 50, 0.03);
  background-color: white;
  margin-bottom: 18px;
  position: relative;
  border: 1px solid ${props => props.theme.colors.lightSilver};
  
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
  max-height: 88px;
  overflow: hidden;
  transition: max-height 325ms ease-in;
  
  ${props =>
    props.isOpen
    && css`
      max-height: 500px;

      ${RadioDescription} {
        opacity: 1;
        height: 100%;
      }
    `}
    
  ${props =>
    props.isOpen && props.isCCZuora
    && css`
        max-height: 500px;
        height: 500px;
    
        ${RadioDescription} {
          opacity: 1;
          height: 100%;
        }
        
        @media ${props.theme.device.mobile} {
          max-height: 700px;
          height: 700px;
        }
    `}
  
  ${props =>
    props.isOpen && props.isSEPAZuora
  && css`
        max-height: 500px;
        height: 500px;
    
        ${RadioDescription} {
          opacity: 1;
          height: 100%;
        }
        
        @media ${props.theme.device.mobile} {
          max-height: 700px;
          height: 700px;
        }
    `}
  
  ${props =>
    props.actionable
    && css`
      transition: none;
    `}
  

  @media ${props => props.theme.device.tablet} {
    border: none;
    box-shadow: none;
    background-color: ${props => props.theme.colors.transparent};
    padding-left: 0;
    padding-right: 0;
    
    ${props =>
    !props.isOpen
      && css`
        border-radius: 0;
        border-top: solid 1px ${props.theme.colors.lightSilver};
        border-bottom: solid 1px ${props.theme.colors.lightSilver};
      `}
  }
`;

export const Radio = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 32px;
`;

export const Icons = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.device.mobile} {
    width: 35%;
  }
`;

export const Icon = styled.span``;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  width: 20%;
`;

export const ActionBtn = styled.button`
  display: flex;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
  background-color: ${props => props.theme.colors.white};
  padding-right: 0;
  &:hover {
    color: ${props => props.theme.colors.primary.base};
    text-decoration: underline;
  }
`;
