import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Img from 'ui/assets/img/group.png';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';

import { Container } from 'ui/gridSystem';

import { SIGNUP_RESET_CART } from 'redux/actions/signup';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { WelcomeInterface } from 'interface/configuration';
import {
  ALL_PROSPECT_GET,
  ASSOC_SUBSCRIPTIONS_GET,
  GO_TO_EDIT_SUBSCRIPTION,
} from 'redux/actions/subscriptions';
import { LOADER_OPEN } from 'redux/actions';
import { ContentWrapper, Image, Title, Subtitle } from './style';

const Confirm = ({
  dataLoaded,
  welcome,
  goToEditSubscriptions,
  welcomeFromBank,
  isFromBank,
  resetCart,
  getAllProspect,
  getAssocSubscriptions,
  loaderOpen,
}) => {
  useEffect(() => {
    if (dataLoaded.prospects && dataLoaded.subscriptions) {
      goToEditSubscriptions();
    }
  }, [dataLoaded, goToEditSubscriptions]);

  const handleClick = () => {
    loaderOpen();
    setTimeout(() => {
      getAllProspect();
      getAssocSubscriptions();
      resetCart();
    }, 2000);
  };

  return (
    <Container>
      <ContentWrapper>
        <Image src={Img} />
        <Title>
          <ProDisplay type="modalTitle">
            {isFromBank ? welcomeFromBank.title : welcome.title}
          </ProDisplay>
        </Title>
        <Subtitle>
          <ProDisplay configuration={{ lineHeight: 1.63 }}>
            {isFromBank ? welcomeFromBank.description : welcome.description}
          </ProDisplay>
        </Subtitle>
        {!isFromBank && (
          <Button type={Button.TYPE.PRIMARY} onClick={handleClick}>
            {welcome.call_to_action}
          </Button>
        )}
      </ContentWrapper>
    </Container>
  );
};

Confirm.propTypes = {
  // HOC withMediaQueires

  // HOC (connect, state)
  dataLoaded: PropTypes.bool,
  welcome: WelcomeInterface,
  welcomeFromBank: WelcomeInterface,
  isFromBank: PropTypes.bool,

  // HOC (connect, dispatch)
  goToEditSubscriptions: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired,
  getAllProspect: PropTypes.func.isRequired,
  getAssocSubscriptions: PropTypes.func.isRequired,
  loaderOpen: PropTypes.func.isRequired,
};

export default withMediaQueries(
  connect(
    state => {
      const { welcome, welcomeFromBank } = state.configuration;
      const { isFromBank } = state.signup;
      const { dataLoaded } = state.subscriptions;
      return {
        dataLoaded,
        welcome,
        welcomeFromBank,
        isFromBank,
      };
    },
    dispatch => ({
      loaderOpen: () => dispatch({ type: LOADER_OPEN }),
      goToEditSubscriptions: () => dispatch({ type: GO_TO_EDIT_SUBSCRIPTION }),
      getAllProspect: () => dispatch({ type: ALL_PROSPECT_GET._REQUEST }),
      getAssocSubscriptions: () => dispatch({ type: ASSOC_SUBSCRIPTIONS_GET._REQUEST }),
      resetCart: () => dispatch({ type: SIGNUP_RESET_CART }),
    })
  )(Confirm)
);
