import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import moment from 'moment';
import validator from 'utils/regex-validator';

import { CustomInput } from 'ui/atoms';

import { StripeIbanElement } from 'ui/components';

import { Row, Col } from 'ui/gridSystem';
import compose from 'utils/compose';
import { LOADER_CLOSE, LOADER_OPEN, SET_TIMESTAMP } from 'redux/actions';

const ibanFormValidity = {
  owner: false,
  iban: false
};

const IBAN = ({
  setData,
  stripe,
  billingDetails: billing_details,
  clientSecret: client_secret,
  disabled,
  callback,
  loaderOpen,
  loaderClose,
  setTimestamp,
  email
}) => {
  const [formValidity, setFormValidity] = useState(ibanFormValidity);
  const [owner, setOwner] = useState();
  const [ibanRef, setIbanRef] = useState();

  const createPaymentMethod = () =>
    new Promise(res => {
      // eslint-disable-next-line react/prop-types
      loaderOpen();
      stripe
        .retrieveSetupIntent(client_secret)
        .then(result => {
          loaderClose();
          const { error, setupIntent } = result;
          if (setupIntent) {
            loaderOpen();
            setTimestamp(moment().valueOf());
            stripe
              .confirmSepaDebitSetup(client_secret, {
                payment_method: {
                  sepa_debit: ibanRef,
                  billing_details: {
                    ...billing_details,
                    name: owner,
                    email
                  }
                }
              })
              .then(response => {
                loaderClose();
                if (callback) {
                  callback(response);
                }
                res(response);
              })
              .catch(err => {
                console.log(err);
                loaderClose();
                setData(undefined);
              });
          } else if (error) {
            console.log('retrieveSetupIntent ', error);
          }
        })
        .catch(error => {
          console.log(error);
          loaderClose();
        });
    });

  const setFormIBAN = () => {
    const formValid =
      Object.values(formValidity).filter(value => !value === false).length <= 0;
    if (formValid && owner && ibanRef) {
      setData({
        billing_details: {
          name: owner
        }
      });
      createPaymentMethod();
    } else {
      setData(undefined);
    }
  };

  const RefIBAN = ref => {
    ref.addEventListener('change', event => {
      const { error, empty, complete } = event;
      if (error || empty || !complete) {
        setIbanRef(undefined);
        setFormValidity(prevState => ({
          ...prevState,
          iban: error ? error.message : true
        }));
      } else {
        setIbanRef(ref);
        setFormValidity(prevState => ({
          ...prevState,
          iban: false
        }));
      }
    });
  };

  const handleOnchangeName = value => {
    setFormValidity(prevState => ({
      ...prevState,
      owner: !validator.string(value) || value.length < 3
    }));
    setOwner(value);
  };

  return (
    <div>
      <form
        id="iban"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Row>
          <Col sm={12} className="mb-3">
            <CustomInput text="IBAN" error={formValidity.iban}>
              <StripeIbanElement
                onReady={ref => RefIBAN(ref)}
                disabled={disabled}
              />
            </CustomInput>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="mb-3">
            <CustomInput
              type="text"
              text="Nome associato al conto"
              value={owner}
              onChange={value => handleOnchangeName(value)}
              error={formValidity.owner && owner.length > 0}
              hasMinLenght
              disabled={disabled}
              onBlur={setFormIBAN}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

IBAN.propTypes = {
  setData: PropTypes.func,
  stripe: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  clientSecret: PropTypes.string.isRequired,
  billingDetails: PropTypes.object,
  loaderOpen: PropTypes.func,
  loaderClose: PropTypes.func,
  setTimestamp: PropTypes.func,
  email: PropTypes.string,
  callback: PropTypes.func
};

const composed = compose(
  connect(
    ({
      authentication: {
        user: { email = '' }
      }
    }) => ({ email }),
    dispatch => ({
      loaderOpen: () => dispatch({ type: LOADER_OPEN }),
      loaderClose: () => dispatch({ type: LOADER_CLOSE }),
      setTimestamp: timestamp => dispatch({ type: SET_TIMESTAMP, timestamp })
    })
  )
)(injectStripe(IBAN));
export default composed;
