import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import ProDisplay from 'ui/typography/proDisplay';
import theme from 'ui/theme';
import ProText from 'ui/typography/proText';
import {
  ONBOARDING_GET_ON_BOARDING,
  ONBOARDING_PUT_ASSETS_ACTIVE,
  ONBOARDING_SELECT_ITEM
} from 'redux/actions/onBoarding';
import { Container } from 'ui/gridSystem';
import Placeholder from 'ui/atoms/Placeholder';
import { Button } from 'ui/atoms';
import {
  ListSection,
  OnBoardingWrapper,
  SubTitleList,
  TitleList,
  List,
  GroupWrapper,
  ListItem,
  InfoItem,
  PlusMinor,
  Arrow,
  Icon,
  IconContents,
  ContentsSection,
  TitleGridSection,
  Title,
  GridSection,
  SelectedNumber,
  Numbers,
  Grid,
  NumberWrapper,
  DisabledOverlay,
  ConfiguredWrapper,
  InfoConfigured,
  ContentsConfigured,
  TitleConfigured,
  ImageOverlay,
  IconRemove,
  ButtonWrapper,
  ButtonDiv,
  NumberWrapperMobile,
  ButtonWrapperMobile,
  Layout
} from './style';
import DetailSection from './DetailSection';

const PlaceholderSquare = () => <Placeholder />;

const DrawProfile = ({
  needs,
  notConfigured,
  getNeeds,
  pushUrl,
  putAssetsActive,
  selectedNeed,
  setSelectedNeed
}) => {
  const { colors } = theme();
  const [configured, setConfigured] = useState([]);

  useEffect(() => {
    if (notConfigured && notConfigured.length > 0) {
      getNeeds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notConfigured, pushUrl]);

  const handleAddConfigured = newItem => {
    const { cover, title, coursesCount } = newItem;
    const nc = notConfigured.find(
      elm => elm.type === newItem.type && !configured.find(c => c.id === elm.id)
    );
    if (nc) {
      setConfigured([
        ...configured,
        { ...nc, assetId: newItem.id, cover, title, coursesCount }
      ]);
    }
  };

  const handleRemoveConfigured = id =>
    setConfigured(configured.filter(elm => elm.id !== id));

  const handleOnConfimAssets = () =>
    putAssetsActive(
      configured.map(({ id, assetId, type }) => ({ id, assetId, type }))
    );

  const handleClickItem = n => setSelectedNeed(n);

  const getFilteredNeeds = items => items.filter(i => !i.hideFromOnboarding);

  return (
    <OnBoardingWrapper>
      {notConfigured && (
        <NumberWrapperMobile>
          <ProText>
            <Numbers>Selezionati: </Numbers>
          </ProText>
          <ProText>
            <SelectedNumber>{configured.length}</SelectedNumber>
          </ProText>
          <ProText>
            <Numbers>{`/${notConfigured.length}`}</Numbers>
          </ProText>
        </NumberWrapperMobile>
      )}
      <ListSection>
        <TitleList>
          <ProDisplay
            type="title"
            configuration={{ color: 'primary', colorType: 'base' }}
          >
            Disegna il tuo piano
          </ProDisplay>
        </TitleList>
        <SubTitleList>
          {notConfigured && (
            <ProText>{`Aggiungi le ${notConfigured.length} playlist che fanno per te`}</ProText>
          )}
        </SubTitleList>
        <List>
          {needs.map(need => {
            if (getFilteredNeeds(need.items)?.length === 0) return null;
            return (
              <GroupWrapper key={need.id}>
                <ProText
                  type="link"
                  configuration={{
                    textTransform: 'uppercase',
                    color: colors.slateGray
                  }}
                >
                  {need.title}
                </ProText>
                {getFilteredNeeds(need.items).map(item => (
                  <ListItem
                    key={item.id}
                    disabled={item.asset}
                    selected={selectedNeed && item.id === selectedNeed.id}
                  >
                    {item.asset && <DisabledOverlay />}
                    <PlusMinor>
                      {configured.find(elm => elm.assetId === item.id) ? (
                        <Icon
                          className="icon-icona-rimuovi-copy-1"
                          onClick={() =>
                            handleRemoveConfigured(
                              configured.find(elm => elm.assetId === item.id).id
                            )
                          }
                        />
                      ) : (
                        <Icon
                          className="icon-icona-aggiungi"
                          onClick={() => handleAddConfigured(item)}
                        />
                      )}
                    </PlusMinor>
                    <InfoItem onClick={() => handleClickItem(item)}>
                      <Layout
                        selected={configured.find(
                          elm => elm.assetId === item.id
                        )}
                      >
                        <ProText type="titleSeparator">{item.title}</ProText>
                        <ContentsSection>
                          <ProText type="link">
                            <IconContents className="icon-icona-collection-copy-1" />
                            {`${item.coursesCount} contenuti`}
                          </ProText>
                        </ContentsSection>
                      </Layout>
                    </InfoItem>
                    <Arrow onClick={() => handleClickItem(item)}>
                      <Icon className="icon-btn-arrow" />
                    </Arrow>
                  </ListItem>
                ))}
              </GroupWrapper>
            );
          })}
        </List>
      </ListSection>
      {!selectedNeed && (
        <ButtonWrapperMobile>
          <Button
            type={Button.TYPE.PRIMARY}
            text="Continua"
            disabled={
              !(notConfigured && notConfigured.length === configured.length)
            }
            onClick={handleOnConfimAssets}
          />
        </ButtonWrapperMobile>
      )}
      {selectedNeed ? (
        <DetailSection
          item={selectedNeed}
          onClose={() => setSelectedNeed(null)}
          onAdd={
            configured.find(c => c.assetId === selectedNeed.id)
              ? null
              : () => handleAddConfigured(selectedNeed)
          }
        />
      ) : (
        <GridSection>
          <Container>
            <TitleGridSection>
              <Title>
                <ProText type="buttonText">Il tuo piano formativo:</ProText>
              </Title>
              {notConfigured && (
                <NumberWrapper>
                  <ProText>
                    <Numbers>Selezionati: </Numbers>
                  </ProText>
                  <ProText>
                    <SelectedNumber>{configured.length}</SelectedNumber>
                  </ProText>
                  <ProText>
                    <Numbers>{`/${notConfigured.length}`}</Numbers>
                  </ProText>
                </NumberWrapper>
              )}
            </TitleGridSection>
            <Grid>
              {notConfigured &&
                [
                  ...configured,
                  ...Array(notConfigured.length - configured.length)
                ].map((conf, i) =>
                  conf ? (
                    <ConfiguredWrapper key={conf.id} image={conf.cover}>
                      <ImageOverlay />
                      <IconRemove>
                        <Icon
                          className="icon-icona-rimuovi"
                          onClick={() => handleRemoveConfigured(conf.id)}
                        />
                      </IconRemove>
                      <InfoConfigured>
                        <ProDisplay>
                          <TitleConfigured>{conf.title}</TitleConfigured>
                        </ProDisplay>

                        <ProText>
                          <ContentsConfigured>
                            <IconContents className="icon-icona-collection-copy-1" />
                            {` ${conf.coursesCount}`}
                          </ContentsConfigured>
                        </ProText>
                      </InfoConfigured>
                    </ConfiguredWrapper>
                  ) : (
                    <PlaceholderSquare key={i} />
                  )
                )}
            </Grid>
            <ButtonWrapper>
              <ButtonDiv>
                <Button
                  type={Button.TYPE.PRIMARY}
                  text="Continua"
                  disabled={
                    !(
                      notConfigured &&
                      notConfigured.length === configured.length
                    )
                  }
                  onClick={handleOnConfimAssets}
                />
              </ButtonDiv>
            </ButtonWrapper>
          </Container>
        </GridSection>
      )}
    </OnBoardingWrapper>
  );
};

DrawProfile.propTypes = {
  // HOC (connect,state)
  needs: PropTypes.array,
  notConfigured: PropTypes.array,
  selectedNeed: PropTypes.object,

  // HOC  (connect,dispatch)
  getNeeds: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  putAssetsActive: PropTypes.func.isRequired,
  setSelectedNeed: PropTypes.func.isRequired
};

export default connect(
  ({ onBoarding: { needs = [], notConfigured, selectedNeed } }) => ({
    needs,
    notConfigured,
    selectedNeed
  }),
  dispatch => ({
    getNeeds: () => dispatch({ type: ONBOARDING_GET_ON_BOARDING._REQUEST }),
    pushUrl: url => dispatch(push(url)),
    putAssetsActive: payload =>
      dispatch({
        type: ONBOARDING_PUT_ASSETS_ACTIVE._REQUEST,
        payload
      }),
    setSelectedNeed: payload =>
      dispatch({
        type: ONBOARDING_SELECT_ITEM._REQUEST,
        payload
      })
  })
)(DrawProfile);
