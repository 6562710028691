import styled from 'styled-components/macro';

export const Background = styled.div`
  width: 100%;
  height: 191px;
  border-radius: 14px;
  background: linear-gradient(
    135deg,
    ${props => props.theme.colors.primary.darker},
    ${props => props.theme.colors.primary.lighter}
  );
  padding: 30px;
`;

export const PlaceHolder = styled.div`
  height: 100%;
  background-image: url(${props => props.logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
