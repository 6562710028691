import React from 'react';
import { Icon } from './style';

const Amex = () => (
  <Icon className="icon-credit-cards-amex">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
    <span className="path4" />
    <span className="path5" />
    <span className="path6" />
  </Icon>
);

export default Amex;
