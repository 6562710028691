import { FontBase } from 'const';

const baseConfig = {
  color: 'unset',
  fontFamily: 'protext',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  fontSize: FontBase,
  cursor: 'inherit'
};

const types = {
  1: {
    ...baseConfig
  },
  2: {
    ...baseConfig,
    fontWeight: 400
  },
  3: {
    ...baseConfig,
    fontWeight: 500
  },
  4: {
    ...baseConfig,
    fontWeight: 600
  },
  5: {
    ...baseConfig,
    fontWeight: 700
  },
  title: {
    ...baseConfig,
    fontSize: '20',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  description: {
    ...baseConfig,
    fontSize: '16',
    fontStretch: 'normal',
    lineHeight: 1.63,
    letterSpacing: 'normal'
  },
  section: {
    ...baseConfig,
    fontSize: '16',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 1.63,
    letterSpacing: 'normal'
  },
  buttonText: {
    ...baseConfig,
    fontSize: '16',
    fontStretch: 'normal',
    fontWeight: 600,
    lineHeight: 1.63,
    letterSpacing: 'normal',
    textAlign: 'center'
  },
  label: {
    ...baseConfig,
    fontSize: '14',
    fontStretch: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal'
  },
  resumeLabel: {
    ...baseConfig,
    fontSize: '14',
    fontStretch: 'normal',
    lineHeight: 1.86,
    letterSpacing: 'normal'
  },
  link: {
    ...baseConfig,
    fontSize: '12',
    fontStretch: 'normal',
    lineHeight: 2.17,
    letterSpacing: 'normal'
  },
  time: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 300,
    fontStretch: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal'
  },
  resumeTime: {
    ...baseConfig,
    fontSize: '12',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal'
  },
  list: {
    ...baseConfig,
    fontSize: '11',
    fontWeight: 500,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  courseTitle: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: '-0.1px',
    color: 'white'
  },
  courseDuration: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '-0.19px',
    color: 'platinum'
  },
  dots: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 400,
    fontStretch: 'normal',
    lineHeight: '50%',
    letterSpacing: 2.5,
    color: 'slateGray'
  },
  barLabel: {
    ...baseConfig,
    fontSize: '15',
    fontWeight: 400,
    fontStretch: 'normal',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: 'slateGray'
  },
  sidebarTitle: {
    ...baseConfig,
    fontSize: '28',
    fontWeight: 300,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'greenDark'
  },
  duration: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 300,
    fontStretch: 'normal',
    lineHeight: '14px',
    letterSpacing: '-0.1px',
    color: 'slateGray'
  },
  notetitle: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.29',
    letterSpacing: '-0.1px'
  },
  notedate: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: 'slateGray'
  },
  playlistTitle: {
    ...baseConfig,
    fontSize: '18',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: '-0.13px'
  },
  titleSeparator: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.17,
    letterSpacing: 'normal'
  },
  titleSuggestion: {
    ...baseConfig,
    fontSize: '14',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: '16px',
    letterSpacing: 'normal'
  },
  durationSmall: {
    ...baseConfig,
    fontSize: '10',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: 'slateGray'
  },
  showMore: {
    ...baseConfig,
    fontSize: '16',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: 'primary',
    colorType: 'base'
  },
  completed: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 500,
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: '-0.3px',
    textAlign: 'center',
    color: 'white'
  },
  playlistOvertitle: {
    ...baseConfig,
    fontSize: '12',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.17,
    letterSpacing: '-0.1px'
  },
  detailAction: {
    ...baseConfig,
    fontSize: '16',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.38,
    letterSpacing: '-0.39px'
  },
  name: {
    ...baseConfig,
    fontSize: '22',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 0.82,
    letterSpacing: '-0.16px'
  }
};

export default types;