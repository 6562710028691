export const cookieExpiresOneYear = ';max-age=31536000';
export const cookiePathGlobal = ';path=/';

export const getCookie = cookiePair => document.cookie.includes(cookiePair);

export const setCookie = (
  cookiePair,
  expiresIn = cookieExpiresOneYear,
  path = cookiePathGlobal
) => {
  document.cookie = `${cookiePair}${expiresIn}${path}`;
};
