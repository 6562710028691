import styled from 'styled-components/macro';

export const BillingsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormGroup = styled.div`
  margin-bottom: 40px;
`;

export const PromoCodeContainer = styled.div`
  margin: 0;
`;

export const ButtonsContainer = styled.div`
  margin: 55px 0 40px;
`;

export const BtnPromoContainer = styled.div`
  width: 114px;

  @media ${props => props.theme.device.mobile} {
    margin-top: 20px;
  }
`;

export const BtnCancel = styled.div`
  width: 180px;
  margin-right: auto;

  @media ${props => props.theme.device.mobile} {
    width: 150px;
  }
`;

export const BtnConfirm = styled.div`
  width: 180px;
  margin-left: auto;

  @media ${props => props.theme.device.mobile} {
    width: 150px;
  }
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  padding-bottom: 16px;
`;

export const SummaryContainer = styled.div`
  margin-bottom: 20px;
`;

export const UnlockPayment = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.greenDark};
  text-transform: uppercase;
  padding-bottom: 12px;
  padding-top: 23px;
`;

export const Data = styled.div`
  display: flex;
  color: ${props => props.theme.colors.greenDark};
  justify-content: space-between;
  text-align: end;
  width: 30%;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.div`
  color: ${props => props.theme.colors.greenDark};
  width: 70%;
  display: flex;
`;

export const IconCard = styled.span`
  margin-right: 20px;
`;

export const ChooseType = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    & div:last-child {
      margin-top: 10px;
    }
  }
`;
