import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PROFILE_DATA_ACCOUNT_GET } from 'redux/actions/profile';
import useFileUpload from 'customHooks/useFileUpload';

import { AccountDataInterface } from 'interface/profile';
import { generateInitials } from 'utils/generate-initials';
import app from 'api/app';

import IconPhoto from 'ui/assets/icons/IconPhoto';
import { ChangePicture, Icon, ProfilePictureContainer } from './style';

const ProfilePicture = ({
  acceptedFiles,
  format,
  heightContainer = '65px',
  widthContainer = '65px',
  pictureChange = false,
  fontSizeInitials = 32,
  profile: {
    imageurl,
    pictureid,
    pictureId,
    firstname,
    firstName,
    lastname,
    lastName
  } = {},
  domainId,
  getProfile
}) => {
  const [image, setImage] = useState();
  const {
    acquiredFile: acquiredPicture,
    uploadedFile: uploadedPicture,
    fileIsHandled: pictureIsHandled,
    readFile: readPicture,
    resetFile: resetPicture,
    uploadFile: uploadPicture
  } = useFileUpload({ domainId });

  useEffect(() => {
    if (imageurl || pictureid || pictureId) {
      try {
        app
          .getPicture(imageurl || pictureid || pictureId)
          .then(response => {
            const imageDecoded = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );

            setImage(
              `data:${response.headers[
                'content-type'
              ].toLowerCase()};base64,${imageDecoded}`
            );
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('app.getPicture', err);
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageurl, pictureid, pictureId]);

  useEffect(() => {
    acquiredPicture && uploadPicture({ endpoint: 'uploadFile?profile=true' });
  }, [acquiredPicture, uploadPicture]);

  useEffect(() => {
    uploadedPicture && getProfile();
    resetPicture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureIsHandled]);

  return (
    <ProfilePictureContainer
      heightContainer={heightContainer}
      widthContainer={widthContainer}
      src={image}
      fontSizeInitials={fontSizeInitials}
      data-initials={generateInitials(
        `${firstname || firstName} ${lastname || lastName}`
      )}
    >
      {pictureChange && (
        <ChangePicture>
          <Icon htmlFor="uploadImage">
            <IconPhoto />
          </Icon>
          <input
            type="file"
            id="uploadImage"
            hidden
            accept={acceptedFiles.join(',')}
            onChange={() => {
              readPicture({
                file: document.querySelector('input[type=file]')?.files[0],
                format
              });
            }}
          />
        </ChangePicture>
      )}
    </ProfilePictureContainer>
  );
};

ProfilePicture.FORMAT = {
  BINARY: 'BINARY',
  BASE64: 'BASE64'
};

ProfilePicture.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  format: PropTypes.oneOf(Object.values(ProfilePicture.FORMAT)),
  heightContainer: PropTypes.string,
  widthContainer: PropTypes.string,
  pictureChange: PropTypes.bool,
  fontSizeInitials: PropTypes.number,

  // HOC (connect, state)
  profile: AccountDataInterface,
  domainId: PropTypes.number,

  // HOC (connect, dispatch)
  getProfile: PropTypes.func,

};

ProfilePicture.defaultProps = {
  acceptedFiles: ['.jpg', '.jpeg', '.png'],
  format: ProfilePicture.FORMAT.BINARY
};

export default connect(
  state => {
    const { id: domainId } = state.domain;
    return {
      domainId
    };
  },
  dispatch => ({
    getProfile: () => dispatch({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST }),
  })
)(ProfilePicture);
