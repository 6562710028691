import styled from 'styled-components/macro';
import { customScrollbar, hideScrollbar } from 'utils/common';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Head = styled.div`
  width: 420px;
  max-width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Texts = styled.div``;

export const Title = styled.div``;

export const Subtitle = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.americanSilver};
`;

export const Icon = styled.span`
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const IconUser = styled.div`
  font-size: 45px;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
`;

export const Form = styled.div`
  width: 420px;
  max-width: 100%;
  min-height: ${props => (props.minHeight ? `${props.minHeight}` : '350px')};
`;

export const Input = styled.input`
  width: 100%;
  height: 55px;
  margin: 20px 48px 20px 0;
  padding: 16px 10px 15px 20px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
  font-family: 'ProDisplay', sans-serif;
  font-size: 16px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 167px;
  resize: none;
  border-radius: 4px;
  margin: 0 48px 10px 0;
  padding: 16px 10px 147px 20px;
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white};
  font-family: 'ProDisplay', sans-serif;
  font-size: 16px;
`;

export const IconUplaod = styled.div`
  font-size: 30px;
  margin-right: 5px;
  color: ${props => props.theme.colors.primary.base};
`;

export const InputFile = styled.input`
  display: none;
`;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const BtnContainer = styled.div`
  width: 110px;
  right: 155px;
`;

export const AddUserWrapper = styled.div`
  position: absolute;
  min-width: calc(52px + 100%);
  min-height: calc(52px + 100%);

  @media ${props => props.theme.device.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentSearch = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 10px 50px 0 rgba(50, 50, 50, 0.2);
  border: solid 1px ${props => props.theme.colors.lightSilver};
  width: 332px;
  max-width: 100%;
  height: 335px;
  position: absolute;
  top: 90px;
  right: 108px;
  overflow-y: auto;

  @media ${props => props.theme.device.mobile} {
    position: relative;
    top: -40px;
    right: 0;
  }
`;

export const SearchContainer = styled.div`
  padding: 20px 0 20px;
  width: 420px;
  max-width: 100%;
  display: flex;
  align-items: center;
`;

export const SearchWrapper = styled.div`
  width: 300px;
`;

export const IconSearch = styled.div`
  font-size: 30px;
  margin-left: 20px;
  cursor: pointer;
`;

export const Results = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  ${hideScrollbar()}

  & .infinite-scroll-component {
    height: 295px !important;
    ${customScrollbar()}
  }
  @media ${props => props.theme.device.mobile} {
    max-height: 480px;

    & .infinite-scroll-component {
      height: 480px !important;
    }
  }
`;

export const UserWrapper = styled.div`
  height: 64px;
  max-width: 295px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.antiFlashWhite};

  &.selected {
    background-color: ${props => props.theme.colors.lightSilver};
  }
`;

export const Name = styled.div`
  margin-left: 17px;
`;

export const CheckboxWrapper = styled.div`
  margin-left: auto;
  min-height: 23px;
`;

export const ButtonsWrapper = styled.div`
  width: 420px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UsersBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & div {
    margin-left: 5px;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  height: 70px;
`;

export const IconDelete = styled.div`
  font-size: 30px;
  margin-left: 10px;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
`;

export const Thumbnail = styled.img`
  width: auto;
  height: 100%;
  margin-left: auto;
  padding: 5px;
  border: 1px solid ${props => props.theme.colors.lightSilver};
  border-radius: 4px;
`;

export const EmptyMsg = styled.div`
  width: 100%;
  padding: 10px 0;
`;
