import styled, { css } from 'styled-components/macro';

const InputNumber = styled.input.attrs(() => ({
  type: 'number'
}))`
  -moz-appearance: textfield;
  width: 89px;
  height: 55px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  text-indent: 25px;
  padding-right: 25px;
  border: 1px solid ${props => props.theme.colors.lightSilver};
  border-radius: 4px;
  color: ${props => props.theme.colors.mineShaft};
  
  ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  ::placeholder {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
`;

const QuantityNav = styled.div`
  float: left;
  position: relative;
  height: 55px;

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  width: 20px;
  text-align: center;
  color: ${props => props.theme.colors.mineShaft};
  font-size: 13px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  width: 25px;
  top: 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightSilver};
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .icon {
    font-size: 2px;
    color: ${props => props.theme.colors.azure};
  }
`;

const QuantityButton = styled.div`
  position: relative;
  cursor: pointer;
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  width: 20px;
  text-align: center;
  color: ${props => props.theme.colors.azure};
  font-size: 13px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  
  ${props => props.disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `};

  &.quantity-up {
    position: absolute;
    height: 50%;
    width: 25px;
    top: 0;
    border-bottom: 1px solid ${props => props.theme.colors.lightSilver};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.quantity-down {
    position: absolute;
    bottom: 0px;
    height: 50%;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Icon = styled.span`
  font-size: 2px;
  color: ${props => props.theme.colors.azure};
`;


export {
  InputNumber, Icon, QuantityNav, QuantityButton
};
