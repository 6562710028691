import styled from 'styled-components/macro';

export const TeamsActivityContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${props => props.theme.device.mobile} {
    position: relative;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  justify-content: space-between;
  color: ${props => props.theme.colors.azureDark};

  @media ${props => props.theme.device.mobile} {
    flex-wrap: wrap;
    justify-content: normal;
    width: 100%;
    height: auto;
  }
`;

export const Icon = styled.span`
  font-size: 22px;
  margin-left: 18px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;

export const Link = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  color: ${props => props.theme.colors.primary.base};
  text-decoration: none;
  cursor: pointer;

  @media ${props => props.theme.device.mobile} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const CardWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 32px;
`;

export const UserCardContainer = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const IntestationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.mineShaft};
`;

export const ContentWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListNumber = styled.div`
  min-width: 19px;
  min-height: 19px;
  border-radius: 10px;
  border: solid 1px ${props => props.theme.colors.primary.base};
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-right: 10px;
`;

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.mineShaft};
  width: 70px;
`;

export const Number = styled.div`
  margin-left: 12px;
`;

export const TitleSection = styled.div`
  margin-bottom: 17px;
  padding-top: 30px;

  @media ${props => props.theme.device.laptop} {
    margin-bottom: 30px;
  }

  @media ${props => props.theme.device.mobile} {
    padding-top: 20px;
  }
`;

export const SearchBarWrapper = styled.div`
  margin-left: 15px;
  width: 250px;

  @media ${props => props.theme.device.mobile} {
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }
`;
