import produce from 'immer';
import { REGISTRATION_CONTENTS_GET } from 'redux/actions/registration';

const initialState = {
  subscription: {},
  userAccount: {},
  billings: {}
};

const registrationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REGISTRATION_CONTENTS_GET._SUCCESS: {
        const { subscription, userAccount, billings } = action.response;

        draft.subscription = subscription;
        draft.userAccount = userAccount;
        draft.billings = billings;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default registrationReducer;
