import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import { Button, Checkbox, RadioButton } from 'ui/atoms';
import { AGREEMENTS_UPDATE } from 'redux/actions/signup';
import HtmlRaw from 'ui/components/HtmlRaw';
import ProDisplay from 'ui/typography/proDisplay';
import { Icon } from '../ModalResetPassword/style';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  ContentWrapper,
  Title,
  Text,
  CheckboxDescription,
  CheckboxWrapper,
  Radio,
  RadioDescription,
  ButtonWrapper,
  ContentsContainer
} from './style';

const modalId = 'EditPrivacyModal';

const EditPrivacyModal = ({
  onClose,
  privacy,
  updateAgreements,
  contract,
  policy,
  consents,
  isSimpleUser
}) => {
  const oldPrivacyDataCodes =
    privacy?.dataPrivacylist?.map(pd => pd?.type) || [];
  const [tempForm, setTempForm] = useState({});
  const [conditions, setConditions] = useState({});

  useEffect(() => {
    const conds = {};
    if (isSimpleUser) {
      if (consents?.consents?.find(c => oldPrivacyDataCodes.includes(c.code)))
        conds.consents = consents;
    } else {
      if (contract?.consents?.find(c => oldPrivacyDataCodes.includes(c.code)))
        conds.contract = contract;
      if (policy?.consents?.find(c => oldPrivacyDataCodes.includes(c.code)))
        conds.policy = policy;
      if (consents?.consents?.find(c => oldPrivacyDataCodes.includes(c.code)))
        conds.consents = consents;
    }

    setConditions(conds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, policy, consents, privacy]);

  const handleOnChange = (newValue, clause) => {
    const { id, type, mandatory = '', code } = clause;
    let control;

    const val = newValue;

    switch (type) {
      case 'checkbox':
        control = {
          ...tempForm[id],
          authorize: val,
          type: code,
          mandatory: mandatory === 'True',
          valid: mandatory === 'True' ? val : true
        };

        break;
      case 'radio':
        control = {
          ...tempForm[id],
          authorize: Boolean(Number(val)),
          type: code,
          mandatory: mandatory === 'True',
          valid: mandatory === 'True' ? Boolean(Number(val)) : true
        };
        break;
      default:
    }
    setTempForm({ ...tempForm, [id]: control });
  };

  const validator = () => {
    let valid = true;
    if (tempForm) {
      (Object.values(tempForm) || []).forEach(value => {
        valid = valid && value?.valid;
      });
    } else {
      valid = false;
    }
    return valid;
  };

  const updateAndClose = () => {
    if (validator) {
      updateAgreements(tempForm);
      onClose();
    }
  };

  const resetAndClose = () => {
    setTempForm({});
    onClose();
  };

  const getClauseByType = clause => {
    const { id, description, code, type, mandatory } = clause;

    const agreementValue = tempForm[id]
      ? tempForm[id]?.authorize
      : privacy?.dataPrivacylist?.find(d => d.type === code)?.authorized ||
        false;

    let clauseBody;

    switch (type) {
      case 'checkbox':
        clauseBody = (
          <CheckboxWrapper disabled={mandatory === 'True'}>
            <CheckboxDescription>
              <HtmlRaw html={description} />
            </CheckboxDescription>
            <Checkbox
              value={agreementValue}
              checked={agreementValue}
              label="ho letto attentamente il contenuto"
              onChange={value => handleOnChange(value, clause)}
              disabled={mandatory === 'True'}
            />
          </CheckboxWrapper>
        );
        break;
      case 'radio':
        clauseBody = (
          <Radio disabled={mandatory === 'True'}>
            <RadioDescription>
              <HtmlRaw html={description} />
            </RadioDescription>
            <RadioButton
              label="presto il consenso"
              value={1}
              name={`personal-data-${id}`}
              checked={agreementValue}
              onChange={value => {
                handleOnChange(value, clause);
              }}
              disabled={mandatory === 'True'}
            />
            <RadioButton
              label="nego il consenso"
              value={0}
              name={`personal-data-${id}`}
              checked={!agreementValue}
              onChange={value => {
                handleOnChange(value, clause);
              }}
              disabled={mandatory === 'True'}
            />
          </Radio>
        );
        break;
      default:
    }
    if (oldPrivacyDataCodes?.includes(code))
      return <React.Fragment key={id}>{clauseBody}</React.Fragment>;
    return null;
  };

  return (
    <Modal id={modalId} onClose={resetAndClose}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            // height={606}
            width={780}
            widthtablet={600}
          >
            <ContentsContainer>
              <Icon className="icon-close-x" onClick={resetAndClose} />
              {Object.keys(conditions).length &&
                Object.values(conditions).map(condition => (
                  <ContentWrapper key={condition.id}>
                    <Title>
                      <ProDisplay
                        type="modalTitle"
                        configuration={{ color: 'black' }}
                      >
                        {condition?.title}
                      </ProDisplay>
                    </Title>
                    <Text>
                      {(condition?.consents || []).map(clause =>
                        getClauseByType(clause)
                      )}
                    </Text>
                  </ContentWrapper>
                ))}
              <ButtonWrapper>
                <Button
                  text="Salva"
                  type={Button.TYPE.SECONDARY}
                  onClick={updateAndClose}
                />
              </ButtonWrapper>
            </ContentsContainer>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

EditPrivacyModal.propTypes = {
  isSimpleUser: PropTypes.bool,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  updateAgreements: PropTypes.func.isRequired,

  // HOC (connect, state)
  privacy: PropTypes.object,

  contract: PropTypes.object,
  policy: PropTypes.object,
  consents: PropTypes.object
};

EditPrivacyModal.id = modalId;

export default connect(
  state => {
    const { privacy } = state.profile;
    const { contract, policy, consents } = state.domain;
    return {
      privacy,
      contract,
      policy,
      consents
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    updateAgreements: agreements =>
      dispatch({ type: AGREEMENTS_UPDATE, agreements, privacyData: true })
  })
)(EditPrivacyModal);
