/**
 * Compute the current viewport given a map of the available ones.
 * 
 * @param {object} mediaQueries is a map of viewport labels to booleans
 * 
 * NOTE: only one of the labels should be associated with a true value,
 * in case more than one label is set to true only the first is returned.
 * 
 * @returns string representing the active viewport
 */
export const getCurrentViewport = (mediaQueries) => (
  Object.entries(mediaQueries)
    // .flatMap(([label, value]) => value ? [label] : [])
    ?.find(entry => entry[1])
    ?.at(0)
);
