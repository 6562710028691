import React from 'react';
import PropTypes from 'prop-types';

import NavBar from 'ui/components/Navbar';
import { PageContainer } from './styles';

const GlobalLayout = ({ children }) => (
  <>
    <NavBar />
    <PageContainer>{children}</PageContainer>
  </>
);

GlobalLayout.propTypes = {
  children: PropTypes.node
};

export default GlobalLayout;
