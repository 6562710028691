import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import {
  AUTH_LOGIN,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_OPEN,
} from 'redux/actions';
import {
  AUTHENTICATION_SIGNIN,
  AUTHENTICATION_SSO_SIGNIN,
  AUTHENTICATION_SIGNOUT,
  GET_DOMAINS,
  REGISTER_PROSPECT,
} from 'redux/actions/authentication';
import {
  PROFILE_DATA_ACCOUNT_GET,
  PROFILE_DATA_INITIAL_STATE,
  PROFILE_USER_PICTURE_SET,
  PROFILE_USER_SET,
} from 'redux/actions/profile';

import authentication from 'api/authentication';
import routes from 'routes';
import {
  SIGNUP_EMAIL_SET,
  SIGNUP_RESET_CART,
  SIGNUP_NEXT_STEP,
} from 'redux/actions/signup';

import {
  RESET_SUBSCRIPTIONS_INITIALSTATE,
} from 'redux/actions/subscriptions';
import {
  PLAYLISTS_INITIALSTATE,
} from 'redux/actions/playlist';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  cleanToken,
  getToken,
  setTimeoutTokenRefresh,
  setToken,
  StorageName,
} from 'utils/token';
import { channel } from 'api/config';
import app from 'api/app';
import { imageParse } from 'utils/image';
import onBoarding from 'api/onBoarding';
import { USER_SIDE_INITIAL_STATE } from 'redux/actions/social';
import {
  ONBOARDING_INITIAL_STATE,
} from 'redux/actions/onBoarding';
import { RESET_BILLINGS } from 'redux/actions/billings';

export function* profilePicGet({ pictureId }) {
  try {
    if (pictureId) {
      const { data, headers = {} } = yield call(app.getPicture, {
        pictureId,
      });
      const image = yield imageParse(data, headers);
      yield put({ type: PROFILE_USER_PICTURE_SET, image });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > authentication > profilePicGet', err);
  }
}

function* authenticationSignin({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // console.log('authenticationSignin payload ', payload);
    const res = yield call(authentication.authenticationSignin, payload);
    // console.log('authenticationSignin response ', res);
    const {
      data: {
        username,
        givenname,
        familyname,
        email,
        role,
        pictureid,
        organizationid,
        prospectid,
        token,
        accesstoken,
        refreshtoken,
        expiresin,
        clientenabled,
        domainlist,
        cf,
        phonenumber,
        imageurl,
        senddatatreatment,
      } = {},
    } = res;

    if (token) {
      const { rememberMe } = yield select(state => state.landingPage);
      setToken(
        {
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          userid: username,
        },
        !!rememberMe
      );
      if (!organizationid && senddatatreatment) {
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
        yield put(push(routes.billings.path));
      }
      yield put({
        type: AUTHENTICATION_SIGNIN._SUCCESS,
        user: {
          userId: username,
          firstname: givenname,
          lastname: familyname,
          email,
          role,
          pictureId: pictureid,
          organizationId: organizationid,
          prospectId: prospectid,
          clientEnabled: clientenabled,
          domainList: domainlist,
          fiscalCode: cf,
          phoneNumber: phonenumber,
          imageurl,
          sendDataTreatment: senddatatreatment,
        },
      });
      if (!senddatatreatment) {
        yield put({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST });
      } else if (organizationid || !senddatatreatment) {
        yield put({ type: AUTH_LOGIN._SUCCESS });
      }
      if (!clientenabled) {
        yield put(push(routes.choosePortal.path));
      }
    }
  } catch (err) {
    if (err?.response?.data?.errorMessage?.indexOf('Missing fields') >= 0) {
      /* ATTENTION,
        it doesn't seem correct to login the user on a failed call, but atm this
        code seems unreachable with the current shape of the error response
      */
      yield put({ type: AUTH_LOGIN._SUCCESS });
      const {
        data: {
          userName,
          token,
          accessToken: accesstoken,
          refreshToken: refreshtoken,
          expiresin,
        },
      } = err.response;
      yield put({ type: REGISTER_PROSPECT._REQUEST, email: payload.username });
      const { rememberMe } = yield select(state => state.landingPage);
      setToken(
        {
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          userid: userName,
        },
        !!rememberMe
      );
      yield put({
        type: AUTHENTICATION_SIGNIN._SUCCESS,
        user: {
          userId: userName,
        },
      });
      yield put(push(routes.compileManagerProfile.path));
    } else if (
      err?.response?.data?.errorMessage?.indexOf('not confirmed') >= 0
    ) {
      yield put({ type: REGISTER_PROSPECT._REQUEST, email: payload.username });
      yield put({
        type: SIGNUP_EMAIL_SET,
        account: {
          email: payload.username,
          pwd: payload.passwordUser,
          toConfirmUser: true,
        },
      });
      yield put(push(routes.confirmOtp.path));
    } else {
      const payloadError = {};
      if (err?.message?.indexOf('401') > -1 || err?.response?.status === 401) {
        payloadError.errorTitle = 'Attenzione, credenziali errate.';
        payloadError.errorText = 'Inserisci le credenziali corrette per poter effettuare l’accesso';
      }
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: payloadError,
      });
    }
    // eslint-disable-next-line no-console
    //console.log('sagas > authentication > authenticationSignin', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authenticationSigninWatch() {
  yield takeLatest(AUTHENTICATION_SIGNIN._REQUEST, authenticationSignin);
}


function* authenticationSSOSignin({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // console.log('authenticationSignin payload ', payload);
    const res = yield call(authentication.authenticationSSOSignin, payload);
    // console.log('authenticationSignin response ', res);
    const {
      data: {
        username,
        givenname,
        familyname,
        email,
        role,
        pictureid,
        organizationid,
        prospectid,
        token,
        accesstoken,
        refreshtoken,
        expiresin,
        clientenabled,
        domainlist,
        cf,
        phonenumber,
        imageurl,
        senddatatreatment,
      } = {},
    } = res;

    if (token) {
      const { rememberMe } = yield select(state => state.landingPage);
      setToken(
        {
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          userid: username,
        },
        !!rememberMe
      );
      if (!organizationid && senddatatreatment) {
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
        yield put(push(routes.billings.path));
      }
      yield put({
        type: AUTHENTICATION_SSO_SIGNIN._SUCCESS,
        user: {
          userId: username,
          firstname: givenname,
          lastname: familyname,
          email,
          role,
          pictureId: pictureid,
          organizationId: organizationid,
          prospectId: prospectid,
          clientEnabled: clientenabled,
          domainList: domainlist,
          fiscalCode: cf,
          phoneNumber: phonenumber,
          imageurl,
          sendDataTreatment: senddatatreatment,
        },
      });
      if (!senddatatreatment) {
        yield put({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST });
      } else if (organizationid || !senddatatreatment) {
        yield put({ type: AUTH_LOGIN._SUCCESS });
      }
      if (!clientenabled) {
        yield put(push(routes.choosePortal.path));
      }
    }
  } catch (err) {
    if (err?.response?.data?.errorMessage?.indexOf('Missing fields') >= 0) {
      /* ATTENTION,
        it doesn't seem correct to login the user on a failed call, but atm this
        code seems unreachable with the current shape of the error response
      */
      yield put({ type: AUTH_LOGIN._SUCCESS });
      const {
        data: {
          userName,
          token,
          accessToken: accesstoken,
          refreshToken: refreshtoken,
          expiresin,
        },
      } = err.response;
      yield put({ type: REGISTER_PROSPECT._REQUEST, email: payload.username });
      const { rememberMe } = yield select(state => state.landingPage);
      setToken(
        {
          token,
          accesstoken,
          refreshtoken,
          expiresin,
          userid: userName,
        },
        !!rememberMe
      );
      yield put({
        type: AUTHENTICATION_SIGNIN._SUCCESS,
        user: {
          userId: userName,
        },
      });
      yield put(push(routes.compileManagerProfile.path));
    } else if (
      err?.response?.data?.errorMessage?.indexOf('not confirmed') >= 0
    ) {
      yield put({ type: REGISTER_PROSPECT._REQUEST, email: payload.username });
      yield put({
        type: SIGNUP_EMAIL_SET,
        account: {
          email: payload.username,
          pwd: payload.passwordUser,
          toConfirmUser: true,
        },
      });
      yield put(push(routes.confirmOtp.path));
    } else {
      const payloadError = {};
      if (err?.message?.indexOf('401') > -1 || err?.response?.status === 401) {
        payloadError.errorTitle = 'Attenzione, credenziali errate.';
        payloadError.errorText = 'Inserisci le credenziali corrette per poter effettuare l’accesso';
      }
      yield put({
        type: MODAL_OPEN,
        id: ModalErrors.id,
        payload: payloadError,
      });
    }
    // eslint-disable-next-line no-console
    //console.log('sagas > authentication > authenticationSignin', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}
function* authenticationSigninSSOWatch() {
  yield takeLatest(AUTHENTICATION_SSO_SIGNIN._REQUEST, authenticationSSOSignin);
}

function* authenticationSignout({ goTo = undefined, isSuperManager = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { accesstoken: accessToken } = getToken() || {};
    if (accessToken && !isSuperManager) {
      yield call(authentication.authenticationSignout, { accessToken });
    }
    yield put({ type: AUTHENTICATION_SIGNOUT._SUCCESS });

    yield put({ type: PROFILE_DATA_INITIAL_STATE });
    yield put({ type: RESET_SUBSCRIPTIONS_INITIALSTATE });
    yield put({ type: USER_SIDE_INITIAL_STATE });
    yield put({ type: ONBOARDING_INITIAL_STATE });
    yield put({ type: RESET_BILLINGS });
    yield put({ type: SIGNUP_RESET_CART });
    yield put({ type: PLAYLISTS_INITIALSTATE });
    cleanToken(StorageName.Auth);
    if (goTo) {
      yield put(push(goTo));
    }
  } catch (err) {
    yield put({ type: AUTHENTICATION_SIGNOUT._SUCCESS });
    yield put({ type: PROFILE_DATA_INITIAL_STATE });
    yield put({ type: RESET_SUBSCRIPTIONS_INITIALSTATE });
    yield put({ type: USER_SIDE_INITIAL_STATE });
    yield put({ type: ONBOARDING_INITIAL_STATE });
    yield put({ type: RESET_BILLINGS });
    yield put({ type: SIGNUP_RESET_CART });
    yield put({ type: PLAYLISTS_INITIALSTATE });

    cleanToken(StorageName.Auth);
    if (goTo) {
      yield put(push(goTo));
    }
    /* yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    }); */
    // eslint-disable-next-line no-console
    console.log('sagas > authentication > authenticationSignout', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authenticationSignoutWatch() {
  yield takeLatest(AUTHENTICATION_SIGNOUT._REQUEST, authenticationSignout);
}

function* authenticationLogin() {
  try {
    yield put({ type: LOADER_OPEN });

    const store = getToken();
    const isLogged = !!store;

    if (isLogged) {
      if (
        window.location.pathname.split('/').at(-1)
        === routes.setFirstPasswordFromBank.path.split('/').at(-1)
      ) {
        return;
      }
      setTimeoutTokenRefresh(store);
      yield put({ type: PROFILE_USER_SET, userId: store?.userid });
      yield put({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST, toReload: true });
    } else {
      yield put({ type: AUTH_LOGIN._ERROR });
      yield put({ type: AUTHENTICATION_SIGNOUT._REQUEST });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > authentication > authenticationLogin', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* authenticationLoginWatch() {
  yield takeLatest(AUTH_LOGIN._REQUEST, authenticationLogin);
}

function* getDomain({ domain }) {
  try {
    yield put({ type: LOADER_OPEN });

    const { userId } = yield select(state => state.authentication.user);
    const store = getToken();

    if (store) {
      const res = yield call(authentication.getDomain, {
        userId,
        refreshToken: store.refreshtoken,
        accessToken: store.accesstoken,
        channel,
        domainId: domain,
      });
      const {
        data: { token, accesstoken, refreshtoken, expiresin },
      } = res;
      if (token) {
        const { rememberMe } = yield select(state => state.landingPage);
        setToken(
          {
            token,
            accesstoken,
            refreshtoken,
            expiresin,
            userid: userId,
          },
          !!rememberMe
        );
        yield put({ type: GET_DOMAINS._SUCCESS });
        yield put({ type: PROFILE_DATA_ACCOUNT_GET._REQUEST });
      }
    } else {
      yield put({
        type: AUTHENTICATION_SIGNOUT._REQUEST,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    yield put({ type: GET_DOMAINS._ERROR });
    // eslint-disable-next-line no-console
    console.log('sagas > authentication > getDomain', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getDomainWatch() {
  yield takeLatest(GET_DOMAINS._REQUEST, getDomain);
}

function* registerProspect({ email }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const registerProspectResponse = yield call(
      onBoarding.registerProspect,
      email
    );
  } catch (e) {
    //not warn
  } finally {
    yield put({ type: REGISTER_PROSPECT._SUCCESS });
  }
}

function* registerProspectWatch() {
  yield takeLatest(REGISTER_PROSPECT._REQUEST, registerProspect);
}

export default [
  authenticationSigninWatch(),
  authenticationSigninSSOWatch(),
  authenticationSignoutWatch(),
  authenticationLoginWatch(),
  getDomainWatch(),
  registerProspectWatch(),
];
