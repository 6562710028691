import { createAsyncActionType } from './index';

export const GET_NOTES = createAsyncActionType('notes', 'GET_NOTES');

export const ADD_NOTE = createAsyncActionType('notes', 'ADD_NOTE');

export const EDIT_NOTE = createAsyncActionType('notes', 'EDIT_NOTE');

export const GET_USERS = createAsyncActionType('notes', 'GET_USERS');

export const GET_ALL_USERS = createAsyncActionType('notes', 'GET_ALL_USERS');

export const DELETE_NOTE = createAsyncActionType('notes', 'DELETE_NOTE');

export const ADD_PARTECIPANTS = createAsyncActionType(
  'notes',
  'ADD_PARTECIPANTS'
);

export const ADD_COMMENT = createAsyncActionType('notes', 'ADD_COMMENT');
