import produce from 'immer';
import {
  BILLINGS_GET_PAYMENT_GATEGAYS,
  SET_SELECTED_GATEWAY,
  BILLING_GET_PAYMENT_METHODS,
  BILLING_GET_CLIENT_SECRET,
  BILLING_CLIENT_SECRET_RESET,
  BILLING_IS_PREMIUM_PURCHASE,
  RESET_BILLINGS, BILLINGS_ZUORA_SIGNATURE_GET, SET_PAYMENT_METHODS
} from 'redux/actions/billings';
import { PROFILE_SELECTED_DATA_PAYMENT } from 'redux/actions/profile';

const initialState = {
  gateways: {},
  selected_gateway: '',
  payment_methods: [],
  client_secret: '',
  signatures: {},
  atecoCodes: []
};

const billingsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case BILLINGS_GET_PAYMENT_GATEGAYS._SUCCESS: {
        const { gateways = [] } = action;
        draft.gateways = gateways;
        break;
      }

      case SET_SELECTED_GATEWAY: {
        const { gateway } = action;
        draft.selected_gateway = gateway;
        break;
      }

      case SET_PAYMENT_METHODS:
      case BILLING_GET_PAYMENT_METHODS._SUCCESS: {
        const { payment_methods } = action;
        draft.payment_methods = payment_methods;
        break;
      }

      case BILLINGS_ZUORA_SIGNATURE_GET._SUCCESS: {
        const { data, payment_method } = action;
        draft.signatures = {
          ...state.signatures,
          [payment_method]: data
        };
        break;
      }

      case BILLING_GET_CLIENT_SECRET._SUCCESS: {
        const { clientSecret } = action;
        draft.client_secret = clientSecret;
        break;
      }

      case PROFILE_SELECTED_DATA_PAYMENT:
      case BILLING_CLIENT_SECRET_RESET: {
        draft.client_secret = initialState.client_secret;
        break;
      }
      case BILLING_IS_PREMIUM_PURCHASE: {
        draft.isPremiumPurchase = action.payload;
        break;
      }

      case RESET_BILLINGS: {
        draft = initialState;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default billingsReducer;
