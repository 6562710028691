import styled from 'styled-components/macro';
import { customScrollbar } from 'utils/common';
import { FooterHeight, NavBarHeight } from '../../../const';

export const SidebarWrapper = styled.div`
  position: absolute;
  top: ${NavBarHeight}px;
  right: 0;
  width: 0;
  min-height: calc(${props => props.height}px - ${NavBarHeight}px);
  background-color: rgba(255, 255, 255, 0);
  transition-delay: 50ms;
  z-index: 60;
  &.open {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media ${props => props.theme.device.tablet} {
    position: fixed;
  }
`;

export const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  /*height: calc(
    ${props => props.height}px - ${NavBarHeight}px - ${FooterHeight}px
  );*/
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  box-shadow: -10px 4px 20px 0 rgba(209, 209, 214, 0.3);
  overflow-y: auto;
  transition: all 250ms ease-in;
  transition-delay: 50ms;
  ${customScrollbar()};

  &.open {
    border: solid 1px ${props => props.theme.colors.filterColor};
    width: 429px;
  }

  @media ${props => props.theme.device.tablet} {
    z-index: 200;
    //height: calc(100% - ${NavBarHeight}px);
    height: 100%;
    position: fixed;
    top: ${NavBarHeight}px;
    overflow: auto;
    &.open {
      width: 100%;
      padding: 0 36px;
    }
  }
  @media ${props => props.theme.device.mobile} {
    &.open {
      padding: 0 10px;
    }
  }
`;
