import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import {
  Title,
  ContentWrapper,
  Icon,
  ContentList,
  ItemList,
  IconList
} from './style';
import { ModalWrapper } from '../InviteFriendModal/style';


const modalId = 'SelectedCollectionModal';

const SelectedCollectionModal = ({ onClose, list }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Title>
              <ProDisplay type="modalTitle">Playlist selezionate</ProDisplay>
            </Title>
            <ContentList>
              {list.map(element => (
                <ItemList>
                  <IconList className="icon icon-check" />
                  <ProDisplay>{element}</ProDisplay>
                </ItemList>
              ))}
            </ContentList>
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

SelectedCollectionModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  list: PropTypes.array
};

SelectedCollectionModal.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(SelectedCollectionModal);
