import styled from 'styled-components/macro';

export const MyProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
`;

export const Title = styled.div`
  padding-bottom: 42px;
  padding-top: 35px;

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 25px;
    padding-top: 30px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 16px;
    padding-top: 19px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.device.laptop} {
    flex-direction: column;
  }
`;

export const CardsWrapper = styled.div`
  width: 100%;
`;

export const ProfilePicture = styled.div`
  height: 65px;
  width: 65px;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: blue;
  border-radius: 100%;
  margin-right: 33px;
  position: relative;
  margin-bottom: 21px;
`;

export const ChangePicture = styled.div`
  background-color: ${props => props.theme.colors.azureDark};
  color: ${props => props.theme.colors.white};
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Icon = styled.label`
  font-size: 22px;
  margin: 0;
  cursor: pointer;
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;

  &:first-child {
    padding-top: 15px;

    @media ${props => props.theme.device.tablet} {
      padding-top: 0;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.filterColor};
    padding-top: 15px;
  }
`;

export const ColSmall = styled.div`
  width: 10%;

  @media ${props => props.theme.device.laptop} {
    width: 25%;

    &.profile-pic {
      width: 10%;
    }
  }

  @media ${props => props.theme.device.tablet} {
    &.profile-pic {
      width: 15%;
    }
  }

  @media ${props => props.theme.device.mobile} {
    width: 50%;

    &.profile-pic {
      width: 100%;
    }
    &.smaller {
      width: 50%;
    }
  }
`;

export const ColMedium = styled.div`
  width: 25%;

  &.smaller {
    width: 15%;
  }

  @media ${props => props.theme.device.laptop} {
    width: 50%;

    &.table {
      width: 25%;
    }

    &.smaller {
      width: 40%;
    }
  }

  @media ${props => props.theme.device.tablet} {
    &.smaller {
      width: 35%;
    }
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;

    &.smaller,
    &.smaller-sm {
      width: 50%;
    }
  }
`;

export const ColMediumRight = styled.div`
  width: 8%;
  text-align: right;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }
`;

export const ColMediumTable = styled.div`
  width: 14%;
  margin-bottom: 9.5px;

  @media ${props => props.theme.device.tablet} {
    width: 50%;
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const TableBody = styled.div`
  width: 100%;

  &.bordered {
    border-top: 1px solid ${props => props.theme.colors.lightSilver};
  }
`;

export const BillingContainer = styled.div``;

// User Section

export const Head = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.header`
  height: 70px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.primary.darker};
`;

export const HeaderIcon = styled.div`
  width: 70px;
  height: 50px;
  padding-left: 20px;
  color: ${props => props.theme.colors.white};
  font-size: 30px;
  text-align: end;
  cursor: pointer;
  transform: rotate(180deg);
  border-left: 1px solid white;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  margin-left: 20px;
`;

export const ProfileInfo = styled.div`
  margin-left: 50px;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    margin: 0 auto;
  }
`;

export const Name = styled.div`
  padding: 0 20px;
`;

export const IconEdit = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
`;

export const FollowersContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Followers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  height: 40px;

  cursor: pointer;
  &.follow {
    cursor: default;
  }
`;

export const Following = styled(Followers)`
  border-left: 1px solid ${props => props.theme.colors.platinum};
`;

export const Number = styled.div``;

export const ProgressesContainer = styled.div`
  border-radius: 6px;
  box-shadow: 0 10px 40px 0 rgba(24, 42, 50, 0.03);
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 40px;
`;

export const ProgressesTitle = styled.div`
  padding: 15px 30px;
  border-bottom: 1px solid ${props => props.theme.colors.antiFlashWhite};
`;

export const ProgressCardsWrapper = styled.div`
  padding: 14px 10px;
  display: flex;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
  }
`;

export const SmallRow = styled.div`
  min-height: 1.14em;
`;

export const NameAndPrivacy = styled.div`
  display: flex;
  flex-wrap: wrap;

  span{
    line-height: 1.2;
  }
  @media ${props => props.theme.device.mobile} {
    min-height: 100px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const Link = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 0 20px;
`;
