import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BILLINGS_ZUORA_SIGNATURE_GET } from '../../../../redux/actions/billings';
import { LOADER_CLOSE, LOADER_OPEN } from '../../../../redux/actions';
import { ZuoraContainer, ZuoraPayment } from './style';
import { ZUORA_URL } from '../../../../api/config';

const SEPAZuora = ({
  callbackSuccess,
  callbackError,
  signatures: zuoraSignatures = {},
  company,
  user,
  getBillingsSignatureZuora,
  loaderOpen,
  loaderClose,
}) => {
  useEffect(() => {
    loaderOpen();
    const { SEPA } = zuoraSignatures;
    if (!SEPA) getBillingsSignatureZuora();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBillingsSignatureZuora]);
  const default_params = {
    style: 'inline',
    locale: 'it_IT',
    submitEnabled: true,
    url: ZUORA_URL[process.env.REACT_APP_DEPLOY_ENV],
  };

  const [zuora_params, setZuoraParams] = useState({});

  useEffect(() => {
    const { SEPA: { key, signature, token, tenantid: tenantId, acn_pageid: id } = {} } = zuoraSignatures;
    const { zuoraaccountid: field_accountId } = company;
    setZuoraParams({ ...default_params, id, field_accountId, key, signature, tenantId, token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zuoraSignatures]);


  const zuoraCallback = ({ success, refId, errorMessage }) => {
    if (success) {
      callbackSuccess && callbackSuccess(refId);
    } else if (callbackError) {
      callbackError(errorMessage);
    }
    getBillingsSignatureZuora();
  };

  const loadZuora = () => {
    window.Z.setEventHandler('onloadCallback', () => {
      loaderClose();
    });
    const { email = '' } = user;
    window.Z.render(zuora_params, { email, country: 'ITA' }, zuoraCallback);
  };

  useEffect(() => {
    const { SEPA } = zuoraSignatures;
    if (SEPA && Object.keys(zuora_params).length > 0) {
      loadZuora();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zuora_params]);

  return (
    <ZuoraContainer>
      <ZuoraPayment />
    </ZuoraContainer>
  );
};

SEPAZuora.propTypes = {
  callbackSuccess: PropTypes.func,
  callbackError: PropTypes.func,

  // HOC (connect, state)
  company: PropTypes.object,
  user: PropTypes.object,
  signatures: PropTypes.object,
  // HOC (connect, dispatch)
  getBillingsSignatureZuora: PropTypes.func,
  loaderOpen: PropTypes.func,
  loaderClose: PropTypes.func,
};

export default connect(
  state => ({
    signatures: state.billings.signatures,
    user: state.authentication.user,
    company: state.profile.company,
  }),
  dispatch => ({
    getBillingsSignatureZuora: () => dispatch({ type: BILLINGS_ZUORA_SIGNATURE_GET._REQUEST, payment_method: 'SEPA' }),
    loaderOpen: () => dispatch({ type: LOADER_OPEN }),
    loaderClose: () => dispatch({ type: LOADER_CLOSE }),
  })
)(SEPAZuora);
