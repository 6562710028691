import React from 'react';
import PropTypes from 'prop-types';

import Typeface, { TypefaceInterface } from './index';
import types from './proDisplay.types';

const ProDisplay = ({ children, configuration, onClick, type, currentViewport, ellipsis, tooltip, className }) => {
  const selectedType = (currentViewport && types[type] && types[type][currentViewport]) ?? types[type];
  return (
    <Typeface
      configuration={{
        ...selectedType,
        ...configuration
      }}
      onClick={onClick}
      tooltip={tooltip}
      ellipsis={ellipsis}
      className={className}
    >
      {children}
    </Typeface>
  )
};

ProDisplay.propTypes = {
  children: PropTypes.node,
  configuration: TypefaceInterface,
  ellipsis: PropTypes.number,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(types)),
};

ProDisplay.defaultProps = {
  type: '1',
};

export default ProDisplay;
